import {
  Configuration,
  WorkflowFeedback,
  GenericV1ApiFactory,
  GenericWorkflow,
  FindCompaniesV2InitialInputs,
  FindCompaniesV2ApiFactory,
  SourceCompaniesInputs,
  SourceCompaniesApiFactory,
  CompanyApiFactory,
  UserApiFactory,
  ImportableSearchScope,
  CompanyListsApiFactory,
  CompanyList,
  DemoApiFactory
} from "./autogen";

import { brainServerPath } from "@/utils/config";

export const config: Configuration = new Configuration({
  basePath: brainServerPath,
  baseOptions: {
    headers: {
      "Content-Type": "application/json"
    }
  }
});

export function runGenericWorkflow(token: string, workflow: GenericWorkflow) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = GenericV1ApiFactory(config);
  return brainApi.brainV1RunGenericWorkflowPost(workflow);
}

export function refineGenericWorkflow(token: string, workflow: GenericWorkflow) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = GenericV1ApiFactory(config);
  return brainApi.brainV1RefineGenericWorkflowPost(workflow);
}

export function fetchAllWorkflowStubs(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = GenericV1ApiFactory(config);
  return brainApi.brainV1FetchWorkflowStubsPost();
}

export function fetchAllGenericWorkflows(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = GenericV1ApiFactory(config);
  return brainApi.brainV1FetchAllGenericWorkflowsPost();
}

export function deleteGenericWorkflow(token: string, workflow_id: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = GenericV1ApiFactory(config);
  return brainApi.brainV1DeleteGenericWorkflowPost({ workflow_id });
}

export function fetchGenericWorkflow(workflow_id: string) {
  const brainApi = GenericV1ApiFactory(config);
  return brainApi.brainV1FetchGenericWorkflowPost({ workflow_id });
}

export function giveGenericWorkflowFeedback(token: string, workflow_id: string, feedback: WorkflowFeedback) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = GenericV1ApiFactory(config);
  return brainApi.brainV1GiveGenericWorkflowFeedbackPost({ workflow_id, feedback });
}

export function fetchLibraryGenericWorkflows() {
  const brainApi = GenericV1ApiFactory(config);
  return brainApi.brainV1FetchLibraryGenericWorkflowsPost();
}

export function fetchLibraryGenericWorkflowStubs() {
  const brainApi = GenericV1ApiFactory(config);
  return brainApi.brainV1FetchLibraryGenericWorkflowStubsPost();
}

export function exportWorkflowToExcel(token: string, workflow: GenericWorkflow) {
  const newConfig = new Configuration(config);
  newConfig.baseOptions = { ...newConfig.baseOptions, responseType: "blob" };
  newConfig.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = GenericV1ApiFactory(newConfig);
  return brainApi.brainV1ExportWorkflowToExcelPost(workflow);
}

export function exportWorkflowToPpt(token: string, workflow: GenericWorkflow) {
  const newConfig = new Configuration(config);
  newConfig.baseOptions = { ...newConfig.baseOptions, responseType: "blob" };
  newConfig.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = GenericV1ApiFactory(newConfig);
  return brainApi.brainV1ExportWorkflowToPptPost(workflow);
}

export function updateGenericWorkflow(token: string, workflow: GenericWorkflow) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = GenericV1ApiFactory(config);
  return brainApi.brainV1UpdateGenericWorkflowPost(workflow);
}

export function markWorkflowViewed(token: string, workflow_id: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = GenericV1ApiFactory(config);
  return brainApi.brainV1MarkWorkflowViewedPost({ workflow_id });
}

export function findCompaniesInitialInputsToInputs(token: string, initial_inputs: FindCompaniesV2InitialInputs) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = FindCompaniesV2ApiFactory(config);
  return brainApi.brainV1FindCompaniesInitialInputsToInputsPost(initial_inputs);
}

export function addSimilarCompaniesToFindCompaniesWorkflow(token: string, workflow_id: string, company_domain: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = FindCompaniesV2ApiFactory(config);
  return brainApi.brainV1AddSimilarCompaniesToFindCompaniesWorkflowPost({ workflow_id, company_domain });
}

export function sourceCompaniesInitialInputsToInputs(token: string, initial_inputs: SourceCompaniesInputs) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = SourceCompaniesApiFactory(config);
  return brainApi.brainV1SourceCompaniesInitialInputsToInputsPost(initial_inputs);
}

export function getCompanyProfile(token: string, company_domain: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanyApiFactory(config);
  return brainApi.brainV1GetCompanyProfilePost({ company_domain });
}

export function getUserDefaultScope(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = UserApiFactory(config);
  return brainApi.brainV1GetUserDefaultScopePost();
}

export function setUserDefaultScope(token: string, scope: ImportableSearchScope) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = UserApiFactory(config);
  return brainApi.brainV1SetUserDefaultScopePost({ scope });
}

export function getCompanyLists(token: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanyListsApiFactory(config);
  return brainApi.brainV1GetCompanyListsPost();
}

export function updateCompanyList(token: string, companyList: CompanyList) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanyListsApiFactory(config);
  return brainApi.brainV1UpdateCompanyListPost(companyList);
}

export function addCompaniesFromMarketMapToCompanyList(token: string, company_list_id: string, market_map_image_base64_encoded: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanyListsApiFactory(config);
  return brainApi.brainV1AddCompaniesFromMarketMapToCompanyListPost({ company_list_id, market_map_image_base64_encoded });
}

export function deleteCompanyList(token: string, company_list_id: string) {
  config.baseOptions.headers = {
    ...config.baseOptions.headers,
    Authorization: `Bearer ${token}`
  };
  const brainApi = CompanyListsApiFactory(config);
  return brainApi.brainV1DeleteCompanyListPost({ company_list_id });
}

export function findSimilarCompaniesLight(company_domain: string, user_id?: string) {
  const brainApi = DemoApiFactory(config);
  return brainApi.brainV1FindSimilarCompaniesLightPost({ company_domain, user_id });
}
