import * as React from "react";
import * as AvatarPrimitive from "@radix-ui/react-avatar";

import { cn, getDomainFromUrl } from "@/lib/utils";

const Avatar = React.forwardRef<React.ElementRef<typeof AvatarPrimitive.Root>, React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Root>>(
  ({ className, ...props }, ref) => (
    <AvatarPrimitive.Root ref={ref} className={cn("relative flex h-10 w-10 shrink-0 overflow-hidden rounded-full", className)} {...props} />
  )
);
Avatar.displayName = AvatarPrimitive.Root.displayName;

const AvatarImage = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Image>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Image>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Image ref={ref} className={cn("aspect-square w-full object-scale-down", className)} {...props} />
));
AvatarImage.displayName = AvatarPrimitive.Image.displayName;

const AvatarFallback = React.forwardRef<
  React.ElementRef<typeof AvatarPrimitive.Fallback>,
  React.ComponentPropsWithoutRef<typeof AvatarPrimitive.Fallback>
>(({ className, ...props }, ref) => (
  <AvatarPrimitive.Fallback
    ref={ref}
    className={cn("flex h-full w-full items-center justify-center rounded-full bg-colors-gray-modern-500 text-white", className)}
    {...props}
  />
));
AvatarFallback.displayName = AvatarPrimitive.Fallback.displayName;

const CompanyLogo = ({ name, website, className, logo }: { name: string; website?: string; className?: string; logo?: string }) => {
  // const iconUrl = website ? "https://icon.horse/icon/" + getDomainFromUrl(website) : undefined;
  let iconUrl: string | undefined;
  if (logo) {
    iconUrl = logo;
  } else {
    iconUrl = website ? "https://logo.clearbit.com/" + getDomainFromUrl(website) : undefined;
    // iconUrl = website ? "https://cdn.brandfetch.io/" + getDomainFromUrl(website) : undefined;
  }
  let fallbackChar = "";
  if (name.length === 0) {
    fallbackChar = "?";
  } else {
    fallbackChar = name[0].toUpperCase();
  }
  return (
    <>
      <Avatar className={cn("border border-colors-border-border-primary", className)}>
        <AvatarImage src={iconUrl} />
        <AvatarFallback>{fallbackChar}</AvatarFallback>
      </Avatar>
    </>
  );
};

export { Avatar, AvatarImage, AvatarFallback, CompanyLogo, getDomainFromUrl };
