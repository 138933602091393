/* tslint:disable */
/* eslint-disable */
/**
 * Meticulate Web Server - OpenAPI 3.0
 * Something something description
 *
 * The version of the OpenAPI document: 0.0.1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError } from './base';

/**
 * 
 * @export
 * @interface BrainV1AddCompaniesFromMarketMapToCompanyListPostRequest
 */
export interface BrainV1AddCompaniesFromMarketMapToCompanyListPostRequest {
    /**
     * 
     * @type {string}
     * @memberof BrainV1AddCompaniesFromMarketMapToCompanyListPostRequest
     */
    'company_list_id': string;
    /**
     * 
     * @type {string}
     * @memberof BrainV1AddCompaniesFromMarketMapToCompanyListPostRequest
     */
    'market_map_image_base64_encoded': string;
}
/**
 * 
 * @export
 * @interface BrainV1AddCompaniesToCompanyListPostRequest
 */
export interface BrainV1AddCompaniesToCompanyListPostRequest {
    /**
     * 
     * @type {string}
     * @memberof BrainV1AddCompaniesToCompanyListPostRequest
     */
    'company_list_id': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BrainV1AddCompaniesToCompanyListPostRequest
     */
    'company_domains': Array<string>;
}
/**
 * 
 * @export
 * @interface BrainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest
 */
export interface BrainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest {
    /**
     * 
     * @type {string}
     * @memberof BrainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest
     */
    'workflow_id': string;
    /**
     * 
     * @type {string}
     * @memberof BrainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest
     */
    'company_domain': string;
}
/**
 * 
 * @export
 * @interface BrainV1DeleteCompanyListPostRequest
 */
export interface BrainV1DeleteCompanyListPostRequest {
    /**
     * 
     * @type {string}
     * @memberof BrainV1DeleteCompanyListPostRequest
     */
    'company_list_id': string;
}
/**
 * 
 * @export
 * @interface BrainV1DeletePeopleListPostRequest
 */
export interface BrainV1DeletePeopleListPostRequest {
    /**
     * 
     * @type {string}
     * @memberof BrainV1DeletePeopleListPostRequest
     */
    'people_list_id': string;
}
/**
 * 
 * @export
 * @interface BrainV1FetchGenericWorkflowPostRequest
 */
export interface BrainV1FetchGenericWorkflowPostRequest {
    /**
     * 
     * @type {string}
     * @memberof BrainV1FetchGenericWorkflowPostRequest
     */
    'workflow_id': string;
}
/**
 * 
 * @export
 * @interface BrainV1FindCompaniesInitialInputsToInputsPost200Response
 */
export interface BrainV1FindCompaniesInitialInputsToInputsPost200Response {
    /**
     * 
     * @type {FindCompaniesV2Inputs}
     * @memberof BrainV1FindCompaniesInitialInputsToInputsPost200Response
     */
    'final_inputs': FindCompaniesV2Inputs;
    /**
     * 
     * @type {string}
     * @memberof BrainV1FindCompaniesInitialInputsToInputsPost200Response
     */
    'error_explanation'?: string;
}
/**
 * 
 * @export
 * @interface BrainV1FindSimilarCompaniesLightPostRequest
 */
export interface BrainV1FindSimilarCompaniesLightPostRequest {
    /**
     * 
     * @type {string}
     * @memberof BrainV1FindSimilarCompaniesLightPostRequest
     */
    'company_domain': string;
    /**
     * 
     * @type {string}
     * @memberof BrainV1FindSimilarCompaniesLightPostRequest
     */
    'user_id'?: string;
}
/**
 * 
 * @export
 * @interface BrainV1GetCompanyContactsPostRequest
 */
export interface BrainV1GetCompanyContactsPostRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof BrainV1GetCompanyContactsPostRequest
     */
    'company_domains': Array<string>;
    /**
     * 
     * @type {string}
     * @memberof BrainV1GetCompanyContactsPostRequest
     */
    'persona_description': string;
    /**
     * 
     * @type {number}
     * @memberof BrainV1GetCompanyContactsPostRequest
     */
    'limit_contacts_per_company'?: number;
}
/**
 * 
 * @export
 * @interface BrainV1GetCompanyProfilePostRequest
 */
export interface BrainV1GetCompanyProfilePostRequest {
    /**
     * 
     * @type {string}
     * @memberof BrainV1GetCompanyProfilePostRequest
     */
    'company_domain': string;
}
/**
 * 
 * @export
 * @interface BrainV1GetDocumentPdfPostRequest
 */
export interface BrainV1GetDocumentPdfPostRequest {
    /**
     * 
     * @type {string}
     * @memberof BrainV1GetDocumentPdfPostRequest
     */
    'document_id': string;
}
/**
 * 
 * @export
 * @interface BrainV1GetIngestedCompaniesPost200Response
 */
export interface BrainV1GetIngestedCompaniesPost200Response {
    /**
     * 
     * @type {Array<IngestedCompany>}
     * @memberof BrainV1GetIngestedCompaniesPost200Response
     */
    'ingested_companies'?: Array<IngestedCompany>;
    /**
     * 
     * @type {object}
     * @memberof BrainV1GetIngestedCompaniesPost200Response
     */
    'assessment_framework'?: object;
}
/**
 * 
 * @export
 * @interface BrainV1GetScoredCompaniesPostRequest
 */
export interface BrainV1GetScoredCompaniesPostRequest {
    /**
     * 
     * @type {ScoredCompaniesRequest}
     * @memberof BrainV1GetScoredCompaniesPostRequest
     */
    'request': ScoredCompaniesRequest;
}
/**
 * 
 * @export
 * @interface BrainV1GetTaxonomyPostRequest
 */
export interface BrainV1GetTaxonomyPostRequest {
    /**
     * 
     * @type {string}
     * @memberof BrainV1GetTaxonomyPostRequest
     */
    'taxonomy_id': string;
}
/**
 * 
 * @export
 * @interface BrainV1GetTearsheetEnrichmentPostRequest
 */
export interface BrainV1GetTearsheetEnrichmentPostRequest {
    /**
     * 
     * @type {string}
     * @memberof BrainV1GetTearsheetEnrichmentPostRequest
     */
    'company_domain': string;
    /**
     * 
     * @type {TearsheetEnrichmentType}
     * @memberof BrainV1GetTearsheetEnrichmentPostRequest
     */
    'enrichment_type': TearsheetEnrichmentType;
}


/**
 * 
 * @export
 * @interface BrainV1GiveGenericWorkflowFeedbackPostRequest
 */
export interface BrainV1GiveGenericWorkflowFeedbackPostRequest {
    /**
     * 
     * @type {string}
     * @memberof BrainV1GiveGenericWorkflowFeedbackPostRequest
     */
    'workflow_id': string;
    /**
     * 
     * @type {WorkflowFeedback}
     * @memberof BrainV1GiveGenericWorkflowFeedbackPostRequest
     */
    'feedback': WorkflowFeedback;
}
/**
 * 
 * @export
 * @interface BrainV1IngestDocumentPostRequest
 */
export interface BrainV1IngestDocumentPostRequest {
    /**
     * 
     * @type {string}
     * @memberof BrainV1IngestDocumentPostRequest
     */
    'document_base64_encoded': string;
}
/**
 * 
 * @export
 * @interface BrainV1IngestWebpagePostRequest
 */
export interface BrainV1IngestWebpagePostRequest {
    /**
     * 
     * @type {string}
     * @memberof BrainV1IngestWebpagePostRequest
     */
    'webpage_url': string;
}
/**
 * 
 * @export
 * @interface BrainV1SetUserComingSoonUpvotePostRequest
 */
export interface BrainV1SetUserComingSoonUpvotePostRequest {
    /**
     * 
     * @type {WorkflowType}
     * @memberof BrainV1SetUserComingSoonUpvotePostRequest
     */
    'workflow_type'?: WorkflowType;
    /**
     * 
     * @type {boolean}
     * @memberof BrainV1SetUserComingSoonUpvotePostRequest
     */
    'upvoted'?: boolean;
}


/**
 * 
 * @export
 * @interface BrainV1SetUserDefaultScopePostRequest
 */
export interface BrainV1SetUserDefaultScopePostRequest {
    /**
     * 
     * @type {ImportableSearchScope}
     * @memberof BrainV1SetUserDefaultScopePostRequest
     */
    'scope': ImportableSearchScope;
}
/**
 * 
 * @export
 * @interface BrainV1SourceCompaniesInitialInputsToInputsPost200Response
 */
export interface BrainV1SourceCompaniesInitialInputsToInputsPost200Response {
    /**
     * 
     * @type {SourceCompaniesInputs}
     * @memberof BrainV1SourceCompaniesInitialInputsToInputsPost200Response
     */
    'final_inputs': SourceCompaniesInputs;
    /**
     * 
     * @type {string}
     * @memberof BrainV1SourceCompaniesInitialInputsToInputsPost200Response
     */
    'error_explanation'?: string;
}
/**
 * 
 * @export
 * @interface BrainV1UpdateLastViewedCompanyIdsPostRequest
 */
export interface BrainV1UpdateLastViewedCompanyIdsPostRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof BrainV1UpdateLastViewedCompanyIdsPostRequest
     */
    'company_ids': Array<string>;
}
/**
 * 
 * @export
 * @interface Business
 */
export interface Business {
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    'id': string;
    /**
     * 
     * @type {TextWithSources}
     * @memberof Business
     */
    'overview': TextWithSources;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    'domain': string;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    'linkedin_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    'logo'?: string;
    /**
     * 
     * @type {Array<TextWithSources>}
     * @memberof Business
     */
    'aliases'?: Array<TextWithSources>;
    /**
     * 
     * @type {TextWithSources}
     * @memberof Business
     */
    'employee_count'?: TextWithSources;
    /**
     * 
     * @type {TextWithSources}
     * @memberof Business
     */
    'founded'?: TextWithSources;
    /**
     * 
     * @type {TextWithSources}
     * @memberof Business
     */
    'headquarters'?: TextWithSources;
    /**
     * 
     * @type {BusinessOwnershipType}
     * @memberof Business
     */
    'ownership'?: BusinessOwnershipType;
    /**
     * 
     * @type {BusinessSummary}
     * @memberof Business
     */
    'summary': BusinessSummary;
    /**
     * 
     * @type {TextWithSources}
     * @memberof Business
     */
    'revenue'?: TextWithSources;
    /**
     * 
     * @type {TextWithSources}
     * @memberof Business
     */
    'market_valuation'?: TextWithSources;
    /**
     * 
     * @type {TextWithSources}
     * @memberof Business
     */
    'funds_raised'?: TextWithSources;
    /**
     * 
     * @type {Array<TextWithSources>}
     * @memberof Business
     */
    'investors'?: Array<TextWithSources>;
    /**
     * 
     * @type {{ [key: string]: TextWithSources; }}
     * @memberof Business
     */
    'research': { [key: string]: TextWithSources; };
    /**
     * 
     * @type {CompFinderSimilarity}
     * @memberof Business
     */
    'similarity'?: CompFinderSimilarity;
    /**
     * 
     * @type {number}
     * @memberof Business
     */
    'basic_research_timestamp'?: number;
    /**
     * 
     * @type {number}
     * @memberof Business
     */
    'provider_research_timestamp'?: number;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    'ticker'?: string;
    /**
     * 
     * @type {string}
     * @memberof Business
     */
    'industry'?: string;
    /**
     * 
     * @type {ReportedMetric}
     * @memberof Business
     */
    'ebitda'?: ReportedMetric;
    /**
     * 
     * @type {number}
     * @memberof Business
     */
    'market_cap'?: number;
    /**
     * 
     * @type {ReportedMetric}
     * @memberof Business
     */
    'enterprise_value'?: ReportedMetric;
    /**
     * 
     * @type {ReportedMetric}
     * @memberof Business
     */
    'earnings'?: ReportedMetric;
    /**
     * 
     * @type {ReportedMetric}
     * @memberof Business
     */
    'free_cash_flow'?: ReportedMetric;
    /**
     * 
     * @type {ReportedMetric}
     * @memberof Business
     */
    'revenue_growth'?: ReportedMetric;
    /**
     * 
     * @type {ReportedMetric}
     * @memberof Business
     */
    'gross_margin'?: ReportedMetric;
    /**
     * 
     * @type {ReportedMetric}
     * @memberof Business
     */
    'reported_employee_count'?: ReportedMetric;
    /**
     * 
     * @type {ReportedMetric}
     * @memberof Business
     */
    'reported_revenue'?: ReportedMetric;
    /**
     * 
     * @type {MultiReportedMetric}
     * @memberof Business
     */
    'ttm_revenue'?: MultiReportedMetric;
    /**
     * 
     * @type {MultiReportedMetric}
     * @memberof Business
     */
    'ttm_ebitda'?: MultiReportedMetric;
    /**
     * 
     * @type {MultiReportedMetric}
     * @memberof Business
     */
    'ttm_earnings'?: MultiReportedMetric;
    /**
     * 
     * @type {MultiReportedMetric}
     * @memberof Business
     */
    'ttm_free_cash_flow'?: MultiReportedMetric;
    /**
     * 
     * @type {MultiReportedMetric}
     * @memberof Business
     */
    'ttm_gross_margin'?: MultiReportedMetric;
    /**
     * 
     * @type {MultiReportedMetric}
     * @memberof Business
     */
    'ttm_ev_to_ebitda'?: MultiReportedMetric;
    /**
     * 
     * @type {MetricWithSources}
     * @memberof Business
     */
    'revenue_ttm'?: MetricWithSources;
    /**
     * 
     * @type {MetricWithSources}
     * @memberof Business
     */
    'ebitda_ttm'?: MetricWithSources;
    /**
     * 
     * @type {MetricWithSources}
     * @memberof Business
     */
    'earnings_ttm'?: MetricWithSources;
    /**
     * 
     * @type {MetricWithSources}
     * @memberof Business
     */
    'gross_margin_ttm'?: MetricWithSources;
    /**
     * 
     * @type {MetricWithSources}
     * @memberof Business
     */
    'free_cash_flow_ttm'?: MetricWithSources;
    /**
     * 
     * @type {MetricWithSources}
     * @memberof Business
     */
    'ev_to_ebitda_ttm'?: MetricWithSources;
    /**
     * 
     * @type {MetricWithSources}
     * @memberof Business
     */
    'ev_to_revenue_ttm'?: MetricWithSources;
    /**
     * 
     * @type {Array<BusinessManagementItem>}
     * @memberof Business
     */
    'management'?: Array<BusinessManagementItem>;
    /**
     * 
     * @type {Array<PressItem>}
     * @memberof Business
     */
    'recent_press'?: Array<PressItem>;
    /**
     * 
     * @type {BusinessCommentary}
     * @memberof Business
     */
    'commentary'?: BusinessCommentary;
    /**
     * 
     * @type {BusinessSemanticFields}
     * @memberof Business
     */
    'semantic_fields'?: BusinessSemanticFields;
}
/**
 * 
 * @export
 * @interface BusinessCommentary
 */
export interface BusinessCommentary {
    /**
     * 
     * @type {Array<string>}
     * @memberof BusinessCommentary
     */
    'advantages'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BusinessCommentary
     */
    'disadvantages'?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof BusinessCommentary
     */
    'differentiation'?: Array<string>;
}
/**
 * 
 * @export
 * @interface BusinessManagementItem
 */
export interface BusinessManagementItem {
    /**
     * 
     * @type {string}
     * @memberof BusinessManagementItem
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessManagementItem
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessManagementItem
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessManagementItem
     */
    'linkedin_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessManagementItem
     */
    'image'?: string;
}
/**
 * 
 * @export
 * @interface BusinessOwnershipType
 */
export interface BusinessOwnershipType {
    /**
     * 
     * @type {string}
     * @memberof BusinessOwnershipType
     */
    'value': BusinessOwnershipTypeValueEnum;
    /**
     * 
     * @type {Array<Source>}
     * @memberof BusinessOwnershipType
     */
    'sources': Array<Source>;
}

export const BusinessOwnershipTypeValueEnum = {
    Public: 'public',
    Private: 'private',
    Subsidiary: 'subsidiary',
    JointVenture: 'joint_venture',
    Defunct: 'defunct',
    InvestmentFund: 'investment_fund'
} as const;

export type BusinessOwnershipTypeValueEnum = typeof BusinessOwnershipTypeValueEnum[keyof typeof BusinessOwnershipTypeValueEnum];

/**
 * 
 * @export
 * @interface BusinessPressItem
 */
export interface BusinessPressItem {
    /**
     * 
     * @type {string}
     * @memberof BusinessPressItem
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof BusinessPressItem
     */
    'link': string;
    /**
     * 
     * @type {string}
     * @memberof BusinessPressItem
     */
    'summary': string;
    /**
     * 
     * @type {string}
     * @memberof BusinessPressItem
     */
    'source_name'?: string;
    /**
     * 
     * @type {number}
     * @memberof BusinessPressItem
     */
    'publish_timestamp'?: number;
}
/**
 * 
 * @export
 * @interface BusinessSemanticFields
 */
export interface BusinessSemanticFields {
    /**
     * 
     * @type {TextWithSources}
     * @memberof BusinessSemanticFields
     */
    'business_model'?: TextWithSources;
    /**
     * 
     * @type {TextWithSources}
     * @memberof BusinessSemanticFields
     */
    'main_offerings'?: TextWithSources;
    /**
     * 
     * @type {Array<TextWithSources>}
     * @memberof BusinessSemanticFields
     */
    'main_offerings_list'?: Array<TextWithSources>;
    /**
     * 
     * @type {TextWithSources}
     * @memberof BusinessSemanticFields
     */
    'customer_segments'?: TextWithSources;
    /**
     * 
     * @type {Array<TextWithSources>}
     * @memberof BusinessSemanticFields
     */
    'customer_segments_list'?: Array<TextWithSources>;
    /**
     * 
     * @type {TextWithSources}
     * @memberof BusinessSemanticFields
     */
    'geographies'?: TextWithSources;
    /**
     * 
     * @type {Array<TextWithSources>}
     * @memberof BusinessSemanticFields
     */
    'geographies_list'?: Array<TextWithSources>;
    /**
     * 
     * @type {TextWithSources}
     * @memberof BusinessSemanticFields
     */
    'technologies'?: TextWithSources;
    /**
     * 
     * @type {Array<TextWithSources>}
     * @memberof BusinessSemanticFields
     */
    'technologies_list'?: Array<TextWithSources>;
}
/**
 * 
 * @export
 * @interface BusinessShell
 */
export interface BusinessShell {
    /**
     * 
     * @type {string}
     * @memberof BusinessShell
     */
    'name': string;
    /**
     * 
     * @type {TextWithSources}
     * @memberof BusinessShell
     */
    'overview': TextWithSources;
}
/**
 * 
 * @export
 * @interface BusinessShellWithDomain
 */
export interface BusinessShellWithDomain {
    /**
     * 
     * @type {string}
     * @memberof BusinessShellWithDomain
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof BusinessShellWithDomain
     */
    'overview': string;
    /**
     * 
     * @type {string}
     * @memberof BusinessShellWithDomain
     */
    'domain': string;
}
/**
 * 
 * @export
 * @interface BusinessStore
 */
export interface BusinessStore {
    /**
     * 
     * @type {{ [key: string]: Business; }}
     * @memberof BusinessStore
     */
    'businesses': { [key: string]: Business; };
}
/**
 * 
 * @export
 * @interface BusinessSummary
 */
export interface BusinessSummary {
    /**
     * 
     * @type {TextWithSources}
     * @memberof BusinessSummary
     */
    'business_model'?: TextWithSources;
    /**
     * 
     * @type {Array<TextWithSources>}
     * @memberof BusinessSummary
     */
    'main_offerings'?: Array<TextWithSources>;
    /**
     * 
     * @type {Array<TextWithSources>}
     * @memberof BusinessSummary
     */
    'customer_groups'?: Array<TextWithSources>;
    /**
     * 
     * @type {MaturityLevel}
     * @memberof BusinessSummary
     */
    'maturity_level'?: MaturityLevel;
    /**
     * 
     * @type {TextWithSources}
     * @memberof BusinessSummary
     */
    'geographies'?: TextWithSources;
}
/**
 * 
 * @export
 * @interface BusinessSummaryFile
 */
export interface BusinessSummaryFile {
    /**
     * 
     * @type {TextWithSources}
     * @memberof BusinessSummaryFile
     */
    'overview'?: TextWithSources;
    /**
     * 
     * @type {TextWithSources}
     * @memberof BusinessSummaryFile
     */
    'business_model'?: TextWithSources;
    /**
     * 
     * @type {TextWithSources}
     * @memberof BusinessSummaryFile
     */
    'main_offerings'?: TextWithSources;
    /**
     * 
     * @type {TextWithSources}
     * @memberof BusinessSummaryFile
     */
    'customer_groups'?: TextWithSources;
    /**
     * 
     * @type {TextWithSources}
     * @memberof BusinessSummaryFile
     */
    'geographies'?: TextWithSources;
    /**
     * 
     * @type {TextWithSources}
     * @memberof BusinessSummaryFile
     */
    'scale'?: TextWithSources;
}
/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    'description': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Category
     */
    'exemplars': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Category
     */
    'companies': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof Category
     */
    'bespoke_questions': Array<string>;
}
/**
 * 
 * @export
 * @interface CategoryScoringResult
 */
export interface CategoryScoringResult {
    /**
     * 
     * @type {number}
     * @memberof CategoryScoringResult
     */
    'overall_score'?: number;
    /**
     * 
     * @type {Array<ScoringTopicResult>}
     * @memberof CategoryScoringResult
     */
    'topic_results': Array<ScoringTopicResult>;
}
/**
 * 
 * @export
 * @interface CommercialDive
 */
export interface CommercialDive {
    /**
     * 
     * @type {string}
     * @memberof CommercialDive
     */
    'company_domain': string;
    /**
     * 
     * @type {Array<CustomerGroup>}
     * @memberof CommercialDive
     */
    'customer_groups': Array<CustomerGroup>;
    /**
     * 
     * @type {Array<Partner>}
     * @memberof CommercialDive
     */
    'partners': Array<Partner>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CommercialDive
     */
    'customers': Array<string>;
    /**
     * 
     * @type {Array<SalesMotion>}
     * @memberof CommercialDive
     */
    'sales_motions': Array<SalesMotion>;
    /**
     * 
     * @type {Array<CustomerClass>}
     * @memberof CommercialDive
     */
    'customer_classes': Array<CustomerClass>;
    /**
     * 
     * @type {Array<Source>}
     * @memberof CommercialDive
     */
    'sources': Array<Source>;
}
/**
 * 
 * @export
 * @interface CompFinderInputsV5
 */
export interface CompFinderInputsV5 {
    /**
     * 
     * @type {string}
     * @memberof CompFinderInputsV5
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompFinderInputsV5
     */
    'domain': string;
    /**
     * 
     * @type {string}
     * @memberof CompFinderInputsV5
     */
    'guidance'?: string;
    /**
     * 
     * @type {string}
     * @memberof CompFinderInputsV5
     */
    'user_email'?: string;
    /**
     * 
     * @type {MarketMap}
     * @memberof CompFinderInputsV5
     */
    'user_market_map'?: MarketMap;
    /**
     * 
     * @type {string}
     * @memberof CompFinderInputsV5
     */
    'target_business_id'?: string;
}
/**
 * 
 * @export
 * @interface CompFinderOutputsV5
 */
export interface CompFinderOutputsV5 {
    /**
     * 
     * @type {MarketMap}
     * @memberof CompFinderOutputsV5
     */
    'market_map': MarketMap;
    /**
     * 
     * @type {Array<Business>}
     * @memberof CompFinderOutputsV5
     */
    'businesses': Array<Business>;
    /**
     * 
     * @type {CompFinderOutputsV5Effort}
     * @memberof CompFinderOutputsV5
     */
    'effort'?: CompFinderOutputsV5Effort;
}
/**
 * 
 * @export
 * @interface CompFinderOutputsV5Effort
 */
export interface CompFinderOutputsV5Effort {
    /**
     * 
     * @type {number}
     * @memberof CompFinderOutputsV5Effort
     */
    'number_of_articles_read': number;
    /**
     * 
     * @type {number}
     * @memberof CompFinderOutputsV5Effort
     */
    'number_of_companies_researched': number;
    /**
     * 
     * @type {number}
     * @memberof CompFinderOutputsV5Effort
     */
    'number_of_llm_calls': number;
}
/**
 * 
 * @export
 * @interface CompFinderSimilarity
 */
export interface CompFinderSimilarity {
    /**
     * 
     * @type {number}
     * @memberof CompFinderSimilarity
     */
    'overall_similarity'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompFinderSimilarity
     */
    'business_model'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompFinderSimilarity
     */
    'main_offerings'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompFinderSimilarity
     */
    'customer_groups'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompFinderSimilarity
     */
    'geographies'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompFinderSimilarity
     */
    'maturity_level'?: number;
}
/**
 * 
 * @export
 * @interface CompFinderWorkflowStubV5
 */
export interface CompFinderWorkflowStubV5 {
    /**
     * 
     * @type {string}
     * @memberof CompFinderWorkflowStubV5
     */
    'workflow_id': string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof CompFinderWorkflowStubV5
     */
    'status': TaskStatus;
    /**
     * 
     * @type {string}
     * @memberof CompFinderWorkflowStubV5
     */
    'title': string;
    /**
     * 
     * @type {boolean}
     * @memberof CompFinderWorkflowStubV5
     */
    'hack_fill_out_market_map'?: boolean;
}


/**
 * 
 * @export
 * @interface CompFinderWorkflowV5
 */
export interface CompFinderWorkflowV5 {
    /**
     * 
     * @type {string}
     * @memberof CompFinderWorkflowV5
     */
    'workflow_id': string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof CompFinderWorkflowV5
     */
    'status': TaskStatus;
    /**
     * 
     * @type {CompFinderInputsV5}
     * @memberof CompFinderWorkflowV5
     */
    'inputs': CompFinderInputsV5;
    /**
     * 
     * @type {CompFinderOutputsV5}
     * @memberof CompFinderWorkflowV5
     */
    'outputs'?: CompFinderOutputsV5;
    /**
     * 
     * @type {number}
     * @memberof CompFinderWorkflowV5
     */
    'start_timestamp_seconds'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CompFinderWorkflowV5
     */
    'hack_fill_out_market_map'?: boolean;
    /**
     * 
     * @type {WorkflowFeedback}
     * @memberof CompFinderWorkflowV5
     */
    'feedback'?: WorkflowFeedback;
}


/**
 * 
 * @export
 * @interface CompLandscapeCompany
 */
export interface CompLandscapeCompany {
    /**
     * 
     * @type {FrontendCompany}
     * @memberof CompLandscapeCompany
     */
    'company': FrontendCompany;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompLandscapeCompany
     */
    'categories_belonging': Array<string>;
}
/**
 * 
 * @export
 * @interface CompLandscapeInputs
 */
export interface CompLandscapeInputs {
    /**
     * 
     * @type {string}
     * @memberof CompLandscapeInputs
     */
    'company_website': string;
    /**
     * 
     * @type {string}
     * @memberof CompLandscapeInputs
     */
    'grouping_instructions'?: string;
    /**
     * 
     * @type {MarketMap}
     * @memberof CompLandscapeInputs
     */
    'market_map'?: MarketMap;
}
/**
 * 
 * @export
 * @interface CompLandscapeOutputs
 */
export interface CompLandscapeOutputs {
    /**
     * 
     * @type {MarketMap}
     * @memberof CompLandscapeOutputs
     */
    'market_map': MarketMap;
    /**
     * 
     * @type {Array<CompLandscapeCompany>}
     * @memberof CompLandscapeOutputs
     */
    'companies': Array<CompLandscapeCompany>;
}
/**
 * 
 * @export
 * @interface CompLandscapeV7Workflow
 */
export interface CompLandscapeV7Workflow {
    /**
     * 
     * @type {CompLandscapeInputs}
     * @memberof CompLandscapeV7Workflow
     */
    'inputs': CompLandscapeInputs;
    /**
     * 
     * @type {CompLandscapeOutputs}
     * @memberof CompLandscapeV7Workflow
     */
    'outputs'?: CompLandscapeOutputs;
    /**
     * 
     * @type {string}
     * @memberof CompLandscapeV7Workflow
     */
    'parse_helper': CompLandscapeV7WorkflowParseHelperEnum;
}

export const CompLandscapeV7WorkflowParseHelperEnum = {
    CompLandscapeV7: 'comp_landscape_v7'
} as const;

export type CompLandscapeV7WorkflowParseHelperEnum = typeof CompLandscapeV7WorkflowParseHelperEnum[keyof typeof CompLandscapeV7WorkflowParseHelperEnum];

/**
 * 
 * @export
 * @interface CompanyAssessment
 */
export interface CompanyAssessment {
    /**
     * 
     * @type {Array<ScoringAnswer>}
     * @memberof CompanyAssessment
     */
    'scoring_answers'?: Array<ScoringAnswer>;
    /**
     * 
     * @type {number}
     * @memberof CompanyAssessment
     */
    'overall_score'?: number;
}
/**
 * 
 * @export
 * @interface CompanyCommentary
 */
export interface CompanyCommentary {
    /**
     * 
     * @type {Array<TalkingPoint>}
     * @memberof CompanyCommentary
     */
    'talking_points': Array<TalkingPoint>;
    /**
     * 
     * @type {CompanyAssessment}
     * @memberof CompanyCommentary
     */
    'assessment'?: CompanyAssessment;
}
/**
 * 
 * @export
 * @interface CompanyCustomResearchAnswerSet
 */
export interface CompanyCustomResearchAnswerSet {
    /**
     * 
     * @type {Business}
     * @memberof CompanyCustomResearchAnswerSet
     */
    'business': Business;
    /**
     * 
     * @type {Array<TextWithSourcesAndHeadline>}
     * @memberof CompanyCustomResearchAnswerSet
     */
    'answers_to_subtopics': Array<TextWithSourcesAndHeadline>;
}
/**
 * 
 * @export
 * @interface CompanyCustomResearchInputsV1
 */
export interface CompanyCustomResearchInputsV1 {
    /**
     * 
     * @type {string}
     * @memberof CompanyCustomResearchInputsV1
     */
    'topic': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyCustomResearchInputsV1
     */
    'businesses': Array<string>;
}
/**
 * 
 * @export
 * @interface CompanyCustomResearchOutputV1
 */
export interface CompanyCustomResearchOutputV1 {
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyCustomResearchOutputV1
     */
    'subtopics': Array<string>;
    /**
     * 
     * @type {Array<CompanyCustomResearchAnswerSet>}
     * @memberof CompanyCustomResearchOutputV1
     */
    'answer_sets': Array<CompanyCustomResearchAnswerSet>;
}
/**
 * 
 * @export
 * @interface CompanyCustomResearchWorkflowV1
 */
export interface CompanyCustomResearchWorkflowV1 {
    /**
     * 
     * @type {CompanyCustomResearchInputsV1}
     * @memberof CompanyCustomResearchWorkflowV1
     */
    'inputs': CompanyCustomResearchInputsV1;
    /**
     * 
     * @type {CompanyCustomResearchOutputV1}
     * @memberof CompanyCustomResearchWorkflowV1
     */
    'outputs'?: CompanyCustomResearchOutputV1;
    /**
     * 
     * @type {string}
     * @memberof CompanyCustomResearchWorkflowV1
     */
    'parse_helper': CompanyCustomResearchWorkflowV1ParseHelperEnum;
}

export const CompanyCustomResearchWorkflowV1ParseHelperEnum = {
    CompanyCustomResearch: 'company_custom_research'
} as const;

export type CompanyCustomResearchWorkflowV1ParseHelperEnum = typeof CompanyCustomResearchWorkflowV1ParseHelperEnum[keyof typeof CompanyCustomResearchWorkflowV1ParseHelperEnum];

/**
 * 
 * @export
 * @interface CompanyList
 */
export interface CompanyList {
    /**
     * 
     * @type {string}
     * @memberof CompanyList
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CompanyList
     */
    'title': string;
    /**
     * 
     * @type {Array<FrontendCompany>}
     * @memberof CompanyList
     */
    'companies': Array<FrontendCompany>;
    /**
     * 
     * @type {Array<string>}
     * @memberof CompanyList
     */
    'input_images_base64_encoded'?: Array<string>;
}
/**
 * 
 * @export
 * @interface CompanyScoringResult
 */
export interface CompanyScoringResult {
    /**
     * 
     * @type {number}
     * @memberof CompanyScoringResult
     */
    'overall_score'?: number;
    /**
     * 
     * @type {Array<ScoringTopicResult>}
     * @memberof CompanyScoringResult
     */
    'topic_results': Array<ScoringTopicResult>;
}
/**
 * 
 * @export
 * @interface CompanySummary
 */
export interface CompanySummary {
    /**
     * 
     * @type {TextWithSources}
     * @memberof CompanySummary
     */
    'overview': TextWithSources;
    /**
     * 
     * @type {TextWithSources}
     * @memberof CompanySummary
     */
    'business_model': TextWithSources;
    /**
     * 
     * @type {TextWithSources}
     * @memberof CompanySummary
     */
    'main_offerings': TextWithSources;
    /**
     * 
     * @type {TextWithSources}
     * @memberof CompanySummary
     */
    'customer_segments': TextWithSources;
    /**
     * 
     * @type {TextWithSources}
     * @memberof CompanySummary
     */
    'geographies': TextWithSources;
    /**
     * 
     * @type {TextWithSources}
     * @memberof CompanySummary
     */
    'technologies': TextWithSources;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CompanySummaryField = {
    BusinessModel: 'business_model',
    MainOfferings: 'main_offerings',
    CustomerGroups: 'customer_groups',
    Geographies: 'geographies',
    MaturityLevel: 'maturity_level',
    Technologies: 'technologies',
    Custom: 'custom'
} as const;

export type CompanySummaryField = typeof CompanySummaryField[keyof typeof CompanySummaryField];


/**
 * 
 * @export
 * @interface CompanyTearsheet
 */
export interface CompanyTearsheet {
    /**
     * 
     * @type {FrontendCompany}
     * @memberof CompanyTearsheet
     */
    'company': FrontendCompany;
    /**
     * 
     * @type {CommercialDive}
     * @memberof CompanyTearsheet
     */
    'commercial_dive'?: CommercialDive;
    /**
     * 
     * @type {OfferingsDive}
     * @memberof CompanyTearsheet
     */
    'offerings_dive'?: OfferingsDive;
    /**
     * 
     * @type {TeamDive}
     * @memberof CompanyTearsheet
     */
    'team_dive'?: TeamDive;
    /**
     * 
     * @type {RecentDevelopments}
     * @memberof CompanyTearsheet
     */
    'recent_developments'?: RecentDevelopments;
    /**
     * 
     * @type {FeaturedDocuments}
     * @memberof CompanyTearsheet
     */
    'featured_documents'?: FeaturedDocuments;
    /**
     * 
     * @type {RelatedCompanies}
     * @memberof CompanyTearsheet
     */
    'related_companies'?: RelatedCompanies;
    /**
     * 
     * @type {CompanyCommentary}
     * @memberof CompanyTearsheet
     */
    'commentary'?: CompanyCommentary;
    /**
     * 
     * @type {Array<TearsheetEnrichment>}
     * @memberof CompanyTearsheet
     */
    'enrichments'?: Array<TearsheetEnrichment>;
    /**
     * 
     * @type {DealcloudCompanyInfo}
     * @memberof CompanyTearsheet
     */
    'dealcloud_company_info'?: DealcloudCompanyInfo;
}
/**
 * 
 * @export
 * @interface CompanyTearsheetStreamingMessage
 */
export interface CompanyTearsheetStreamingMessage {
    /**
     * 
     * @type {TaskStatus}
     * @memberof CompanyTearsheetStreamingMessage
     */
    'status': TaskStatus;
    /**
     * 
     * @type {CompanyTearsheet}
     * @memberof CompanyTearsheetStreamingMessage
     */
    'tearsheet'?: CompanyTearsheet;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const CompanyType = {
    SmallPrivate: 'SmallPrivate',
    EarlyStageStartup: 'EarlyStageStartup',
    GrowthStageStartup: 'GrowthStageStartup',
    EstablishedPrivate: 'EstablishedPrivate',
    Public: 'Public',
    NotForProfit: 'NotForProfit',
    Subsidiary: 'Subsidiary',
    InvestmentFund: 'InvestmentFund',
    Defunct: 'Defunct'
} as const;

export type CompanyType = typeof CompanyType[keyof typeof CompanyType];


/**
 * 
 * @export
 * @interface Confidence
 */
export interface Confidence {
    /**
     * 
     * @type {number}
     * @memberof Confidence
     */
    'overall': number;
    /**
     * 
     * @type {number}
     * @memberof Confidence
     */
    'sources'?: number;
    /**
     * 
     * @type {number}
     * @memberof Confidence
     */
    'understanding'?: number;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const CustomerClass = {
    LargeEnterprise: 'LargeEnterprise',
    MidMarket: 'MidMarket',
    Smb: 'SMB',
    GovernmentPublicSector: 'GovernmentPublicSector',
    Consumer: 'Consumer',
    Marketplace: 'Marketplace'
} as const;

export type CustomerClass = typeof CustomerClass[keyof typeof CustomerClass];


/**
 * 
 * @export
 * @interface CustomerGroup
 */
export interface CustomerGroup {
    /**
     * 
     * @type {string}
     * @memberof CustomerGroup
     */
    'name': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CustomerGroup
     */
    'buyer_personas': Array<string>;
}
/**
 * 
 * @export
 * @interface DataElement
 */
export interface DataElement {
    /**
     * 
     * @type {Array<Source>}
     * @memberof DataElement
     */
    'sources'?: Array<Source>;
    /**
     * 
     * @type {Array<Source>}
     * @memberof DataElement
     */
    'otherSources'?: Array<Source>;
    /**
     * 
     * @type {Confidence}
     * @memberof DataElement
     */
    'confidence'?: Confidence;
    /**
     * 
     * @type {DataElementValue}
     * @memberof DataElement
     */
    'value': DataElementValue;
    /**
     * 
     * @type {string}
     * @memberof DataElement
     */
    'origin'?: DataElementOriginEnum;
}

export const DataElementOriginEnum = {
    User: 'user',
    Meticulate: 'meticulate',
    UserModified: 'user-modified'
} as const;

export type DataElementOriginEnum = typeof DataElementOriginEnum[keyof typeof DataElementOriginEnum];

/**
 * @type DataElementValue
 * @export
 */
export type DataElementValue = object | string;

/**
 * 
 * @export
 * @interface DealcloudCompanyInfo
 */
export interface DealcloudCompanyInfo {
    /**
     * 
     * @type {string}
     * @memberof DealcloudCompanyInfo
     */
    'entry_id': string;
    /**
     * 
     * @type {string}
     * @memberof DealcloudCompanyInfo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DealcloudCompanyInfo
     */
    'domain': string;
    /**
     * 
     * @type {Array<DealcloudInteraction>}
     * @memberof DealcloudCompanyInfo
     */
    'interactions': Array<DealcloudInteraction>;
    /**
     * 
     * @type {Array<DealcloudUser>}
     * @memberof DealcloudCompanyInfo
     */
    'relationship_owners': Array<DealcloudUser>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DealcloudCompanyInfo
     */
    'coverage_status': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DealcloudCompanyInfo
     */
    'company_type': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DealcloudCompanyInfo
     */
    'status': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof DealcloudCompanyInfo
     */
    'priority': Array<string>;
    /**
     * 
     * @type {Array<DealcloudUser>}
     * @memberof DealcloudCompanyInfo
     */
    'deal_team_users': Array<DealcloudUser>;
    /**
     * 
     * @type {Array<DealcloudCompanyInfoRelatedCompaniesInner>}
     * @memberof DealcloudCompanyInfo
     */
    'related_companies'?: Array<DealcloudCompanyInfoRelatedCompaniesInner>;
}
/**
 * 
 * @export
 * @interface DealcloudCompanyInfoRelatedCompaniesInner
 */
export interface DealcloudCompanyInfoRelatedCompaniesInner {
    /**
     * 
     * @type {FrontendCompany}
     * @memberof DealcloudCompanyInfoRelatedCompaniesInner
     */
    'company'?: FrontendCompany;
    /**
     * 
     * @type {Array<DealcloudUser>}
     * @memberof DealcloudCompanyInfoRelatedCompaniesInner
     */
    'users'?: Array<DealcloudUser>;
    /**
     * 
     * @type {Array<DealcloudInteraction>}
     * @memberof DealcloudCompanyInfoRelatedCompaniesInner
     */
    'interactions'?: Array<DealcloudInteraction>;
}
/**
 * 
 * @export
 * @interface DealcloudInteraction
 */
export interface DealcloudInteraction {
    /**
     * 
     * @type {Array<string>}
     * @memberof DealcloudInteraction
     */
    'interaction_type'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof DealcloudInteraction
     */
    'date'?: string;
    /**
     * 
     * @type {Array<DealcloudUser>}
     * @memberof DealcloudInteraction
     */
    'entered_by'?: Array<DealcloudUser>;
    /**
     * 
     * @type {Array<DealcloudUser>}
     * @memberof DealcloudInteraction
     */
    'initiator'?: Array<DealcloudUser>;
    /**
     * 
     * @type {string}
     * @memberof DealcloudInteraction
     */
    'subject'?: string;
}
/**
 * 
 * @export
 * @interface DealcloudUser
 */
export interface DealcloudUser {
    /**
     * 
     * @type {number}
     * @memberof DealcloudUser
     */
    'id'?: number;
    /**
     * 
     * @type {string}
     * @memberof DealcloudUser
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof DealcloudUser
     */
    'email'?: string;
}
/**
 * 
 * @export
 * @interface DimensionGuidance
 */
export interface DimensionGuidance {
    /**
     * 
     * @type {string}
     * @memberof DimensionGuidance
     */
    'background'?: string;
    /**
     * 
     * @type {string}
     * @memberof DimensionGuidance
     */
    'positive_guidance'?: string;
    /**
     * 
     * @type {string}
     * @memberof DimensionGuidance
     */
    'negative_guidance'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const DocumentType = {
    CompanyPage: 'company_page',
    BlogPost: 'blog_post',
    News: 'news',
    Forum: 'forum',
    CompanyProfile: 'company_profile',
    PressRelease: 'press_release',
    Other: 'other',
    Cim: 'cim',
    IcMemo: 'ic_memo',
    TwoPager: 'two_pager',
    CallNotes: 'call_notes',
    ResearchReport: 'research_report',
    Qoe: 'qoe',
    CompanyPresentation: 'company_presentation',
    TermSheet: 'term_sheet'
} as const;

export type DocumentType = typeof DocumentType[keyof typeof DocumentType];


/**
 * 
 * @export
 * @interface EnrichmentsAdminInfo
 */
export interface EnrichmentsAdminInfo {
    /**
     * 
     * @type {Array<TearsheetEnrichment>}
     * @memberof EnrichmentsAdminInfo
     */
    'last_pulled_enrichments': Array<TearsheetEnrichment>;
    /**
     * 
     * @type {number}
     * @memberof EnrichmentsAdminInfo
     */
    'total_pulled_enrichments': number;
    /**
     * 
     * @type {Array<EnrichmentsAdminInfoEnrichmentCountsByUserInner>}
     * @memberof EnrichmentsAdminInfo
     */
    'enrichment_counts_by_user': Array<EnrichmentsAdminInfoEnrichmentCountsByUserInner>;
    /**
     * 
     * @type {Array<EnrichmentsAdminInfoEnrichmentCountsByTypeInner>}
     * @memberof EnrichmentsAdminInfo
     */
    'enrichment_counts_by_type': Array<EnrichmentsAdminInfoEnrichmentCountsByTypeInner>;
}
/**
 * 
 * @export
 * @interface EnrichmentsAdminInfoEnrichmentCountsByTypeInner
 */
export interface EnrichmentsAdminInfoEnrichmentCountsByTypeInner {
    /**
     * 
     * @type {string}
     * @memberof EnrichmentsAdminInfoEnrichmentCountsByTypeInner
     */
    'enrichment_type': string;
    /**
     * 
     * @type {number}
     * @memberof EnrichmentsAdminInfoEnrichmentCountsByTypeInner
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface EnrichmentsAdminInfoEnrichmentCountsByUserInner
 */
export interface EnrichmentsAdminInfoEnrichmentCountsByUserInner {
    /**
     * 
     * @type {string}
     * @memberof EnrichmentsAdminInfoEnrichmentCountsByUserInner
     */
    'user_id': string;
    /**
     * 
     * @type {number}
     * @memberof EnrichmentsAdminInfoEnrichmentCountsByUserInner
     */
    'count': number;
}
/**
 * 
 * @export
 * @interface FeaturedDocuments
 */
export interface FeaturedDocuments {
    /**
     * 
     * @type {Array<FrontendDocument>}
     * @memberof FeaturedDocuments
     */
    'common_mentioned': Array<FrontendDocument>;
    /**
     * 
     * @type {Array<FrontendDocument>}
     * @memberof FeaturedDocuments
     */
    'internal_documents'?: Array<FrontendDocument>;
}
/**
 * 
 * @export
 * @interface FindCompaniesInputsV1
 */
export interface FindCompaniesInputsV1 {
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesInputsV1
     */
    'category_name': string;
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesInputsV1
     */
    'business_model': string;
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesInputsV1
     */
    'main_offerings': string;
    /**
     * 
     * @type {Array<HardFilter>}
     * @memberof FindCompaniesInputsV1
     */
    'filters': Array<HardFilter>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FindCompaniesInputsV1
     */
    'sample_business_domains': Array<string>;
}
/**
 * 
 * @export
 * @interface FindCompaniesInstructions
 */
export interface FindCompaniesInstructions {
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesInstructions
     */
    'category_name': string;
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesInstructions
     */
    'business_model': string;
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesInstructions
     */
    'main_offerings': string;
    /**
     * 
     * @type {Array<Business>}
     * @memberof FindCompaniesInstructions
     */
    'sample_businesses': Array<Business>;
    /**
     * 
     * @type {Array<HardFilter>}
     * @memberof FindCompaniesInstructions
     */
    'filters': Array<HardFilter>;
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesInstructions
     */
    'customer_groups'?: string;
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesInstructions
     */
    'geographies'?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof FindCompaniesInstructions
     */
    'screening_dict'?: { [key: string]: string; };
}
/**
 * 
 * @export
 * @interface FindCompaniesOutputsV1
 */
export interface FindCompaniesOutputsV1 {
    /**
     * 
     * @type {Array<Business>}
     * @memberof FindCompaniesOutputsV1
     */
    'businesses': Array<Business>;
}
/**
 * 
 * @export
 * @interface FindCompaniesOutputsV2
 */
export interface FindCompaniesOutputsV2 {
    /**
     * 
     * @type {Array<FrontendCompany>}
     * @memberof FindCompaniesOutputsV2
     */
    'companies': Array<FrontendCompany>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FindCompaniesOutputsV2
     */
    'removed_company_domains'?: Array<string>;
}
/**
 * 
 * @export
 * @interface FindCompaniesV2InitialInputs
 */
export interface FindCompaniesV2InitialInputs {
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesV2InitialInputs
     */
    'category_name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FindCompaniesV2InitialInputs
     */
    'exemplar_company_domains'?: Array<string>;
    /**
     * 
     * @type {Array<HardFilter>}
     * @memberof FindCompaniesV2InitialInputs
     */
    'filters'?: Array<HardFilter>;
    /**
     * 
     * @type {Array<CompanyType>}
     * @memberof FindCompaniesV2InitialInputs
     */
    'company_type_filter'?: Array<CompanyType>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FindCompaniesV2InitialInputs
     */
    'hq_country_filter'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof FindCompaniesV2InitialInputs
     */
    'requested_number_of_companies'?: number;
}
/**
 * 
 * @export
 * @interface FindCompaniesV2Inputs
 */
export interface FindCompaniesV2Inputs {
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesV2Inputs
     */
    'category_name': string;
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesV2Inputs
     */
    'business_model'?: string;
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesV2Inputs
     */
    'main_offerings'?: string;
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesV2Inputs
     */
    'customer_segments'?: string;
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesV2Inputs
     */
    'geographies'?: string;
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesV2Inputs
     */
    'technologies'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FindCompaniesV2Inputs
     */
    'exemplar_company_domains'?: Array<string>;
    /**
     * 
     * @type {Array<HardFilter>}
     * @memberof FindCompaniesV2Inputs
     */
    'filters'?: Array<HardFilter>;
    /**
     * 
     * @type {Array<CompanyType>}
     * @memberof FindCompaniesV2Inputs
     */
    'company_type_filter'?: Array<CompanyType>;
    /**
     * 
     * @type {Array<string>}
     * @memberof FindCompaniesV2Inputs
     */
    'hq_country_filter'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof FindCompaniesV2Inputs
     */
    'requested_number_of_companies'?: number;
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesV2Inputs
     */
    'market_map_image_base64_encoded'?: string;
}
/**
 * 
 * @export
 * @interface FindCompaniesWorkflowV1
 */
export interface FindCompaniesWorkflowV1 {
    /**
     * 
     * @type {FindCompaniesInputsV1}
     * @memberof FindCompaniesWorkflowV1
     */
    'inputs': FindCompaniesInputsV1;
    /**
     * 
     * @type {FindCompaniesOutputsV1}
     * @memberof FindCompaniesWorkflowV1
     */
    'outputs'?: FindCompaniesOutputsV1;
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesWorkflowV1
     */
    'parse_helper': FindCompaniesWorkflowV1ParseHelperEnum;
}

export const FindCompaniesWorkflowV1ParseHelperEnum = {
    FindCompanies: 'find_companies'
} as const;

export type FindCompaniesWorkflowV1ParseHelperEnum = typeof FindCompaniesWorkflowV1ParseHelperEnum[keyof typeof FindCompaniesWorkflowV1ParseHelperEnum];

/**
 * 
 * @export
 * @interface FindCompaniesWorkflowV2
 */
export interface FindCompaniesWorkflowV2 {
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesWorkflowV2
     */
    'input_generation_state'?: FindCompaniesWorkflowV2InputGenerationStateEnum;
    /**
     * 
     * @type {FindCompaniesV2InitialInputs}
     * @memberof FindCompaniesWorkflowV2
     */
    'frontend_initial_inputs'?: FindCompaniesV2InitialInputs;
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesWorkflowV2
     */
    'error_explanation'?: string;
    /**
     * 
     * @type {FindCompaniesV2Inputs}
     * @memberof FindCompaniesWorkflowV2
     */
    'inputs': FindCompaniesV2Inputs;
    /**
     * 
     * @type {FindCompaniesOutputsV2}
     * @memberof FindCompaniesWorkflowV2
     */
    'outputs'?: FindCompaniesOutputsV2;
    /**
     * 
     * @type {string}
     * @memberof FindCompaniesWorkflowV2
     */
    'parse_helper': FindCompaniesWorkflowV2ParseHelperEnum;
}

export const FindCompaniesWorkflowV2InputGenerationStateEnum = {
    Initial: 'initial',
    Loading: 'loading',
    Final: 'final',
    UploadList: 'upload_list',
    FindSimilar: 'find_similar'
} as const;

export type FindCompaniesWorkflowV2InputGenerationStateEnum = typeof FindCompaniesWorkflowV2InputGenerationStateEnum[keyof typeof FindCompaniesWorkflowV2InputGenerationStateEnum];
export const FindCompaniesWorkflowV2ParseHelperEnum = {
    FindCompaniesV2: 'find_companies_v2'
} as const;

export type FindCompaniesWorkflowV2ParseHelperEnum = typeof FindCompaniesWorkflowV2ParseHelperEnum[keyof typeof FindCompaniesWorkflowV2ParseHelperEnum];

/**
 * 
 * @export
 * @interface FrontendAllocationAnswer
 */
export interface FrontendAllocationAnswer {
    /**
     * 
     * @type {string}
     * @memberof FrontendAllocationAnswer
     */
    'explanation'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontendAllocationAnswer
     */
    'answer'?: string;
    /**
     * 
     * @type {number}
     * @memberof FrontendAllocationAnswer
     */
    'choice_number'?: number;
    /**
     * 
     * @type {number}
     * @memberof FrontendAllocationAnswer
     */
    'confidence'?: number;
    /**
     * 
     * @type {Array<Source>}
     * @memberof FrontendAllocationAnswer
     */
    'sources': Array<Source>;
    /**
     * 
     * @type {number}
     * @memberof FrontendAllocationAnswer
     */
    'fit_level'?: number;
}
/**
 * 
 * @export
 * @interface FrontendCompany
 */
export interface FrontendCompany {
    /**
     * 
     * @type {string}
     * @memberof FrontendCompany
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FrontendCompany
     */
    'domain': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FrontendCompany
     */
    'alternate_domains': Array<string>;
    /**
     * 
     * @type {CompanySummary}
     * @memberof FrontendCompany
     */
    'summary': CompanySummary;
    /**
     * 
     * @type {CompanyType}
     * @memberof FrontendCompany
     */
    'company_type': CompanyType;
    /**
     * 
     * @type {number}
     * @memberof FrontendCompany
     */
    'hype_rating': number;
    /**
     * 
     * @type {string}
     * @memberof FrontendCompany
     */
    'linkedin_aid': string;
    /**
     * 
     * @type {number}
     * @memberof FrontendCompany
     */
    'linkedin_followers'?: number;
    /**
     * 
     * @type {number}
     * @memberof FrontendCompany
     */
    'linkedin_followers_90day_growth'?: number;
    /**
     * 
     * @type {number}
     * @memberof FrontendCompany
     */
    'employee_min'?: number;
    /**
     * 
     * @type {number}
     * @memberof FrontendCompany
     */
    'employee_max'?: number;
    /**
     * 
     * @type {number}
     * @memberof FrontendCompany
     */
    'employee_guess'?: number;
    /**
     * 
     * @type {number}
     * @memberof FrontendCompany
     */
    'employee_count_90day_growth'?: number;
    /**
     * 
     * @type {number}
     * @memberof FrontendCompany
     */
    'year_founded'?: number;
    /**
     * 
     * @type {string}
     * @memberof FrontendCompany
     */
    'stock_exchange'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontendCompany
     */
    'stock_ticker'?: string;
    /**
     * 
     * @type {Array<Fundraise>}
     * @memberof FrontendCompany
     */
    'fundraises'?: Array<Fundraise>;
    /**
     * 
     * @type {Array<BusinessManagementItem>}
     * @memberof FrontendCompany
     */
    'management'?: Array<BusinessManagementItem>;
    /**
     * 
     * @type {Array<PressItem>}
     * @memberof FrontendCompany
     */
    'recent_press'?: Array<PressItem>;
    /**
     * 
     * @type {BusinessCommentary}
     * @memberof FrontendCompany
     */
    'commentary'?: BusinessCommentary;
    /**
     * 
     * @type {Array<FrontendCompanyStub>}
     * @memberof FrontendCompany
     */
    'similar_companies'?: Array<FrontendCompanyStub>;
    /**
     * 
     * @type {OfferingProductSynthesis}
     * @memberof FrontendCompany
     */
    'product_synthesis'?: OfferingProductSynthesis;
    /**
     * 
     * @type {string}
     * @memberof FrontendCompany
     */
    'logo_url'?: string;
    /**
     * 
     * @type {{ [key: string]: TextWithSources; }}
     * @memberof FrontendCompany
     */
    'custom_research'?: { [key: string]: TextWithSources; };
    /**
     * 
     * @type {Array<string>}
     * @memberof FrontendCompany
     */
    'list_of_investors'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof FrontendCompany
     */
    'hq_str'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontendCompany
     */
    'hq_city'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontendCompany
     */
    'hq_region'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontendCompany
     */
    'hq_country'?: string;
    /**
     * 
     * @type {number}
     * @memberof FrontendCompany
     */
    'last_known_fundraise_timestamp'?: number;
    /**
     * 
     * @type {Fundraise}
     * @memberof FrontendCompany
     */
    'total_known_fundraising'?: Fundraise;
    /**
     * 
     * @type {WebTrafficSnapshot}
     * @memberof FrontendCompany
     */
    'web_traffic'?: WebTrafficSnapshot;
}


/**
 * 
 * @export
 * @interface FrontendCompanyStub
 */
export interface FrontendCompanyStub {
    /**
     * 
     * @type {string}
     * @memberof FrontendCompanyStub
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof FrontendCompanyStub
     */
    'domain': string;
    /**
     * 
     * @type {string}
     * @memberof FrontendCompanyStub
     */
    'linkedin_aid': string;
    /**
     * 
     * @type {string}
     * @memberof FrontendCompanyStub
     */
    'logo_url'?: string;
}
/**
 * 
 * @export
 * @interface FrontendDocument
 */
export interface FrontendDocument {
    /**
     * 
     * @type {string}
     * @memberof FrontendDocument
     */
    'url': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof FrontendDocument
     */
    'company_domains'?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: number; }}
     * @memberof FrontendDocument
     */
    'company_prominences'?: { [key: string]: number; };
    /**
     * 
     * @type {string}
     * @memberof FrontendDocument
     */
    'source_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontendDocument
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontendDocument
     */
    'summary'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontendDocument
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontendDocument
     */
    'date_published'?: string;
    /**
     * 
     * @type {DocumentType}
     * @memberof FrontendDocument
     */
    'page_type'?: DocumentType;
    /**
     * 
     * @type {number}
     * @memberof FrontendDocument
     */
    'credibility_level'?: number;
    /**
     * 
     * @type {number}
     * @memberof FrontendDocument
     */
    'bias_level'?: number;
    /**
     * 
     * @type {number}
     * @memberof FrontendDocument
     */
    'information_level'?: number;
}


/**
 * 
 * @export
 * @interface FrontendPerson
 */
export interface FrontendPerson {
    /**
     * 
     * @type {string}
     * @memberof FrontendPerson
     */
    'first_name': string;
    /**
     * 
     * @type {string}
     * @memberof FrontendPerson
     */
    'last_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontendPerson
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontendPerson
     */
    'email'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontendPerson
     */
    'linkedin_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontendPerson
     */
    'image_url'?: string;
    /**
     * 
     * @type {FrontendCompanyStub}
     * @memberof FrontendPerson
     */
    'company_stub': FrontendCompanyStub;
}
/**
 * 
 * @export
 * @interface FrontendTaxonomy
 */
export interface FrontendTaxonomy {
    /**
     * 
     * @type {string}
     * @memberof FrontendTaxonomy
     */
    'taxonomy_id': string;
    /**
     * 
     * @type {FrontendTaxonomyNode}
     * @memberof FrontendTaxonomy
     */
    'taxonomy_node': FrontendTaxonomyNode;
    /**
     * 
     * @type {Array<FrontendTaxonomyEntry>}
     * @memberof FrontendTaxonomy
     */
    'entries': Array<FrontendTaxonomyEntry>;
}
/**
 * 
 * @export
 * @interface FrontendTaxonomyEntry
 */
export interface FrontendTaxonomyEntry {
    /**
     * 
     * @type {FrontendCompany}
     * @memberof FrontendTaxonomyEntry
     */
    'company': FrontendCompany;
    /**
     * 
     * @type {Array<FrontendAllocationAnswer>}
     * @memberof FrontendTaxonomyEntry
     */
    'traversal_path': Array<FrontendAllocationAnswer>;
    /**
     * 
     * @type {CompanyScoringResult}
     * @memberof FrontendTaxonomyEntry
     */
    'scoring_result'?: CompanyScoringResult;
}
/**
 * 
 * @export
 * @interface FrontendTaxonomyNode
 */
export interface FrontendTaxonomyNode {
    /**
     * 
     * @type {string}
     * @memberof FrontendTaxonomyNode
     */
    'node_id': string;
    /**
     * 
     * @type {string}
     * @memberof FrontendTaxonomyNode
     */
    'node_title'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontendTaxonomyNode
     */
    'node_description'?: string;
    /**
     * 
     * @type {string}
     * @memberof FrontendTaxonomyNode
     */
    'subnode_question'?: string;
    /**
     * 
     * @type {Array<FrontendTaxonomyNode>}
     * @memberof FrontendTaxonomyNode
     */
    'subnodes': Array<FrontendTaxonomyNode>;
    /**
     * 
     * @type {number}
     * @memberof FrontendTaxonomyNode
     */
    'target_volume': number;
    /**
     * 
     * @type {boolean}
     * @memberof FrontendTaxonomyNode
     */
    'enable_multi_select': boolean;
    /**
     * 
     * @type {boolean}
     * @memberof FrontendTaxonomyNode
     */
    'allow_none_of_the_above': boolean;
    /**
     * 
     * @type {CategoryScoringResult}
     * @memberof FrontendTaxonomyNode
     */
    'category_scoring'?: CategoryScoringResult;
    /**
     * 
     * @type {number}
     * @memberof FrontendTaxonomyNode
     */
    'number_of_companies': number;
}
/**
 * 
 * @export
 * @interface Fundraise
 */
export interface Fundraise {
    /**
     * 
     * @type {number}
     * @memberof Fundraise
     */
    'amount'?: number;
    /**
     * 
     * @type {string}
     * @memberof Fundraise
     */
    'currency'?: string;
    /**
     * 
     * @type {string}
     * @memberof Fundraise
     */
    'round_name'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof Fundraise
     */
    'investors'?: Array<string>;
    /**
     * 
     * @type {string}
     * @memberof Fundraise
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface GenericWorkflow
 */
export interface GenericWorkflow {
    /**
     * 
     * @type {string}
     * @memberof GenericWorkflow
     */
    'workflow_id': string;
    /**
     * 
     * @type {WorkflowType}
     * @memberof GenericWorkflow
     */
    'workflow_type': WorkflowType;
    /**
     * 
     * @type {string}
     * @memberof GenericWorkflow
     */
    'title': string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof GenericWorkflow
     */
    'status': TaskStatus;
    /**
     * 
     * @type {WorkflowFeedback}
     * @memberof GenericWorkflow
     */
    'feedback'?: WorkflowFeedback;
    /**
     * 
     * @type {WorkflowEffort}
     * @memberof GenericWorkflow
     */
    'effort'?: WorkflowEffort;
    /**
     * 
     * @type {number}
     * @memberof GenericWorkflow
     */
    'start_timestamp_seconds'?: number;
    /**
     * 
     * @type {number}
     * @memberof GenericWorkflow
     */
    'created_at'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GenericWorkflow
     */
    'viewed'?: boolean;
    /**
     * 
     * @type {GenericWorkflowWorkflow}
     * @memberof GenericWorkflow
     */
    'workflow': GenericWorkflowWorkflow;
    /**
     * 
     * @type {string}
     * @memberof GenericWorkflow
     */
    'error_message'?: string;
}


/**
 * @type GenericWorkflowWorkflow
 * @export
 */
export type GenericWorkflowWorkflow = CompFinderWorkflowV5 | CompLandscapeV7Workflow | CompanyCustomResearchWorkflowV1 | FindCompaniesWorkflowV1 | FindCompaniesWorkflowV2 | MarketMapWorkflow | OfferingDeepdiveWorkflowV1 | PressSearchWorkflowV1 | PublicCompsWorkflowV1 | SourceCompaniesWorkflow | TearSheetWorkflowV1;

/**
 * 
 * @export
 * @interface HardFilter
 */
export interface HardFilter {
    /**
     * 
     * @type {HardMetric}
     * @memberof HardFilter
     */
    'hard_metric': HardMetric;
    /**
     * 
     * @type {number}
     * @memberof HardFilter
     */
    'min'?: number;
    /**
     * 
     * @type {number}
     * @memberof HardFilter
     */
    'max'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof HardFilter
     */
    'nones_allowed': boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const HardMetric = {
    FoundedYear: 'founded_year',
    LastFundraiseDate: 'last_fundraise_date',
    EmployeeCount: 'employee_count',
    GrowthScore: 'growth_score',
    HypeScore: 'hype_score',
    ProductScore: 'product_score',
    TechScore: 'tech_score',
    Ebitda: 'ebitda',
    MarketCap: 'market_cap',
    Revenue: 'revenue',
    RevenueGrowth: 'revenue_growth',
    EnterpriseValue: 'enterprise_value',
    GrossMargin: 'gross_margin',
    Earnings: 'earnings',
    FreeCashFlow: 'free_cash_flow'
} as const;

export type HardMetric = typeof HardMetric[keyof typeof HardMetric];


/**
 * 
 * @export
 * @interface ImportableSearchScope
 */
export interface ImportableSearchScope {
    /**
     * 
     * @type {Array<HardFilter>}
     * @memberof ImportableSearchScope
     */
    'filters'?: Array<HardFilter>;
    /**
     * 
     * @type {Array<CompanyType>}
     * @memberof ImportableSearchScope
     */
    'company_type_filter'?: Array<CompanyType>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ImportableSearchScope
     */
    'hq_country_filter'?: Array<string>;
    /**
     * 
     * @type {Array<ScoringCriterion>}
     * @memberof ImportableSearchScope
     */
    'scoring_criteria'?: Array<ScoringCriterion>;
}
/**
 * 
 * @export
 * @interface IngestedCompany
 */
export interface IngestedCompany {
    /**
     * 
     * @type {FrontendCompany}
     * @memberof IngestedCompany
     */
    'company': FrontendCompany;
    /**
     * 
     * @type {boolean}
     * @memberof IngestedCompany
     */
    'in_scope'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof IngestedCompany
     */
    'out_of_scope_reason'?: string;
    /**
     * 
     * @type {string}
     * @memberof IngestedCompany
     */
    'added_at'?: string;
    /**
     * 
     * @type {string}
     * @memberof IngestedCompany
     */
    'added_from'?: string;
    /**
     * 
     * @type {number}
     * @memberof IngestedCompany
     */
    'evaluation_score'?: number;
    /**
     * 
     * @type {string}
     * @memberof IngestedCompany
     */
    'evaluation_score_reason'?: string;
}
/**
 * 
 * @export
 * @interface LinkedinJob
 */
export interface LinkedinJob {
    /**
     * 
     * @type {string}
     * @memberof LinkedinJob
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof LinkedinJob
     */
    'location'?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkedinJob
     */
    'type'?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkedinJob
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof LinkedinJob
     */
    'date_posted'?: string;
}
/**
 * 
 * @export
 * @interface MarketMap
 */
export interface MarketMap {
    /**
     * 
     * @type {string}
     * @memberof MarketMap
     */
    'title'?: string;
    /**
     * 
     * @type {Array<MarketMapCategory>}
     * @memberof MarketMap
     */
    'categories': Array<MarketMapCategory>;
}
/**
 * 
 * @export
 * @interface MarketMapCategory
 */
export interface MarketMapCategory {
    /**
     * 
     * @type {string}
     * @memberof MarketMapCategory
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MarketMapCategory
     */
    'description'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof MarketMapCategory
     */
    'businesses': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MarketMapCategory
     */
    'bespoke_research_topics': Array<string>;
    /**
     * 
     * @type {Array<MarketMapSubcategory>}
     * @memberof MarketMapCategory
     */
    'subcategories': Array<MarketMapSubcategory>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MarketMapCategory
     */
    'exemplars': Array<string>;
    /**
     * 
     * @type {FindCompaniesInstructions}
     * @memberof MarketMapCategory
     */
    'find_companies_instructions'?: FindCompaniesInstructions;
}
/**
 * 
 * @export
 * @interface MarketMapInputs
 */
export interface MarketMapInputs {
    /**
     * 
     * @type {MarketMap}
     * @memberof MarketMapInputs
     */
    'market_map': MarketMap;
}
/**
 * 
 * @export
 * @interface MarketMapOutputs
 */
export interface MarketMapOutputs {
    /**
     * 
     * @type {MarketMap}
     * @memberof MarketMapOutputs
     */
    'market_map': MarketMap;
    /**
     * 
     * @type {Array<CompLandscapeCompany>}
     * @memberof MarketMapOutputs
     */
    'companies': Array<CompLandscapeCompany>;
}
/**
 * 
 * @export
 * @interface MarketMapSubcategory
 */
export interface MarketMapSubcategory {
    /**
     * 
     * @type {string}
     * @memberof MarketMapSubcategory
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof MarketMapSubcategory
     */
    'description'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MarketMapSubcategory
     */
    'businesses': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MarketMapSubcategory
     */
    'bespoke_research_topics': Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof MarketMapSubcategory
     */
    'exemplars': Array<string>;
    /**
     * 
     * @type {FindCompaniesInstructions}
     * @memberof MarketMapSubcategory
     */
    'find_companies_instructions'?: FindCompaniesInstructions;
}
/**
 * 
 * @export
 * @interface MarketMapWorkflow
 */
export interface MarketMapWorkflow {
    /**
     * 
     * @type {MarketMapInputs}
     * @memberof MarketMapWorkflow
     */
    'inputs': MarketMapInputs;
    /**
     * 
     * @type {MarketMapOutputs}
     * @memberof MarketMapWorkflow
     */
    'outputs'?: MarketMapOutputs;
    /**
     * 
     * @type {string}
     * @memberof MarketMapWorkflow
     */
    'parse_helper': MarketMapWorkflowParseHelperEnum;
}

export const MarketMapWorkflowParseHelperEnum = {
    MarketMapV2: 'market_map_v2'
} as const;

export type MarketMapWorkflowParseHelperEnum = typeof MarketMapWorkflowParseHelperEnum[keyof typeof MarketMapWorkflowParseHelperEnum];

/**
 * 
 * @export
 * @interface Matrix
 */
export interface Matrix {
    /**
     * 
     * @type {Array<MatrixHeader>}
     * @memberof Matrix
     */
    'rowHeaders': Array<MatrixHeader>;
    /**
     * 
     * @type {Array<MatrixHeader>}
     * @memberof Matrix
     */
    'colHeaders': Array<MatrixHeader>;
    /**
     * 
     * @type {Array<MatrixDataRow>}
     * @memberof Matrix
     */
    'data': Array<MatrixDataRow>;
}
/**
 * 
 * @export
 * @interface MatrixDataRow
 */
export interface MatrixDataRow {
    /**
     * 
     * @type {Array<DataElement>}
     * @memberof MatrixDataRow
     */
    'elements': Array<DataElement>;
}
/**
 * 
 * @export
 * @interface MatrixHeader
 */
export interface MatrixHeader {
    /**
     * 
     * @type {string}
     * @memberof MatrixHeader
     */
    'shortText': string;
    /**
     * 
     * @type {string}
     * @memberof MatrixHeader
     */
    'longText'?: string;
    /**
     * 
     * @type {string}
     * @memberof MatrixHeader
     */
    'outputFormat'?: string;
}
/**
 * 
 * @export
 * @interface MaturityLevel
 */
export interface MaturityLevel {
    /**
     * 
     * @type {number}
     * @memberof MaturityLevel
     */
    'value': number;
    /**
     * 
     * @type {Array<Source>}
     * @memberof MaturityLevel
     */
    'sources': Array<Source>;
}
/**
 * 
 * @export
 * @interface MetricWithSources
 */
export interface MetricWithSources {
    /**
     * 
     * @type {number}
     * @memberof MetricWithSources
     */
    'value': number;
    /**
     * 
     * @type {Array<Source>}
     * @memberof MetricWithSources
     */
    'sources': Array<Source>;
}
/**
 * 
 * @export
 * @interface MultiReportedMetric
 */
export interface MultiReportedMetric {
    /**
     * 
     * @type {number}
     * @memberof MultiReportedMetric
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof MultiReportedMetric
     */
    'period': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof MultiReportedMetric
     */
    'filingLinks'?: Array<string>;
}
/**
 * 
 * @export
 * @interface OfferingAspectAnalysis
 */
export interface OfferingAspectAnalysis {
    /**
     * 
     * @type {string}
     * @memberof OfferingAspectAnalysis
     */
    'topic': string;
    /**
     * 
     * @type {TextWithSources}
     * @memberof OfferingAspectAnalysis
     */
    'internal_perspective'?: TextWithSources;
    /**
     * 
     * @type {TextWithSources}
     * @memberof OfferingAspectAnalysis
     */
    'external_perspective'?: TextWithSources;
    /**
     * 
     * @type {Array<TextWithSource>}
     * @memberof OfferingAspectAnalysis
     */
    'reviews'?: Array<TextWithSource>;
}
/**
 * 
 * @export
 * @interface OfferingDeepdiveInputsV1
 */
export interface OfferingDeepdiveInputsV1 {
    /**
     * 
     * @type {string}
     * @memberof OfferingDeepdiveInputsV1
     */
    'domain': string;
}
/**
 * 
 * @export
 * @interface OfferingDeepdiveOutputsV1
 */
export interface OfferingDeepdiveOutputsV1 {
    /**
     * 
     * @type {OfferingProductSynthesis}
     * @memberof OfferingDeepdiveOutputsV1
     */
    'product_synthesis': OfferingProductSynthesis;
    /**
     * 
     * @type {Array<OfferingAspectAnalysis>}
     * @memberof OfferingDeepdiveOutputsV1
     */
    'aspect_analyses': Array<OfferingAspectAnalysis>;
}
/**
 * 
 * @export
 * @interface OfferingDeepdiveWorkflowV1
 */
export interface OfferingDeepdiveWorkflowV1 {
    /**
     * 
     * @type {OfferingDeepdiveInputsV1}
     * @memberof OfferingDeepdiveWorkflowV1
     */
    'inputs': OfferingDeepdiveInputsV1;
    /**
     * 
     * @type {OfferingDeepdiveOutputsV1}
     * @memberof OfferingDeepdiveWorkflowV1
     */
    'outputs'?: OfferingDeepdiveOutputsV1;
    /**
     * 
     * @type {string}
     * @memberof OfferingDeepdiveWorkflowV1
     */
    'parse_helper': OfferingDeepdiveWorkflowV1ParseHelperEnum;
}

export const OfferingDeepdiveWorkflowV1ParseHelperEnum = {
    OfferingDeepdive: 'offering_deepdive'
} as const;

export type OfferingDeepdiveWorkflowV1ParseHelperEnum = typeof OfferingDeepdiveWorkflowV1ParseHelperEnum[keyof typeof OfferingDeepdiveWorkflowV1ParseHelperEnum];

/**
 * 
 * @export
 * @interface OfferingProductSynthesis
 */
export interface OfferingProductSynthesis {
    /**
     * 
     * @type {string}
     * @memberof OfferingProductSynthesis
     */
    'one_liner': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OfferingProductSynthesis
     */
    'key_facts': Array<string>;
    /**
     * 
     * @type {Array<Source>}
     * @memberof OfferingProductSynthesis
     */
    'sources': Array<Source>;
}
/**
 * 
 * @export
 * @interface OfferingSummary
 */
export interface OfferingSummary {
    /**
     * 
     * @type {string}
     * @memberof OfferingSummary
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof OfferingSummary
     */
    'use_case_value_prop': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof OfferingSummary
     */
    'differentiators': Array<string>;
}
/**
 * 
 * @export
 * @interface OfferingsDive
 */
export interface OfferingsDive {
    /**
     * 
     * @type {string}
     * @memberof OfferingsDive
     */
    'company_domain': string;
    /**
     * 
     * @type {Array<OfferingSummary>}
     * @memberof OfferingsDive
     */
    'offerings': Array<OfferingSummary>;
    /**
     * 
     * @type {Array<TextWithSource>}
     * @memberof OfferingsDive
     */
    'review_quotes': Array<TextWithSource>;
    /**
     * 
     * @type {ReviewSet}
     * @memberof OfferingsDive
     */
    'g2_review_set'?: ReviewSet;
    /**
     * 
     * @type {ReviewSet}
     * @memberof OfferingsDive
     */
    'trustpilot_review_set'?: ReviewSet;
    /**
     * 
     * @type {Array<Source>}
     * @memberof OfferingsDive
     */
    'sources': Array<Source>;
}
/**
 * 
 * @export
 * @interface Partner
 */
export interface Partner {
    /**
     * 
     * @type {string}
     * @memberof Partner
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof Partner
     */
    'relationship': string;
}
/**
 * 
 * @export
 * @interface PeopleList
 */
export interface PeopleList {
    /**
     * 
     * @type {string}
     * @memberof PeopleList
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof PeopleList
     */
    'title': string;
    /**
     * 
     * @type {Array<FrontendPerson>}
     * @memberof PeopleList
     */
    'people': Array<FrontendPerson>;
}
/**
 * 
 * @export
 * @interface PitchbookEnrichment
 */
export interface PitchbookEnrichment {
    /**
     * 
     * @type {string}
     * @memberof PitchbookEnrichment
     */
    'pitchbook_id': string;
    /**
     * 
     * @type {string}
     * @memberof PitchbookEnrichment
     */
    'pitchbook_url': string;
    /**
     * 
     * @type {Array<Fundraise>}
     * @memberof PitchbookEnrichment
     */
    'fundraises': Array<Fundraise>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PitchbookEnrichment
     */
    'list_of_investors'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof PitchbookEnrichment
     */
    'last_known_fundraise_timestamp'?: number;
    /**
     * 
     * @type {Fundraise}
     * @memberof PitchbookEnrichment
     */
    'total_known_fundraising'?: Fundraise;
    /**
     * 
     * @type {number}
     * @memberof PitchbookEnrichment
     */
    'pull_timestamp': number;
    /**
     * 
     * @type {number}
     * @memberof PitchbookEnrichment
     */
    'valuation_stepup'?: number;
    /**
     * 
     * @type {number}
     * @memberof PitchbookEnrichment
     */
    'post_money_valuation'?: number;
    /**
     * 
     * @type {string}
     * @memberof PitchbookEnrichment
     */
    'predicted_ipo_date'?: string;
    /**
     * 
     * @type {number}
     * @memberof PitchbookEnrichment
     */
    'financials_revenue'?: number;
    /**
     * 
     * @type {number}
     * @memberof PitchbookEnrichment
     */
    'financials_period'?: number;
}
/**
 * 
 * @export
 * @interface Playbook
 */
export interface Playbook {
    /**
     * 
     * @type {string}
     * @memberof Playbook
     */
    'task_type': string;
    /**
     * 
     * @type {string}
     * @memberof Playbook
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Playbook
     */
    'example_input'?: string;
    /**
     * 
     * @type {string}
     * @memberof Playbook
     */
    'example_explanation'?: string;
    /**
     * 
     * @type {string}
     * @memberof Playbook
     */
    'example_output': string;
}
/**
 * 
 * @export
 * @interface PressItem
 */
export interface PressItem {
    /**
     * 
     * @type {string}
     * @memberof PressItem
     */
    'takeaway': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof PressItem
     */
    'quotes': Array<string>;
    /**
     * 
     * @type {boolean}
     * @memberof PressItem
     */
    'featured': boolean;
    /**
     * 
     * @type {Source}
     * @memberof PressItem
     */
    'source': Source;
}
/**
 * 
 * @export
 * @interface PressSearchInputV1
 */
export interface PressSearchInputV1 {
    /**
     * 
     * @type {string}
     * @memberof PressSearchInputV1
     */
    'company_domain'?: string;
    /**
     * 
     * @type {string}
     * @memberof PressSearchInputV1
     */
    'topic'?: string;
}
/**
 * 
 * @export
 * @interface PressSearchOutputV1
 */
export interface PressSearchOutputV1 {
    /**
     * 
     * @type {Array<PressItem>}
     * @memberof PressSearchOutputV1
     */
    'press_items': Array<PressItem>;
    /**
     * 
     * @type {Array<string>}
     * @memberof PressSearchOutputV1
     */
    'summary': Array<string>;
}
/**
 * 
 * @export
 * @interface PressSearchWorkflowV1
 */
export interface PressSearchWorkflowV1 {
    /**
     * 
     * @type {PressSearchInputV1}
     * @memberof PressSearchWorkflowV1
     */
    'inputs': PressSearchInputV1;
    /**
     * 
     * @type {PressSearchOutputV1}
     * @memberof PressSearchWorkflowV1
     */
    'outputs'?: PressSearchOutputV1;
    /**
     * 
     * @type {string}
     * @memberof PressSearchWorkflowV1
     */
    'parse_helper': PressSearchWorkflowV1ParseHelperEnum;
}

export const PressSearchWorkflowV1ParseHelperEnum = {
    PressSearch: 'press_search'
} as const;

export type PressSearchWorkflowV1ParseHelperEnum = typeof PressSearchWorkflowV1ParseHelperEnum[keyof typeof PressSearchWorkflowV1ParseHelperEnum];

/**
 * 
 * @export
 * @interface PublicCompsBusiness
 */
export interface PublicCompsBusiness {
    /**
     * 
     * @type {Business}
     * @memberof PublicCompsBusiness
     */
    'business': Business;
    /**
     * 
     * @type {string}
     * @memberof PublicCompsBusiness
     */
    'similarity_justification'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicCompsBusiness
     */
    'benchmark_variance_justification'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PublicCompsBusiness
     */
    'included_in_benchmark'?: boolean;
}
/**
 * 
 * @export
 * @interface PublicCompsInputsV1
 */
export interface PublicCompsInputsV1 {
    /**
     * 
     * @type {string}
     * @memberof PublicCompsInputsV1
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicCompsInputsV1
     */
    'domain': string;
    /**
     * 
     * @type {string}
     * @memberof PublicCompsInputsV1
     */
    'guidance'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicCompsInputsV1
     */
    'user_email'?: string;
    /**
     * 
     * @type {string}
     * @memberof PublicCompsInputsV1
     */
    'target_business_id'?: string;
    /**
     * 
     * @type {Array<HardFilter>}
     * @memberof PublicCompsInputsV1
     */
    'filters'?: Array<HardFilter>;
}
/**
 * 
 * @export
 * @interface PublicCompsOutputsV1
 */
export interface PublicCompsOutputsV1 {
    /**
     * 
     * @type {Array<PublicCompsBusiness>}
     * @memberof PublicCompsOutputsV1
     */
    'businesses': Array<PublicCompsBusiness>;
    /**
     * 
     * @type {CompFinderOutputsV5Effort}
     * @memberof PublicCompsOutputsV1
     */
    'effort'?: CompFinderOutputsV5Effort;
}
/**
 * 
 * @export
 * @interface PublicCompsWorkflowV1
 */
export interface PublicCompsWorkflowV1 {
    /**
     * 
     * @type {string}
     * @memberof PublicCompsWorkflowV1
     */
    'workflow_id'?: string;
    /**
     * 
     * @type {PublicCompsInputsV1}
     * @memberof PublicCompsWorkflowV1
     */
    'inputs': PublicCompsInputsV1;
    /**
     * 
     * @type {PublicCompsOutputsV1}
     * @memberof PublicCompsWorkflowV1
     */
    'outputs'?: PublicCompsOutputsV1;
    /**
     * 
     * @type {string}
     * @memberof PublicCompsWorkflowV1
     */
    'parse_helper': PublicCompsWorkflowV1ParseHelperEnum;
}

export const PublicCompsWorkflowV1ParseHelperEnum = {
    PublicComps: 'public_comps'
} as const;

export type PublicCompsWorkflowV1ParseHelperEnum = typeof PublicCompsWorkflowV1ParseHelperEnum[keyof typeof PublicCompsWorkflowV1ParseHelperEnum];

/**
 * 
 * @export
 * @interface RecentDevelopment
 */
export interface RecentDevelopment {
    /**
     * 
     * @type {string}
     * @memberof RecentDevelopment
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof RecentDevelopment
     */
    'description'?: string;
    /**
     * 
     * @type {string}
     * @memberof RecentDevelopment
     */
    'date': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof RecentDevelopment
     */
    'quotes': Array<string>;
    /**
     * 
     * @type {Array<Source>}
     * @memberof RecentDevelopment
     */
    'sources': Array<Source>;
}
/**
 * 
 * @export
 * @interface RecentDevelopments
 */
export interface RecentDevelopments {
    /**
     * 
     * @type {string}
     * @memberof RecentDevelopments
     */
    'company_domain': string;
    /**
     * 
     * @type {Array<RecentDevelopment>}
     * @memberof RecentDevelopments
     */
    'developments': Array<RecentDevelopment>;
}
/**
 * 
 * @export
 * @interface RelatedCompanies
 */
export interface RelatedCompanies {
    /**
     * 
     * @type {Array<FrontendCompany>}
     * @memberof RelatedCompanies
     */
    'similar'?: Array<FrontendCompany>;
}
/**
 * 
 * @export
 * @interface ReportedMetric
 */
export interface ReportedMetric {
    /**
     * 
     * @type {number}
     * @memberof ReportedMetric
     */
    'value': number;
    /**
     * 
     * @type {string}
     * @memberof ReportedMetric
     */
    'period': string;
    /**
     * 
     * @type {string}
     * @memberof ReportedMetric
     */
    'filingLink'?: string;
}
/**
 * 
 * @export
 * @interface ReviewSet
 */
export interface ReviewSet {
    /**
     * 
     * @type {number}
     * @memberof ReviewSet
     */
    'aggregate_rating'?: number;
    /**
     * 
     * @type {number}
     * @memberof ReviewSet
     */
    'num_reviews'?: number;
    /**
     * 
     * @type {Array<TextWithSource>}
     * @memberof ReviewSet
     */
    'reviews': Array<TextWithSource>;
    /**
     * 
     * @type {string}
     * @memberof ReviewSet
     */
    'company_profile_link'?: string;
}
/**
 * 
 * @export
 * @interface Rubric
 */
export interface Rubric {
    /**
     * 
     * @type {string}
     * @memberof Rubric
     */
    'rubric_id': string;
    /**
     * 
     * @type {Array<RubricDimension>}
     * @memberof Rubric
     */
    'dimensions': Array<RubricDimension>;
}
/**
 * 
 * @export
 * @interface RubricDimension
 */
export interface RubricDimension {
    /**
     * 
     * @type {RubricDimensionType}
     * @memberof RubricDimension
     */
    'type': RubricDimensionType;
    /**
     * 
     * @type {DimensionGuidance}
     * @memberof RubricDimension
     */
    'guidance': DimensionGuidance;
    /**
     * 
     * @type {number}
     * @memberof RubricDimension
     */
    'weight': number;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const RubricDimensionType = {
    Presence: 'PRESENCE',
    ManagementTeam: 'MANAGEMENT_TEAM',
    InvestorQuality: 'INVESTOR_QUALITY',
    Tam: 'TAM',
    Differentiation: 'DIFFERENTIATION',
    FirmValueAdd: 'FIRM_VALUE_ADD',
    Competition: 'COMPETITION',
    Growth: 'GROWTH',
    MarketSize: 'MARKET_SIZE',
    MarketGrowth: 'MARKET_GROWTH',
    FragmentationCommodification: 'FRAGMENTATION_COMMODIFICATION',
    GreenfieldVsReplacement: 'GREENFIELD_VS_REPLACEMENT',
    RiskOfNewEntrants: 'RISK_OF_NEW_ENTRANTS',
    Seasonality: 'SEASONALITY',
    Cyclicality: 'CYCLICALITY',
    SalesCycles: 'SALES_CYCLES',
    DistributionChannels: 'DISTRIBUTION_CHANNELS',
    TechnologyTailwinds: 'TECHNOLOGY_TAILWINDS',
    SwitchingCosts: 'SWITCHING_COSTS',
    NetworkEffects: 'NETWORK_EFFECTS',
    CustomerSentiment: 'CUSTOMER_SENTIMENT',
    BlueChipCustomers: 'BLUE_CHIP_CUSTOMERS',
    WebPresence: 'WEB_PRESENCE',
    DiversificationOfCustomerBase: 'DIVERSIFICATION_OF_CUSTOMER_BASE',
    ChannelPartners: 'CHANNEL_PARTNERS',
    TechnicalComplexity: 'TECHNICAL_COMPLEXITY',
    CorneredResources: 'CORNERED_RESOURCES',
    LevelOfProductDifferentiation: 'LEVEL_OF_PRODUCT_DIFFERENTIATION',
    UniqueAdvantages: 'UNIQUE_ADVANTAGES',
    TrackRecord: 'TRACK_RECORD',
    RecentProductLaunches: 'RECENT_PRODUCT_LAUNCHES',
    RecentCommercialAnnouncements: 'RECENT_COMMERCIAL_ANNOUNCEMENTS',
    EmployeeGrowth: 'EMPLOYEE_GROWTH'
} as const;

export type RubricDimensionType = typeof RubricDimensionType[keyof typeof RubricDimensionType];


/**
 * 
 * @export
 * @enum {string}
 */

export const SalesMotion = {
    TopDown: 'TopDown',
    BottomUp: 'BottomUp',
    ChannelSales: 'ChannelSales',
    DistributorRetailer: 'DistributorRetailer'
} as const;

export type SalesMotion = typeof SalesMotion[keyof typeof SalesMotion];


/**
 * 
 * @export
 * @interface ScoredCompaniesRequest
 */
export interface ScoredCompaniesRequest {
    /**
     * 
     * @type {string}
     * @memberof ScoredCompaniesRequest
     */
    'individual_filter'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScoredCompaniesRequest
     */
    'pod_filter'?: string;
    /**
     * 
     * @type {Rubric}
     * @memberof ScoredCompaniesRequest
     */
    'rubric': Rubric;
}
/**
 * 
 * @export
 * @interface ScoredDealcloudCompany
 */
export interface ScoredDealcloudCompany {
    /**
     * 
     * @type {string}
     * @memberof ScoredDealcloudCompany
     */
    'company_domain': string;
    /**
     * 
     * @type {string}
     * @memberof ScoredDealcloudCompany
     */
    'individual'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScoredDealcloudCompany
     */
    'pod'?: string;
    /**
     * 
     * @type {Array<ScoredRubricDimension>}
     * @memberof ScoredDealcloudCompany
     */
    'scored_dimensions': Array<ScoredRubricDimension>;
}
/**
 * 
 * @export
 * @interface ScoredRubric
 */
export interface ScoredRubric {
    /**
     * 
     * @type {string}
     * @memberof ScoredRubric
     */
    'rubric_id': string;
    /**
     * 
     * @type {string}
     * @memberof ScoredRubric
     */
    'company_domain': string;
    /**
     * 
     * @type {Array<ScoredRubricDimension>}
     * @memberof ScoredRubric
     */
    'scored_dimensions': Array<ScoredRubricDimension>;
}
/**
 * 
 * @export
 * @interface ScoredRubricDimension
 */
export interface ScoredRubricDimension {
    /**
     * 
     * @type {RubricDimensionType}
     * @memberof ScoredRubricDimension
     */
    'type': RubricDimensionType;
    /**
     * 
     * @type {number}
     * @memberof ScoredRubricDimension
     */
    'score': number;
    /**
     * 
     * @type {string}
     * @memberof ScoredRubricDimension
     */
    'explanation': string;
}


/**
 * 
 * @export
 * @interface ScoringAnswer
 */
export interface ScoringAnswer {
    /**
     * 
     * @type {string}
     * @memberof ScoringAnswer
     */
    'question': string;
    /**
     * 
     * @type {TextWithSources}
     * @memberof ScoringAnswer
     */
    'answer'?: TextWithSources;
    /**
     * 
     * @type {number}
     * @memberof ScoringAnswer
     */
    'score'?: number;
}
/**
 * 
 * @export
 * @interface ScoringCriterion
 */
export interface ScoringCriterion {
    /**
     * 
     * @type {string}
     * @memberof ScoringCriterion
     */
    'question': string;
    /**
     * 
     * @type {string}
     * @memberof ScoringCriterion
     */
    'detail'?: string;
    /**
     * 
     * @type {string}
     * @memberof ScoringCriterion
     */
    'low_score_standard': string;
    /**
     * 
     * @type {string}
     * @memberof ScoringCriterion
     */
    'high_score_standard': string;
    /**
     * 
     * @type {number}
     * @memberof ScoringCriterion
     */
    'weight': number;
}
/**
 * 
 * @export
 * @interface ScoringTopicResult
 */
export interface ScoringTopicResult {
    /**
     * 
     * @type {string}
     * @memberof ScoringTopicResult
     */
    'topic'?: string;
    /**
     * 
     * @type {number}
     * @memberof ScoringTopicResult
     */
    'score': number;
    /**
     * 
     * @type {string}
     * @memberof ScoringTopicResult
     */
    'explanation': string;
}
/**
 * 
 * @export
 * @interface SearchStrategy
 */
export interface SearchStrategy {
    /**
     * 
     * @type {string}
     * @memberof SearchStrategy
     */
    'search_type': SearchStrategySearchTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof SearchStrategy
     */
    'site'?: string;
    /**
     * 
     * @type {string}
     * @memberof SearchStrategy
     */
    'search_phrase': string;
}

export const SearchStrategySearchTypeEnum = {
    Web: 'web',
    Xray: 'xray',
    Llm: 'llm'
} as const;

export type SearchStrategySearchTypeEnum = typeof SearchStrategySearchTypeEnum[keyof typeof SearchStrategySearchTypeEnum];

/**
 * 
 * @export
 * @interface Source
 */
export interface Source {
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'title': string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'gateway'?: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'link'?: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'snippet'?: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'source_name'?: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'image'?: string;
    /**
     * 
     * @type {string}
     * @memberof Source
     */
    'date'?: string;
}
/**
 * 
 * @export
 * @interface SourceCompaniesCompany
 */
export interface SourceCompaniesCompany {
    /**
     * 
     * @type {FrontendCompany}
     * @memberof SourceCompaniesCompany
     */
    'company': FrontendCompany;
    /**
     * 
     * @type {number}
     * @memberof SourceCompaniesCompany
     */
    'overall_score'?: number;
    /**
     * 
     * @type {Array<ScoringAnswer>}
     * @memberof SourceCompaniesCompany
     */
    'scoring_answers'?: Array<ScoringAnswer>;
}
/**
 * 
 * @export
 * @interface SourceCompaniesInputs
 */
export interface SourceCompaniesInputs {
    /**
     * 
     * @type {string}
     * @memberof SourceCompaniesInputs
     */
    'category_name': string;
    /**
     * 
     * @type {string}
     * @memberof SourceCompaniesInputs
     */
    'business_model'?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceCompaniesInputs
     */
    'main_offerings'?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceCompaniesInputs
     */
    'customer_segments'?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceCompaniesInputs
     */
    'geographies'?: string;
    /**
     * 
     * @type {string}
     * @memberof SourceCompaniesInputs
     */
    'technologies'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof SourceCompaniesInputs
     */
    'exemplar_company_domains'?: Array<string>;
    /**
     * 
     * @type {Array<HardFilter>}
     * @memberof SourceCompaniesInputs
     */
    'filters'?: Array<HardFilter>;
    /**
     * 
     * @type {Array<CompanyType>}
     * @memberof SourceCompaniesInputs
     */
    'company_type_filter'?: Array<CompanyType>;
    /**
     * 
     * @type {Array<string>}
     * @memberof SourceCompaniesInputs
     */
    'hq_country_filter'?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof SourceCompaniesInputs
     */
    'requested_number_of_companies'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof SourceCompaniesInputs
     */
    'exemplar_domains'?: Array<string>;
    /**
     * 
     * @type {Array<ScoringCriterion>}
     * @memberof SourceCompaniesInputs
     */
    'scoring_criteria'?: Array<ScoringCriterion>;
}
/**
 * 
 * @export
 * @interface SourceCompaniesOutputs
 */
export interface SourceCompaniesOutputs {
    /**
     * 
     * @type {Array<SourceCompaniesCompany>}
     * @memberof SourceCompaniesOutputs
     */
    'companies': Array<SourceCompaniesCompany>;
    /**
     * 
     * @type {Array<SourceCompaniesCompany>}
     * @memberof SourceCompaniesOutputs
     */
    'removed_companies'?: Array<SourceCompaniesCompany>;
}
/**
 * 
 * @export
 * @interface SourceCompaniesWorkflow
 */
export interface SourceCompaniesWorkflow {
    /**
     * 
     * @type {string}
     * @memberof SourceCompaniesWorkflow
     */
    'input_generation_state'?: SourceCompaniesWorkflowInputGenerationStateEnum;
    /**
     * 
     * @type {SourceCompaniesInputs}
     * @memberof SourceCompaniesWorkflow
     */
    'frontend_initial_inputs'?: SourceCompaniesInputs;
    /**
     * 
     * @type {string}
     * @memberof SourceCompaniesWorkflow
     */
    'error_explanation'?: string;
    /**
     * 
     * @type {SourceCompaniesInputs}
     * @memberof SourceCompaniesWorkflow
     */
    'inputs': SourceCompaniesInputs;
    /**
     * 
     * @type {SourceCompaniesOutputs}
     * @memberof SourceCompaniesWorkflow
     */
    'outputs'?: SourceCompaniesOutputs;
    /**
     * 
     * @type {string}
     * @memberof SourceCompaniesWorkflow
     */
    'parse_helper': SourceCompaniesWorkflowParseHelperEnum;
}

export const SourceCompaniesWorkflowInputGenerationStateEnum = {
    Initial: 'initial',
    Loading: 'loading',
    Final: 'final'
} as const;

export type SourceCompaniesWorkflowInputGenerationStateEnum = typeof SourceCompaniesWorkflowInputGenerationStateEnum[keyof typeof SourceCompaniesWorkflowInputGenerationStateEnum];
export const SourceCompaniesWorkflowParseHelperEnum = {
    SourceCompanies: 'source_companies'
} as const;

export type SourceCompaniesWorkflowParseHelperEnum = typeof SourceCompaniesWorkflowParseHelperEnum[keyof typeof SourceCompaniesWorkflowParseHelperEnum];

/**
 * 
 * @export
 * @interface TalkingPoint
 */
export interface TalkingPoint {
    /**
     * 
     * @type {string}
     * @memberof TalkingPoint
     */
    'observation': string;
    /**
     * 
     * @type {string}
     * @memberof TalkingPoint
     */
    'implied_goal': string;
    /**
     * 
     * @type {string}
     * @memberof TalkingPoint
     */
    'proof_point': string;
}
/**
 * 
 * @export
 * @interface TargetInfo
 */
export interface TargetInfo {
    /**
     * 
     * @type {string}
     * @memberof TargetInfo
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TargetInfo
     */
    'website'?: string;
    /**
     * 
     * @type {string}
     * @memberof TargetInfo
     */
    'description'?: string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const TaskStatus = {
    Created: 'created',
    Running: 'running',
    Completed: 'completed',
    InputModified: 'input_modified',
    OutputModified: 'output_modified',
    Incomplete: 'incomplete'
} as const;

export type TaskStatus = typeof TaskStatus[keyof typeof TaskStatus];


/**
 * 
 * @export
 * @interface TeamDive
 */
export interface TeamDive {
    /**
     * 
     * @type {string}
     * @memberof TeamDive
     */
    'company_domain': string;
    /**
     * 
     * @type {Array<TeamMember>}
     * @memberof TeamDive
     */
    'team_members': Array<TeamMember>;
    /**
     * 
     * @type {Array<LinkedinJob>}
     * @memberof TeamDive
     */
    'linkedin_jobs': Array<LinkedinJob>;
    /**
     * 
     * @type {Array<Source>}
     * @memberof TeamDive
     */
    'sources': Array<Source>;
}
/**
 * 
 * @export
 * @interface TeamMember
 */
export interface TeamMember {
    /**
     * 
     * @type {string}
     * @memberof TeamMember
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof TeamMember
     */
    'title'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamMember
     */
    'linkedin_url'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamMember
     */
    'background'?: string;
    /**
     * 
     * @type {string}
     * @memberof TeamMember
     */
    'location'?: string;
}
/**
 * 
 * @export
 * @interface TearSheetInputsV1
 */
export interface TearSheetInputsV1 {
    /**
     * 
     * @type {string}
     * @memberof TearSheetInputsV1
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof TearSheetInputsV1
     */
    'domain': string;
}
/**
 * 
 * @export
 * @interface TearSheetOutputsV1
 */
export interface TearSheetOutputsV1 {
    /**
     * 
     * @type {Business}
     * @memberof TearSheetOutputsV1
     */
    'business': Business;
    /**
     * 
     * @type {OfferingProductSynthesis}
     * @memberof TearSheetOutputsV1
     */
    'product_synthesis'?: OfferingProductSynthesis;
}
/**
 * 
 * @export
 * @interface TearSheetWorkflowV1
 */
export interface TearSheetWorkflowV1 {
    /**
     * 
     * @type {string}
     * @memberof TearSheetWorkflowV1
     */
    'workflow_id'?: string;
    /**
     * 
     * @type {TearSheetInputsV1}
     * @memberof TearSheetWorkflowV1
     */
    'inputs': TearSheetInputsV1;
    /**
     * 
     * @type {TearSheetOutputsV1}
     * @memberof TearSheetWorkflowV1
     */
    'outputs'?: TearSheetOutputsV1;
    /**
     * 
     * @type {string}
     * @memberof TearSheetWorkflowV1
     */
    'comp_finder_reference_workflow_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TearSheetWorkflowV1
     */
    'public_comps_reference_workflow_id'?: string;
    /**
     * 
     * @type {string}
     * @memberof TearSheetWorkflowV1
     */
    'parse_helper': TearSheetWorkflowV1ParseHelperEnum;
}

export const TearSheetWorkflowV1ParseHelperEnum = {
    TearSheet: 'tear_sheet'
} as const;

export type TearSheetWorkflowV1ParseHelperEnum = typeof TearSheetWorkflowV1ParseHelperEnum[keyof typeof TearSheetWorkflowV1ParseHelperEnum];

/**
 * 
 * @export
 * @interface TearsheetEnrichment
 */
export interface TearsheetEnrichment {
    /**
     * 
     * @type {string}
     * @memberof TearsheetEnrichment
     */
    'company_domain': string;
    /**
     * 
     * @type {TearsheetEnrichmentType}
     * @memberof TearsheetEnrichment
     */
    'enrichment_type': TearsheetEnrichmentType;
    /**
     * 
     * @type {TearsheetEnrichmentEnrichmentValue}
     * @memberof TearsheetEnrichment
     */
    'enrichment_value': TearsheetEnrichmentEnrichmentValue;
}


/**
 * @type TearsheetEnrichmentEnrichmentValue
 * @export
 */
export type TearsheetEnrichmentEnrichmentValue = PitchbookEnrichment;

/**
 * 
 * @export
 * @enum {string}
 */

export const TearsheetEnrichmentType = {
    Pitchbook: 'pitchbook',
    Prequin: 'prequin'
} as const;

export type TearsheetEnrichmentType = typeof TearsheetEnrichmentType[keyof typeof TearsheetEnrichmentType];


/**
 * 
 * @export
 * @interface TextWithSource
 */
export interface TextWithSource {
    /**
     * 
     * @type {string}
     * @memberof TextWithSource
     */
    'text': string;
    /**
     * 
     * @type {Source}
     * @memberof TextWithSource
     */
    'source': Source;
}
/**
 * 
 * @export
 * @interface TextWithSources
 */
export interface TextWithSources {
    /**
     * 
     * @type {string}
     * @memberof TextWithSources
     */
    'text': string;
    /**
     * 
     * @type {Array<Source>}
     * @memberof TextWithSources
     */
    'sources': Array<Source>;
}
/**
 * 
 * @export
 * @interface TextWithSourcesAndHeadline
 */
export interface TextWithSourcesAndHeadline {
    /**
     * 
     * @type {string}
     * @memberof TextWithSourcesAndHeadline
     */
    'text': string;
    /**
     * 
     * @type {Array<Source>}
     * @memberof TextWithSourcesAndHeadline
     */
    'sources': Array<Source>;
    /**
     * 
     * @type {string}
     * @memberof TextWithSourcesAndHeadline
     */
    'headline': string;
}
/**
 * 
 * @export
 * @interface TextsWithSource
 */
export interface TextsWithSource {
    /**
     * 
     * @type {Array<string>}
     * @memberof TextsWithSource
     */
    'texts': Array<string>;
    /**
     * 
     * @type {Source}
     * @memberof TextsWithSource
     */
    'source': Source;
}
/**
 * 
 * @export
 * @interface UnverifiedShell
 */
export interface UnverifiedShell {
    /**
     * 
     * @type {string}
     * @memberof UnverifiedShell
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof UnverifiedShell
     */
    'overview'?: string;
    /**
     * 
     * @type {string}
     * @memberof UnverifiedShell
     */
    'untruncated_domain'?: string;
}
/**
 * 
 * @export
 * @interface WebTrafficSnapshot
 */
export interface WebTrafficSnapshot {
    /**
     * 
     * @type {number}
     * @memberof WebTrafficSnapshot
     */
    'visits_last_month': number;
    /**
     * 
     * @type {number}
     * @memberof WebTrafficSnapshot
     */
    'visit_growth_monthly'?: number;
    /**
     * 
     * @type {Array<number>}
     * @memberof WebTrafficSnapshot
     */
    'visits_by_month'?: Array<number>;
}
/**
 * 
 * @export
 * @interface WorkflowEffort
 */
export interface WorkflowEffort {
    /**
     * 
     * @type {number}
     * @memberof WorkflowEffort
     */
    'number_of_articles_read': number;
    /**
     * 
     * @type {number}
     * @memberof WorkflowEffort
     */
    'number_of_companies_researched': number;
    /**
     * 
     * @type {number}
     * @memberof WorkflowEffort
     */
    'number_of_llm_calls': number;
}
/**
 * 
 * @export
 * @interface WorkflowFeedback
 */
export interface WorkflowFeedback {
    /**
     * 
     * @type {string}
     * @memberof WorkflowFeedback
     */
    'rating'?: WorkflowFeedbackRatingEnum;
}

export const WorkflowFeedbackRatingEnum = {
    Good: 'good',
    Bad: 'bad'
} as const;

export type WorkflowFeedbackRatingEnum = typeof WorkflowFeedbackRatingEnum[keyof typeof WorkflowFeedbackRatingEnum];

/**
 * 
 * @export
 * @interface WorkflowStub
 */
export interface WorkflowStub {
    /**
     * 
     * @type {string}
     * @memberof WorkflowStub
     */
    'workflow_id': string;
    /**
     * 
     * @type {TaskStatus}
     * @memberof WorkflowStub
     */
    'status': TaskStatus;
    /**
     * 
     * @type {string}
     * @memberof WorkflowStub
     */
    'title': string;
    /**
     * 
     * @type {WorkflowType}
     * @memberof WorkflowStub
     */
    'workflow_type': WorkflowType;
    /**
     * 
     * @type {number}
     * @memberof WorkflowStub
     */
    'created_at'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof WorkflowStub
     */
    'viewed'?: boolean;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const WorkflowType = {
    CompFinder: 'comp_finder',
    CompLandscapeV7: 'comp_landscape_v7',
    MarketMap: 'market_map',
    MarketMapV2: 'market_map_v2',
    PublicComps: 'public_comps',
    TearSheet: 'tear_sheet',
    FindCompanies: 'find_companies',
    FindCompaniesV2: 'find_companies_v2',
    PrecedentTransactions: 'precedent_transactions',
    CustomCompanyProfiles: 'custom_company_profiles',
    GeographicCoverage: 'geographic_coverage',
    ManagementProfiles: 'management_profiles',
    PressSearch: 'press_search',
    EarningsCallPressSnippets: 'earnings_call_press_snippets',
    OfferingComparison: 'offering_comparison',
    SentimentComparison: 'sentiment_comparison',
    CaseStudies: 'case_studies',
    RegulatorySnippets: 'regulatory_snippets',
    RegulatoryEnvironments: 'regulatory_environments',
    GtmComparison: 'gtm_comparison',
    GeographicExpansion: 'geographic_expansion',
    CustomerSegmentation: 'customer_segmentation',
    MarketTrends: 'market_trends',
    BottomUpMarketSizing: 'bottom_up_market_sizing',
    TopDownMarketSizing: 'top_down_market_sizing',
    OfferingDeepdive: 'offering_deepdive',
    CompanyCustomResearch: 'company_custom_research',
    SourceCompanies: 'source_companies'
} as const;

export type WorkflowType = typeof WorkflowType[keyof typeof WorkflowType];



/**
 * BillingApi - axios parameter creator
 * @export
 */
export const BillingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1CheckActiveSubscriptionPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/check_active_subscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1CreateCheckoutSessionPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/create_checkout_session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1CreateCustomerPortalSessionPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/create_customer_portal_session`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BillingApi - functional programming interface
 * @export
 */
export const BillingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BillingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1CheckActiveSubscriptionPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1CheckActiveSubscriptionPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1CreateCheckoutSessionPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1CreateCheckoutSessionPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1CreateCustomerPortalSessionPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1CreateCustomerPortalSessionPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BillingApi - factory interface
 * @export
 */
export const BillingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BillingApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1CheckActiveSubscriptionPost(options?: any): AxiosPromise<boolean> {
            return localVarFp.brainV1CheckActiveSubscriptionPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1CreateCheckoutSessionPost(options?: any): AxiosPromise<string> {
            return localVarFp.brainV1CreateCheckoutSessionPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1CreateCustomerPortalSessionPost(options?: any): AxiosPromise<string> {
            return localVarFp.brainV1CreateCustomerPortalSessionPost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BillingApi - object-oriented interface
 * @export
 * @class BillingApi
 * @extends {BaseAPI}
 */
export class BillingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public brainV1CheckActiveSubscriptionPost(options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).brainV1CheckActiveSubscriptionPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public brainV1CreateCheckoutSessionPost(options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).brainV1CreateCheckoutSessionPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BillingApi
     */
    public brainV1CreateCustomerPortalSessionPost(options?: AxiosRequestConfig) {
        return BillingApiFp(this.configuration).brainV1CreateCustomerPortalSessionPost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BrainV1GetCompanyProfilePostRequest} [brainV1GetCompanyProfilePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetCompanyProfilePost: async (brainV1GetCompanyProfilePostRequest?: BrainV1GetCompanyProfilePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/get_company_profile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1GetCompanyProfilePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BrainV1GetCompanyProfilePostRequest} [brainV1GetCompanyProfilePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1GetCompanyProfilePost(brainV1GetCompanyProfilePostRequest?: BrainV1GetCompanyProfilePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FrontendCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1GetCompanyProfilePost(brainV1GetCompanyProfilePostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * 
         * @param {BrainV1GetCompanyProfilePostRequest} [brainV1GetCompanyProfilePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetCompanyProfilePost(brainV1GetCompanyProfilePostRequest?: BrainV1GetCompanyProfilePostRequest, options?: any): AxiosPromise<FrontendCompany> {
            return localVarFp.brainV1GetCompanyProfilePost(brainV1GetCompanyProfilePostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * 
     * @param {BrainV1GetCompanyProfilePostRequest} [brainV1GetCompanyProfilePostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public brainV1GetCompanyProfilePost(brainV1GetCompanyProfilePostRequest?: BrainV1GetCompanyProfilePostRequest, options?: AxiosRequestConfig) {
        return CompanyApiFp(this.configuration).brainV1GetCompanyProfilePost(brainV1GetCompanyProfilePostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * CompanyListsApi - axios parameter creator
 * @export
 */
export const CompanyListsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BrainV1AddCompaniesFromMarketMapToCompanyListPostRequest} [brainV1AddCompaniesFromMarketMapToCompanyListPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1AddCompaniesFromMarketMapToCompanyListPost: async (brainV1AddCompaniesFromMarketMapToCompanyListPostRequest?: BrainV1AddCompaniesFromMarketMapToCompanyListPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/add_companies_from_market_map_to_company_list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1AddCompaniesFromMarketMapToCompanyListPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BrainV1AddCompaniesToCompanyListPostRequest} [brainV1AddCompaniesToCompanyListPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1AddCompaniesToCompanyListPost: async (brainV1AddCompaniesToCompanyListPostRequest?: BrainV1AddCompaniesToCompanyListPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/add_companies_to_company_list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1AddCompaniesToCompanyListPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BrainV1DeleteCompanyListPostRequest} brainV1DeleteCompanyListPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1DeleteCompanyListPost: async (brainV1DeleteCompanyListPostRequest: BrainV1DeleteCompanyListPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brainV1DeleteCompanyListPostRequest' is not null or undefined
            assertParamExists('brainV1DeleteCompanyListPost', 'brainV1DeleteCompanyListPostRequest', brainV1DeleteCompanyListPostRequest)
            const localVarPath = `/brain/v1/delete_company_list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1DeleteCompanyListPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyList} companyList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1ExportCompanyListToExcelPost: async (companyList: CompanyList, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyList' is not null or undefined
            assertParamExists('brainV1ExportCompanyListToExcelPost', 'companyList', companyList)
            const localVarPath = `/brain/v1/export_company_list_to_excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetCompanyListsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/get_company_lists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CompanyList} companyList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1UpdateCompanyListPost: async (companyList: CompanyList, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'companyList' is not null or undefined
            assertParamExists('brainV1UpdateCompanyListPost', 'companyList', companyList)
            const localVarPath = `/brain/v1/update_company_list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(companyList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyListsApi - functional programming interface
 * @export
 */
export const CompanyListsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyListsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BrainV1AddCompaniesFromMarketMapToCompanyListPostRequest} [brainV1AddCompaniesFromMarketMapToCompanyListPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1AddCompaniesFromMarketMapToCompanyListPost(brainV1AddCompaniesFromMarketMapToCompanyListPostRequest?: BrainV1AddCompaniesFromMarketMapToCompanyListPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1AddCompaniesFromMarketMapToCompanyListPost(brainV1AddCompaniesFromMarketMapToCompanyListPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BrainV1AddCompaniesToCompanyListPostRequest} [brainV1AddCompaniesToCompanyListPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1AddCompaniesToCompanyListPost(brainV1AddCompaniesToCompanyListPostRequest?: BrainV1AddCompaniesToCompanyListPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CompanyList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1AddCompaniesToCompanyListPost(brainV1AddCompaniesToCompanyListPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BrainV1DeleteCompanyListPostRequest} brainV1DeleteCompanyListPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1DeleteCompanyListPost(brainV1DeleteCompanyListPostRequest: BrainV1DeleteCompanyListPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1DeleteCompanyListPost(brainV1DeleteCompanyListPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompanyList} companyList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1ExportCompanyListToExcelPost(companyList: CompanyList, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1ExportCompanyListToExcelPost(companyList, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1GetCompanyListsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CompanyList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1GetCompanyListsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {CompanyList} companyList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1UpdateCompanyListPost(companyList: CompanyList, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1UpdateCompanyListPost(companyList, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CompanyListsApi - factory interface
 * @export
 */
export const CompanyListsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyListsApiFp(configuration)
    return {
        /**
         * 
         * @param {BrainV1AddCompaniesFromMarketMapToCompanyListPostRequest} [brainV1AddCompaniesFromMarketMapToCompanyListPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1AddCompaniesFromMarketMapToCompanyListPost(brainV1AddCompaniesFromMarketMapToCompanyListPostRequest?: BrainV1AddCompaniesFromMarketMapToCompanyListPostRequest, options?: any): AxiosPromise<CompanyList> {
            return localVarFp.brainV1AddCompaniesFromMarketMapToCompanyListPost(brainV1AddCompaniesFromMarketMapToCompanyListPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BrainV1AddCompaniesToCompanyListPostRequest} [brainV1AddCompaniesToCompanyListPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1AddCompaniesToCompanyListPost(brainV1AddCompaniesToCompanyListPostRequest?: BrainV1AddCompaniesToCompanyListPostRequest, options?: any): AxiosPromise<CompanyList> {
            return localVarFp.brainV1AddCompaniesToCompanyListPost(brainV1AddCompaniesToCompanyListPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BrainV1DeleteCompanyListPostRequest} brainV1DeleteCompanyListPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1DeleteCompanyListPost(brainV1DeleteCompanyListPostRequest: BrainV1DeleteCompanyListPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.brainV1DeleteCompanyListPost(brainV1DeleteCompanyListPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyList} companyList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1ExportCompanyListToExcelPost(companyList: CompanyList, options?: any): AxiosPromise<File> {
            return localVarFp.brainV1ExportCompanyListToExcelPost(companyList, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetCompanyListsPost(options?: any): AxiosPromise<Array<CompanyList>> {
            return localVarFp.brainV1GetCompanyListsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CompanyList} companyList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1UpdateCompanyListPost(companyList: CompanyList, options?: any): AxiosPromise<void> {
            return localVarFp.brainV1UpdateCompanyListPost(companyList, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyListsApi - object-oriented interface
 * @export
 * @class CompanyListsApi
 * @extends {BaseAPI}
 */
export class CompanyListsApi extends BaseAPI {
    /**
     * 
     * @param {BrainV1AddCompaniesFromMarketMapToCompanyListPostRequest} [brainV1AddCompaniesFromMarketMapToCompanyListPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyListsApi
     */
    public brainV1AddCompaniesFromMarketMapToCompanyListPost(brainV1AddCompaniesFromMarketMapToCompanyListPostRequest?: BrainV1AddCompaniesFromMarketMapToCompanyListPostRequest, options?: AxiosRequestConfig) {
        return CompanyListsApiFp(this.configuration).brainV1AddCompaniesFromMarketMapToCompanyListPost(brainV1AddCompaniesFromMarketMapToCompanyListPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BrainV1AddCompaniesToCompanyListPostRequest} [brainV1AddCompaniesToCompanyListPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyListsApi
     */
    public brainV1AddCompaniesToCompanyListPost(brainV1AddCompaniesToCompanyListPostRequest?: BrainV1AddCompaniesToCompanyListPostRequest, options?: AxiosRequestConfig) {
        return CompanyListsApiFp(this.configuration).brainV1AddCompaniesToCompanyListPost(brainV1AddCompaniesToCompanyListPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BrainV1DeleteCompanyListPostRequest} brainV1DeleteCompanyListPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyListsApi
     */
    public brainV1DeleteCompanyListPost(brainV1DeleteCompanyListPostRequest: BrainV1DeleteCompanyListPostRequest, options?: AxiosRequestConfig) {
        return CompanyListsApiFp(this.configuration).brainV1DeleteCompanyListPost(brainV1DeleteCompanyListPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyList} companyList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyListsApi
     */
    public brainV1ExportCompanyListToExcelPost(companyList: CompanyList, options?: AxiosRequestConfig) {
        return CompanyListsApiFp(this.configuration).brainV1ExportCompanyListToExcelPost(companyList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyListsApi
     */
    public brainV1GetCompanyListsPost(options?: AxiosRequestConfig) {
        return CompanyListsApiFp(this.configuration).brainV1GetCompanyListsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CompanyList} companyList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyListsApi
     */
    public brainV1UpdateCompanyListPost(companyList: CompanyList, options?: AxiosRequestConfig) {
        return CompanyListsApiFp(this.configuration).brainV1UpdateCompanyListPost(companyList, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DemoApi - axios parameter creator
 * @export
 */
export const DemoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BrainV1FindSimilarCompaniesLightPostRequest} [brainV1FindSimilarCompaniesLightPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FindSimilarCompaniesLightPost: async (brainV1FindSimilarCompaniesLightPostRequest?: BrainV1FindSimilarCompaniesLightPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/find_similar_companies_light`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1FindSimilarCompaniesLightPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DemoApi - functional programming interface
 * @export
 */
export const DemoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DemoApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BrainV1FindSimilarCompaniesLightPostRequest} [brainV1FindSimilarCompaniesLightPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1FindSimilarCompaniesLightPost(brainV1FindSimilarCompaniesLightPostRequest?: BrainV1FindSimilarCompaniesLightPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FrontendCompany>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1FindSimilarCompaniesLightPost(brainV1FindSimilarCompaniesLightPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DemoApi - factory interface
 * @export
 */
export const DemoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DemoApiFp(configuration)
    return {
        /**
         * 
         * @param {BrainV1FindSimilarCompaniesLightPostRequest} [brainV1FindSimilarCompaniesLightPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FindSimilarCompaniesLightPost(brainV1FindSimilarCompaniesLightPostRequest?: BrainV1FindSimilarCompaniesLightPostRequest, options?: any): AxiosPromise<Array<FrontendCompany>> {
            return localVarFp.brainV1FindSimilarCompaniesLightPost(brainV1FindSimilarCompaniesLightPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DemoApi - object-oriented interface
 * @export
 * @class DemoApi
 * @extends {BaseAPI}
 */
export class DemoApi extends BaseAPI {
    /**
     * 
     * @param {BrainV1FindSimilarCompaniesLightPostRequest} [brainV1FindSimilarCompaniesLightPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DemoApi
     */
    public brainV1FindSimilarCompaniesLightPost(brainV1FindSimilarCompaniesLightPostRequest?: BrainV1FindSimilarCompaniesLightPostRequest, options?: AxiosRequestConfig) {
        return DemoApiFp(this.configuration).brainV1FindSimilarCompaniesLightPost(brainV1FindSimilarCompaniesLightPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * DocumentsApi - axios parameter creator
 * @export
 */
export const DocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BrainV1GetDocumentPdfPostRequest} [brainV1GetDocumentPdfPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetDocumentPdfPost: async (brainV1GetDocumentPdfPostRequest?: BrainV1GetDocumentPdfPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/get_document_pdf`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1GetDocumentPdfPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetInternalDocumentsPost: async (body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/get_internal_documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BrainV1IngestDocumentPostRequest} [brainV1IngestDocumentPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1IngestDocumentPost: async (brainV1IngestDocumentPostRequest?: BrainV1IngestDocumentPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/ingest_document`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1IngestDocumentPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BrainV1IngestWebpagePostRequest} [brainV1IngestWebpagePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1IngestWebpagePost: async (brainV1IngestWebpagePostRequest?: BrainV1IngestWebpagePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/ingest_webpage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1IngestWebpagePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentsApi - functional programming interface
 * @export
 */
export const DocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BrainV1GetDocumentPdfPostRequest} [brainV1GetDocumentPdfPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1GetDocumentPdfPost(brainV1GetDocumentPdfPostRequest?: BrainV1GetDocumentPdfPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1GetDocumentPdfPost(brainV1GetDocumentPdfPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1GetInternalDocumentsPost(body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FrontendDocument>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1GetInternalDocumentsPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BrainV1IngestDocumentPostRequest} [brainV1IngestDocumentPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1IngestDocumentPost(brainV1IngestDocumentPostRequest?: BrainV1IngestDocumentPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FrontendDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1IngestDocumentPost(brainV1IngestDocumentPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BrainV1IngestWebpagePostRequest} [brainV1IngestWebpagePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1IngestWebpagePost(brainV1IngestWebpagePostRequest?: BrainV1IngestWebpagePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FrontendDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1IngestWebpagePost(brainV1IngestWebpagePostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentsApi - factory interface
 * @export
 */
export const DocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentsApiFp(configuration)
    return {
        /**
         * 
         * @param {BrainV1GetDocumentPdfPostRequest} [brainV1GetDocumentPdfPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetDocumentPdfPost(brainV1GetDocumentPdfPostRequest?: BrainV1GetDocumentPdfPostRequest, options?: any): AxiosPromise<File> {
            return localVarFp.brainV1GetDocumentPdfPost(brainV1GetDocumentPdfPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetInternalDocumentsPost(body?: object, options?: any): AxiosPromise<Array<FrontendDocument>> {
            return localVarFp.brainV1GetInternalDocumentsPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BrainV1IngestDocumentPostRequest} [brainV1IngestDocumentPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1IngestDocumentPost(brainV1IngestDocumentPostRequest?: BrainV1IngestDocumentPostRequest, options?: any): AxiosPromise<FrontendDocument> {
            return localVarFp.brainV1IngestDocumentPost(brainV1IngestDocumentPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BrainV1IngestWebpagePostRequest} [brainV1IngestWebpagePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1IngestWebpagePost(brainV1IngestWebpagePostRequest?: BrainV1IngestWebpagePostRequest, options?: any): AxiosPromise<FrontendDocument> {
            return localVarFp.brainV1IngestWebpagePost(brainV1IngestWebpagePostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentsApi - object-oriented interface
 * @export
 * @class DocumentsApi
 * @extends {BaseAPI}
 */
export class DocumentsApi extends BaseAPI {
    /**
     * 
     * @param {BrainV1GetDocumentPdfPostRequest} [brainV1GetDocumentPdfPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public brainV1GetDocumentPdfPost(brainV1GetDocumentPdfPostRequest?: BrainV1GetDocumentPdfPostRequest, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).brainV1GetDocumentPdfPost(brainV1GetDocumentPdfPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public brainV1GetInternalDocumentsPost(body?: object, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).brainV1GetInternalDocumentsPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BrainV1IngestDocumentPostRequest} [brainV1IngestDocumentPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public brainV1IngestDocumentPost(brainV1IngestDocumentPostRequest?: BrainV1IngestDocumentPostRequest, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).brainV1IngestDocumentPost(brainV1IngestDocumentPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BrainV1IngestWebpagePostRequest} [brainV1IngestWebpagePostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public brainV1IngestWebpagePost(brainV1IngestWebpagePostRequest?: BrainV1IngestWebpagePostRequest, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).brainV1IngestWebpagePost(brainV1IngestWebpagePostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * EnrichmentApi - axios parameter creator
 * @export
 */
export const EnrichmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetEnrichmentsAdminInfoPost: async (body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/get_enrichments_admin_info`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BrainV1GetTearsheetEnrichmentPostRequest} [brainV1GetTearsheetEnrichmentPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetTearsheetEnrichmentPost: async (brainV1GetTearsheetEnrichmentPostRequest?: BrainV1GetTearsheetEnrichmentPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/get_tearsheet_enrichment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1GetTearsheetEnrichmentPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EnrichmentApi - functional programming interface
 * @export
 */
export const EnrichmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EnrichmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1GetEnrichmentsAdminInfoPost(body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EnrichmentsAdminInfo>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1GetEnrichmentsAdminInfoPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BrainV1GetTearsheetEnrichmentPostRequest} [brainV1GetTearsheetEnrichmentPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1GetTearsheetEnrichmentPost(brainV1GetTearsheetEnrichmentPostRequest?: BrainV1GetTearsheetEnrichmentPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TearsheetEnrichment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1GetTearsheetEnrichmentPost(brainV1GetTearsheetEnrichmentPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * EnrichmentApi - factory interface
 * @export
 */
export const EnrichmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EnrichmentApiFp(configuration)
    return {
        /**
         * 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetEnrichmentsAdminInfoPost(body?: object, options?: any): AxiosPromise<EnrichmentsAdminInfo> {
            return localVarFp.brainV1GetEnrichmentsAdminInfoPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BrainV1GetTearsheetEnrichmentPostRequest} [brainV1GetTearsheetEnrichmentPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetTearsheetEnrichmentPost(brainV1GetTearsheetEnrichmentPostRequest?: BrainV1GetTearsheetEnrichmentPostRequest, options?: any): AxiosPromise<TearsheetEnrichment> {
            return localVarFp.brainV1GetTearsheetEnrichmentPost(brainV1GetTearsheetEnrichmentPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EnrichmentApi - object-oriented interface
 * @export
 * @class EnrichmentApi
 * @extends {BaseAPI}
 */
export class EnrichmentApi extends BaseAPI {
    /**
     * 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnrichmentApi
     */
    public brainV1GetEnrichmentsAdminInfoPost(body?: object, options?: AxiosRequestConfig) {
        return EnrichmentApiFp(this.configuration).brainV1GetEnrichmentsAdminInfoPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BrainV1GetTearsheetEnrichmentPostRequest} [brainV1GetTearsheetEnrichmentPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnrichmentApi
     */
    public brainV1GetTearsheetEnrichmentPost(brainV1GetTearsheetEnrichmentPostRequest?: BrainV1GetTearsheetEnrichmentPostRequest, options?: AxiosRequestConfig) {
        return EnrichmentApiFp(this.configuration).brainV1GetTearsheetEnrichmentPost(brainV1GetTearsheetEnrichmentPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * FindCompaniesV2Api - axios parameter creator
 * @export
 */
export const FindCompaniesV2ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BrainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest} brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1AddSimilarCompaniesToFindCompaniesWorkflowPost: async (brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest: BrainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest' is not null or undefined
            assertParamExists('brainV1AddSimilarCompaniesToFindCompaniesWorkflowPost', 'brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest', brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest)
            const localVarPath = `/brain/v1/add_similar_companies_to_find_companies_workflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FindCompaniesV2InitialInputs} findCompaniesV2InitialInputs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FindCompaniesInitialInputsToInputsPost: async (findCompaniesV2InitialInputs: FindCompaniesV2InitialInputs, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'findCompaniesV2InitialInputs' is not null or undefined
            assertParamExists('brainV1FindCompaniesInitialInputsToInputsPost', 'findCompaniesV2InitialInputs', findCompaniesV2InitialInputs)
            const localVarPath = `/brain/v1/find_companies_initial_inputs_to_inputs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(findCompaniesV2InitialInputs, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FindCompaniesV2Api - functional programming interface
 * @export
 */
export const FindCompaniesV2ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FindCompaniesV2ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BrainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest} brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1AddSimilarCompaniesToFindCompaniesWorkflowPost(brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest: BrainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericWorkflow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1AddSimilarCompaniesToFindCompaniesWorkflowPost(brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {FindCompaniesV2InitialInputs} findCompaniesV2InitialInputs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1FindCompaniesInitialInputsToInputsPost(findCompaniesV2InitialInputs: FindCompaniesV2InitialInputs, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrainV1FindCompaniesInitialInputsToInputsPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1FindCompaniesInitialInputsToInputsPost(findCompaniesV2InitialInputs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * FindCompaniesV2Api - factory interface
 * @export
 */
export const FindCompaniesV2ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FindCompaniesV2ApiFp(configuration)
    return {
        /**
         * 
         * @param {BrainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest} brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1AddSimilarCompaniesToFindCompaniesWorkflowPost(brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest: BrainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest, options?: any): AxiosPromise<GenericWorkflow> {
            return localVarFp.brainV1AddSimilarCompaniesToFindCompaniesWorkflowPost(brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FindCompaniesV2InitialInputs} findCompaniesV2InitialInputs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FindCompaniesInitialInputsToInputsPost(findCompaniesV2InitialInputs: FindCompaniesV2InitialInputs, options?: any): AxiosPromise<BrainV1FindCompaniesInitialInputsToInputsPost200Response> {
            return localVarFp.brainV1FindCompaniesInitialInputsToInputsPost(findCompaniesV2InitialInputs, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FindCompaniesV2Api - object-oriented interface
 * @export
 * @class FindCompaniesV2Api
 * @extends {BaseAPI}
 */
export class FindCompaniesV2Api extends BaseAPI {
    /**
     * 
     * @param {BrainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest} brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FindCompaniesV2Api
     */
    public brainV1AddSimilarCompaniesToFindCompaniesWorkflowPost(brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest: BrainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest, options?: AxiosRequestConfig) {
        return FindCompaniesV2ApiFp(this.configuration).brainV1AddSimilarCompaniesToFindCompaniesWorkflowPost(brainV1AddSimilarCompaniesToFindCompaniesWorkflowPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FindCompaniesV2InitialInputs} findCompaniesV2InitialInputs 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FindCompaniesV2Api
     */
    public brainV1FindCompaniesInitialInputsToInputsPost(findCompaniesV2InitialInputs: FindCompaniesV2InitialInputs, options?: AxiosRequestConfig) {
        return FindCompaniesV2ApiFp(this.configuration).brainV1FindCompaniesInitialInputsToInputsPost(findCompaniesV2InitialInputs, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * GenericV1Api - axios parameter creator
 * @export
 */
export const GenericV1ApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BrainV1FetchGenericWorkflowPostRequest} brainV1FetchGenericWorkflowPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1DeleteGenericWorkflowPost: async (brainV1FetchGenericWorkflowPostRequest: BrainV1FetchGenericWorkflowPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brainV1FetchGenericWorkflowPostRequest' is not null or undefined
            assertParamExists('brainV1DeleteGenericWorkflowPost', 'brainV1FetchGenericWorkflowPostRequest', brainV1FetchGenericWorkflowPostRequest)
            const localVarPath = `/brain/v1/delete_generic_workflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1FetchGenericWorkflowPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generates an Excel file with some sample data and returns it for download.
         * @summary Generate an Excel file
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1ExportWorkflowToExcelPost: async (genericWorkflow: GenericWorkflow, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'genericWorkflow' is not null or undefined
            assertParamExists('brainV1ExportWorkflowToExcelPost', 'genericWorkflow', genericWorkflow)
            const localVarPath = `/brain/v1/export_workflow_to_excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(genericWorkflow, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generates an Powerpoint file with some sample data and returns it for download.
         * @summary Generate a powerpoint file
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1ExportWorkflowToPptPost: async (genericWorkflow: GenericWorkflow, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'genericWorkflow' is not null or undefined
            assertParamExists('brainV1ExportWorkflowToPptPost', 'genericWorkflow', genericWorkflow)
            const localVarPath = `/brain/v1/export_workflow_to_ppt`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(genericWorkflow, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FetchAllGenericWorkflowsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/fetch_all_generic_workflows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BrainV1FetchGenericWorkflowPostRequest} brainV1FetchGenericWorkflowPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FetchGenericWorkflowPost: async (brainV1FetchGenericWorkflowPostRequest: BrainV1FetchGenericWorkflowPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brainV1FetchGenericWorkflowPostRequest' is not null or undefined
            assertParamExists('brainV1FetchGenericWorkflowPost', 'brainV1FetchGenericWorkflowPostRequest', brainV1FetchGenericWorkflowPostRequest)
            const localVarPath = `/brain/v1/fetch_generic_workflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1FetchGenericWorkflowPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FetchLibraryGenericWorkflowStubsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/fetch_library_generic_workflow_stubs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FetchLibraryGenericWorkflowsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/fetch_library_generic_workflows`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FetchWorkflowStubsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/fetch_workflow_stubs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BrainV1GiveGenericWorkflowFeedbackPostRequest} brainV1GiveGenericWorkflowFeedbackPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GiveGenericWorkflowFeedbackPost: async (brainV1GiveGenericWorkflowFeedbackPostRequest: BrainV1GiveGenericWorkflowFeedbackPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brainV1GiveGenericWorkflowFeedbackPostRequest' is not null or undefined
            assertParamExists('brainV1GiveGenericWorkflowFeedbackPost', 'brainV1GiveGenericWorkflowFeedbackPostRequest', brainV1GiveGenericWorkflowFeedbackPostRequest)
            const localVarPath = `/brain/v1/give_generic_workflow_feedback`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1GiveGenericWorkflowFeedbackPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BrainV1FetchGenericWorkflowPostRequest} brainV1FetchGenericWorkflowPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1MarkWorkflowViewedPost: async (brainV1FetchGenericWorkflowPostRequest: BrainV1FetchGenericWorkflowPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brainV1FetchGenericWorkflowPostRequest' is not null or undefined
            assertParamExists('brainV1MarkWorkflowViewedPost', 'brainV1FetchGenericWorkflowPostRequest', brainV1FetchGenericWorkflowPostRequest)
            const localVarPath = `/brain/v1/mark_workflow_viewed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1FetchGenericWorkflowPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1RefineGenericWorkflowPost: async (genericWorkflow: GenericWorkflow, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'genericWorkflow' is not null or undefined
            assertParamExists('brainV1RefineGenericWorkflowPost', 'genericWorkflow', genericWorkflow)
            const localVarPath = `/brain/v1/refine_generic_workflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(genericWorkflow, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1RunGenericWorkflowPost: async (genericWorkflow: GenericWorkflow, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'genericWorkflow' is not null or undefined
            assertParamExists('brainV1RunGenericWorkflowPost', 'genericWorkflow', genericWorkflow)
            const localVarPath = `/brain/v1/run_generic_workflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(genericWorkflow, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1UpdateGenericWorkflowPost: async (genericWorkflow: GenericWorkflow, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'genericWorkflow' is not null or undefined
            assertParamExists('brainV1UpdateGenericWorkflowPost', 'genericWorkflow', genericWorkflow)
            const localVarPath = `/brain/v1/update_generic_workflow`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(genericWorkflow, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GenericV1Api - functional programming interface
 * @export
 */
export const GenericV1ApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GenericV1ApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BrainV1FetchGenericWorkflowPostRequest} brainV1FetchGenericWorkflowPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1DeleteGenericWorkflowPost(brainV1FetchGenericWorkflowPostRequest: BrainV1FetchGenericWorkflowPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1DeleteGenericWorkflowPost(brainV1FetchGenericWorkflowPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generates an Excel file with some sample data and returns it for download.
         * @summary Generate an Excel file
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1ExportWorkflowToExcelPost(genericWorkflow: GenericWorkflow, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1ExportWorkflowToExcelPost(genericWorkflow, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generates an Powerpoint file with some sample data and returns it for download.
         * @summary Generate a powerpoint file
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1ExportWorkflowToPptPost(genericWorkflow: GenericWorkflow, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1ExportWorkflowToPptPost(genericWorkflow, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1FetchAllGenericWorkflowsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GenericWorkflow>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1FetchAllGenericWorkflowsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BrainV1FetchGenericWorkflowPostRequest} brainV1FetchGenericWorkflowPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1FetchGenericWorkflowPost(brainV1FetchGenericWorkflowPostRequest: BrainV1FetchGenericWorkflowPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericWorkflow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1FetchGenericWorkflowPost(brainV1FetchGenericWorkflowPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1FetchLibraryGenericWorkflowStubsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkflowStub>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1FetchLibraryGenericWorkflowStubsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1FetchLibraryGenericWorkflowsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<GenericWorkflow>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1FetchLibraryGenericWorkflowsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1FetchWorkflowStubsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkflowStub>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1FetchWorkflowStubsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BrainV1GiveGenericWorkflowFeedbackPostRequest} brainV1GiveGenericWorkflowFeedbackPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1GiveGenericWorkflowFeedbackPost(brainV1GiveGenericWorkflowFeedbackPostRequest: BrainV1GiveGenericWorkflowFeedbackPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1GiveGenericWorkflowFeedbackPost(brainV1GiveGenericWorkflowFeedbackPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BrainV1FetchGenericWorkflowPostRequest} brainV1FetchGenericWorkflowPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1MarkWorkflowViewedPost(brainV1FetchGenericWorkflowPostRequest: BrainV1FetchGenericWorkflowPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1MarkWorkflowViewedPost(brainV1FetchGenericWorkflowPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1RefineGenericWorkflowPost(genericWorkflow: GenericWorkflow, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericWorkflow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1RefineGenericWorkflowPost(genericWorkflow, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1RunGenericWorkflowPost(genericWorkflow: GenericWorkflow, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GenericWorkflow>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1RunGenericWorkflowPost(genericWorkflow, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1UpdateGenericWorkflowPost(genericWorkflow: GenericWorkflow, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1UpdateGenericWorkflowPost(genericWorkflow, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * GenericV1Api - factory interface
 * @export
 */
export const GenericV1ApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GenericV1ApiFp(configuration)
    return {
        /**
         * 
         * @param {BrainV1FetchGenericWorkflowPostRequest} brainV1FetchGenericWorkflowPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1DeleteGenericWorkflowPost(brainV1FetchGenericWorkflowPostRequest: BrainV1FetchGenericWorkflowPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.brainV1DeleteGenericWorkflowPost(brainV1FetchGenericWorkflowPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Generates an Excel file with some sample data and returns it for download.
         * @summary Generate an Excel file
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1ExportWorkflowToExcelPost(genericWorkflow: GenericWorkflow, options?: any): AxiosPromise<File> {
            return localVarFp.brainV1ExportWorkflowToExcelPost(genericWorkflow, options).then((request) => request(axios, basePath));
        },
        /**
         * Generates an Powerpoint file with some sample data and returns it for download.
         * @summary Generate a powerpoint file
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1ExportWorkflowToPptPost(genericWorkflow: GenericWorkflow, options?: any): AxiosPromise<File> {
            return localVarFp.brainV1ExportWorkflowToPptPost(genericWorkflow, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FetchAllGenericWorkflowsPost(options?: any): AxiosPromise<Array<GenericWorkflow>> {
            return localVarFp.brainV1FetchAllGenericWorkflowsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BrainV1FetchGenericWorkflowPostRequest} brainV1FetchGenericWorkflowPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FetchGenericWorkflowPost(brainV1FetchGenericWorkflowPostRequest: BrainV1FetchGenericWorkflowPostRequest, options?: any): AxiosPromise<GenericWorkflow> {
            return localVarFp.brainV1FetchGenericWorkflowPost(brainV1FetchGenericWorkflowPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FetchLibraryGenericWorkflowStubsPost(options?: any): AxiosPromise<Array<WorkflowStub>> {
            return localVarFp.brainV1FetchLibraryGenericWorkflowStubsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FetchLibraryGenericWorkflowsPost(options?: any): AxiosPromise<Array<GenericWorkflow>> {
            return localVarFp.brainV1FetchLibraryGenericWorkflowsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1FetchWorkflowStubsPost(options?: any): AxiosPromise<Array<WorkflowStub>> {
            return localVarFp.brainV1FetchWorkflowStubsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BrainV1GiveGenericWorkflowFeedbackPostRequest} brainV1GiveGenericWorkflowFeedbackPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GiveGenericWorkflowFeedbackPost(brainV1GiveGenericWorkflowFeedbackPostRequest: BrainV1GiveGenericWorkflowFeedbackPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.brainV1GiveGenericWorkflowFeedbackPost(brainV1GiveGenericWorkflowFeedbackPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BrainV1FetchGenericWorkflowPostRequest} brainV1FetchGenericWorkflowPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1MarkWorkflowViewedPost(brainV1FetchGenericWorkflowPostRequest: BrainV1FetchGenericWorkflowPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.brainV1MarkWorkflowViewedPost(brainV1FetchGenericWorkflowPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1RefineGenericWorkflowPost(genericWorkflow: GenericWorkflow, options?: any): AxiosPromise<GenericWorkflow> {
            return localVarFp.brainV1RefineGenericWorkflowPost(genericWorkflow, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1RunGenericWorkflowPost(genericWorkflow: GenericWorkflow, options?: any): AxiosPromise<GenericWorkflow> {
            return localVarFp.brainV1RunGenericWorkflowPost(genericWorkflow, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GenericWorkflow} genericWorkflow 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1UpdateGenericWorkflowPost(genericWorkflow: GenericWorkflow, options?: any): AxiosPromise<void> {
            return localVarFp.brainV1UpdateGenericWorkflowPost(genericWorkflow, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GenericV1Api - object-oriented interface
 * @export
 * @class GenericV1Api
 * @extends {BaseAPI}
 */
export class GenericV1Api extends BaseAPI {
    /**
     * 
     * @param {BrainV1FetchGenericWorkflowPostRequest} brainV1FetchGenericWorkflowPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericV1Api
     */
    public brainV1DeleteGenericWorkflowPost(brainV1FetchGenericWorkflowPostRequest: BrainV1FetchGenericWorkflowPostRequest, options?: AxiosRequestConfig) {
        return GenericV1ApiFp(this.configuration).brainV1DeleteGenericWorkflowPost(brainV1FetchGenericWorkflowPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generates an Excel file with some sample data and returns it for download.
     * @summary Generate an Excel file
     * @param {GenericWorkflow} genericWorkflow 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericV1Api
     */
    public brainV1ExportWorkflowToExcelPost(genericWorkflow: GenericWorkflow, options?: AxiosRequestConfig) {
        return GenericV1ApiFp(this.configuration).brainV1ExportWorkflowToExcelPost(genericWorkflow, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generates an Powerpoint file with some sample data and returns it for download.
     * @summary Generate a powerpoint file
     * @param {GenericWorkflow} genericWorkflow 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericV1Api
     */
    public brainV1ExportWorkflowToPptPost(genericWorkflow: GenericWorkflow, options?: AxiosRequestConfig) {
        return GenericV1ApiFp(this.configuration).brainV1ExportWorkflowToPptPost(genericWorkflow, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericV1Api
     */
    public brainV1FetchAllGenericWorkflowsPost(options?: AxiosRequestConfig) {
        return GenericV1ApiFp(this.configuration).brainV1FetchAllGenericWorkflowsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BrainV1FetchGenericWorkflowPostRequest} brainV1FetchGenericWorkflowPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericV1Api
     */
    public brainV1FetchGenericWorkflowPost(brainV1FetchGenericWorkflowPostRequest: BrainV1FetchGenericWorkflowPostRequest, options?: AxiosRequestConfig) {
        return GenericV1ApiFp(this.configuration).brainV1FetchGenericWorkflowPost(brainV1FetchGenericWorkflowPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericV1Api
     */
    public brainV1FetchLibraryGenericWorkflowStubsPost(options?: AxiosRequestConfig) {
        return GenericV1ApiFp(this.configuration).brainV1FetchLibraryGenericWorkflowStubsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericV1Api
     */
    public brainV1FetchLibraryGenericWorkflowsPost(options?: AxiosRequestConfig) {
        return GenericV1ApiFp(this.configuration).brainV1FetchLibraryGenericWorkflowsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericV1Api
     */
    public brainV1FetchWorkflowStubsPost(options?: AxiosRequestConfig) {
        return GenericV1ApiFp(this.configuration).brainV1FetchWorkflowStubsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BrainV1GiveGenericWorkflowFeedbackPostRequest} brainV1GiveGenericWorkflowFeedbackPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericV1Api
     */
    public brainV1GiveGenericWorkflowFeedbackPost(brainV1GiveGenericWorkflowFeedbackPostRequest: BrainV1GiveGenericWorkflowFeedbackPostRequest, options?: AxiosRequestConfig) {
        return GenericV1ApiFp(this.configuration).brainV1GiveGenericWorkflowFeedbackPost(brainV1GiveGenericWorkflowFeedbackPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BrainV1FetchGenericWorkflowPostRequest} brainV1FetchGenericWorkflowPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericV1Api
     */
    public brainV1MarkWorkflowViewedPost(brainV1FetchGenericWorkflowPostRequest: BrainV1FetchGenericWorkflowPostRequest, options?: AxiosRequestConfig) {
        return GenericV1ApiFp(this.configuration).brainV1MarkWorkflowViewedPost(brainV1FetchGenericWorkflowPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GenericWorkflow} genericWorkflow 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericV1Api
     */
    public brainV1RefineGenericWorkflowPost(genericWorkflow: GenericWorkflow, options?: AxiosRequestConfig) {
        return GenericV1ApiFp(this.configuration).brainV1RefineGenericWorkflowPost(genericWorkflow, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GenericWorkflow} genericWorkflow 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericV1Api
     */
    public brainV1RunGenericWorkflowPost(genericWorkflow: GenericWorkflow, options?: AxiosRequestConfig) {
        return GenericV1ApiFp(this.configuration).brainV1RunGenericWorkflowPost(genericWorkflow, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GenericWorkflow} genericWorkflow 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GenericV1Api
     */
    public brainV1UpdateGenericWorkflowPost(genericWorkflow: GenericWorkflow, options?: AxiosRequestConfig) {
        return GenericV1ApiFp(this.configuration).brainV1UpdateGenericWorkflowPost(genericWorkflow, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * IngestedCompaniesApi - axios parameter creator
 * @export
 */
export const IngestedCompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetIngestedCompaniesPost: async (body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/get_ingested_companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BrainV1GetCompanyProfilePostRequest} [brainV1GetCompanyProfilePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1IngestCompanyPost: async (brainV1GetCompanyProfilePostRequest?: BrainV1GetCompanyProfilePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/ingest_company`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1GetCompanyProfilePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IngestedCompaniesApi - functional programming interface
 * @export
 */
export const IngestedCompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IngestedCompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1GetIngestedCompaniesPost(body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrainV1GetIngestedCompaniesPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1GetIngestedCompaniesPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BrainV1GetCompanyProfilePostRequest} [brainV1GetCompanyProfilePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1IngestCompanyPost(brainV1GetCompanyProfilePostRequest?: BrainV1GetCompanyProfilePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<IngestedCompany>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1IngestCompanyPost(brainV1GetCompanyProfilePostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * IngestedCompaniesApi - factory interface
 * @export
 */
export const IngestedCompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IngestedCompaniesApiFp(configuration)
    return {
        /**
         * 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetIngestedCompaniesPost(body?: object, options?: any): AxiosPromise<BrainV1GetIngestedCompaniesPost200Response> {
            return localVarFp.brainV1GetIngestedCompaniesPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BrainV1GetCompanyProfilePostRequest} [brainV1GetCompanyProfilePostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1IngestCompanyPost(brainV1GetCompanyProfilePostRequest?: BrainV1GetCompanyProfilePostRequest, options?: any): AxiosPromise<IngestedCompany> {
            return localVarFp.brainV1IngestCompanyPost(brainV1GetCompanyProfilePostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IngestedCompaniesApi - object-oriented interface
 * @export
 * @class IngestedCompaniesApi
 * @extends {BaseAPI}
 */
export class IngestedCompaniesApi extends BaseAPI {
    /**
     * 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestedCompaniesApi
     */
    public brainV1GetIngestedCompaniesPost(body?: object, options?: AxiosRequestConfig) {
        return IngestedCompaniesApiFp(this.configuration).brainV1GetIngestedCompaniesPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BrainV1GetCompanyProfilePostRequest} [brainV1GetCompanyProfilePostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IngestedCompaniesApi
     */
    public brainV1IngestCompanyPost(brainV1GetCompanyProfilePostRequest?: BrainV1GetCompanyProfilePostRequest, options?: AxiosRequestConfig) {
        return IngestedCompaniesApiFp(this.configuration).brainV1IngestCompanyPost(brainV1GetCompanyProfilePostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * PeopleListsApi - axios parameter creator
 * @export
 */
export const PeopleListsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BrainV1DeletePeopleListPostRequest} brainV1DeletePeopleListPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1DeletePeopleListPost: async (brainV1DeletePeopleListPostRequest: BrainV1DeletePeopleListPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brainV1DeletePeopleListPostRequest' is not null or undefined
            assertParamExists('brainV1DeletePeopleListPost', 'brainV1DeletePeopleListPostRequest', brainV1DeletePeopleListPostRequest)
            const localVarPath = `/brain/v1/delete_people_list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1DeletePeopleListPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Generates an Excel file with some sample data and returns it for download.
         * @summary Generate an Excel file
         * @param {PeopleList} peopleList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1ExportPeopleListToExcelPost: async (peopleList: PeopleList, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'peopleList' is not null or undefined
            assertParamExists('brainV1ExportPeopleListToExcelPost', 'peopleList', peopleList)
            const localVarPath = `/brain/v1/export_people_list_to_excel`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(peopleList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BrainV1GetCompanyContactsPostRequest} [brainV1GetCompanyContactsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetCompanyContactsPost: async (brainV1GetCompanyContactsPostRequest?: BrainV1GetCompanyContactsPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/get_company_contacts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1GetCompanyContactsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetPeopleListsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/get_people_lists`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {PeopleList} peopleList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1UpdatePeopleListPost: async (peopleList: PeopleList, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'peopleList' is not null or undefined
            assertParamExists('brainV1UpdatePeopleListPost', 'peopleList', peopleList)
            const localVarPath = `/brain/v1/update_people_list`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(peopleList, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PeopleListsApi - functional programming interface
 * @export
 */
export const PeopleListsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PeopleListsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BrainV1DeletePeopleListPostRequest} brainV1DeletePeopleListPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1DeletePeopleListPost(brainV1DeletePeopleListPostRequest: BrainV1DeletePeopleListPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1DeletePeopleListPost(brainV1DeletePeopleListPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Generates an Excel file with some sample data and returns it for download.
         * @summary Generate an Excel file
         * @param {PeopleList} peopleList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1ExportPeopleListToExcelPost(peopleList: PeopleList, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1ExportPeopleListToExcelPost(peopleList, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BrainV1GetCompanyContactsPostRequest} [brainV1GetCompanyContactsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1GetCompanyContactsPost(brainV1GetCompanyContactsPostRequest?: BrainV1GetCompanyContactsPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FrontendPerson>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1GetCompanyContactsPost(brainV1GetCompanyContactsPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1GetPeopleListsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PeopleList>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1GetPeopleListsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {PeopleList} peopleList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1UpdatePeopleListPost(peopleList: PeopleList, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1UpdatePeopleListPost(peopleList, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PeopleListsApi - factory interface
 * @export
 */
export const PeopleListsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PeopleListsApiFp(configuration)
    return {
        /**
         * 
         * @param {BrainV1DeletePeopleListPostRequest} brainV1DeletePeopleListPostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1DeletePeopleListPost(brainV1DeletePeopleListPostRequest: BrainV1DeletePeopleListPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.brainV1DeletePeopleListPost(brainV1DeletePeopleListPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * Generates an Excel file with some sample data and returns it for download.
         * @summary Generate an Excel file
         * @param {PeopleList} peopleList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1ExportPeopleListToExcelPost(peopleList: PeopleList, options?: any): AxiosPromise<string> {
            return localVarFp.brainV1ExportPeopleListToExcelPost(peopleList, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BrainV1GetCompanyContactsPostRequest} [brainV1GetCompanyContactsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetCompanyContactsPost(brainV1GetCompanyContactsPostRequest?: BrainV1GetCompanyContactsPostRequest, options?: any): AxiosPromise<Array<FrontendPerson>> {
            return localVarFp.brainV1GetCompanyContactsPost(brainV1GetCompanyContactsPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetPeopleListsPost(options?: any): AxiosPromise<Array<PeopleList>> {
            return localVarFp.brainV1GetPeopleListsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {PeopleList} peopleList 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1UpdatePeopleListPost(peopleList: PeopleList, options?: any): AxiosPromise<void> {
            return localVarFp.brainV1UpdatePeopleListPost(peopleList, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PeopleListsApi - object-oriented interface
 * @export
 * @class PeopleListsApi
 * @extends {BaseAPI}
 */
export class PeopleListsApi extends BaseAPI {
    /**
     * 
     * @param {BrainV1DeletePeopleListPostRequest} brainV1DeletePeopleListPostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeopleListsApi
     */
    public brainV1DeletePeopleListPost(brainV1DeletePeopleListPostRequest: BrainV1DeletePeopleListPostRequest, options?: AxiosRequestConfig) {
        return PeopleListsApiFp(this.configuration).brainV1DeletePeopleListPost(brainV1DeletePeopleListPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Generates an Excel file with some sample data and returns it for download.
     * @summary Generate an Excel file
     * @param {PeopleList} peopleList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeopleListsApi
     */
    public brainV1ExportPeopleListToExcelPost(peopleList: PeopleList, options?: AxiosRequestConfig) {
        return PeopleListsApiFp(this.configuration).brainV1ExportPeopleListToExcelPost(peopleList, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BrainV1GetCompanyContactsPostRequest} [brainV1GetCompanyContactsPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeopleListsApi
     */
    public brainV1GetCompanyContactsPost(brainV1GetCompanyContactsPostRequest?: BrainV1GetCompanyContactsPostRequest, options?: AxiosRequestConfig) {
        return PeopleListsApiFp(this.configuration).brainV1GetCompanyContactsPost(brainV1GetCompanyContactsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeopleListsApi
     */
    public brainV1GetPeopleListsPost(options?: AxiosRequestConfig) {
        return PeopleListsApiFp(this.configuration).brainV1GetPeopleListsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PeopleList} peopleList 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PeopleListsApi
     */
    public brainV1UpdatePeopleListPost(peopleList: PeopleList, options?: AxiosRequestConfig) {
        return PeopleListsApiFp(this.configuration).brainV1UpdatePeopleListPost(peopleList, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * RubricApi - axios parameter creator
 * @export
 */
export const RubricApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetRubricPost: async (body?: object, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/get_rubric`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(body, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Rubric} [rubric] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1UpdateRubricPost: async (rubric?: Rubric, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/update_rubric`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(rubric, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RubricApi - functional programming interface
 * @export
 */
export const RubricApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RubricApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1GetRubricPost(body?: object, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Rubric>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1GetRubricPost(body, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {Rubric} [rubric] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1UpdateRubricPost(rubric?: Rubric, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1UpdateRubricPost(rubric, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * RubricApi - factory interface
 * @export
 */
export const RubricApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RubricApiFp(configuration)
    return {
        /**
         * 
         * @param {object} [body] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetRubricPost(body?: object, options?: any): AxiosPromise<Rubric> {
            return localVarFp.brainV1GetRubricPost(body, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Rubric} [rubric] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1UpdateRubricPost(rubric?: Rubric, options?: any): AxiosPromise<void> {
            return localVarFp.brainV1UpdateRubricPost(rubric, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RubricApi - object-oriented interface
 * @export
 * @class RubricApi
 * @extends {BaseAPI}
 */
export class RubricApi extends BaseAPI {
    /**
     * 
     * @param {object} [body] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RubricApi
     */
    public brainV1GetRubricPost(body?: object, options?: AxiosRequestConfig) {
        return RubricApiFp(this.configuration).brainV1GetRubricPost(body, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Rubric} [rubric] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RubricApi
     */
    public brainV1UpdateRubricPost(rubric?: Rubric, options?: AxiosRequestConfig) {
        return RubricApiFp(this.configuration).brainV1UpdateRubricPost(rubric, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ScoringApi - axios parameter creator
 * @export
 */
export const ScoringApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BrainV1GetScoredCompaniesPostRequest} [brainV1GetScoredCompaniesPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetScoredCompaniesPost: async (brainV1GetScoredCompaniesPostRequest?: BrainV1GetScoredCompaniesPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/get_scored_companies`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1GetScoredCompaniesPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ScoringApi - functional programming interface
 * @export
 */
export const ScoringApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ScoringApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BrainV1GetScoredCompaniesPostRequest} [brainV1GetScoredCompaniesPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1GetScoredCompaniesPost(brainV1GetScoredCompaniesPostRequest?: BrainV1GetScoredCompaniesPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ScoredDealcloudCompany>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1GetScoredCompaniesPost(brainV1GetScoredCompaniesPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ScoringApi - factory interface
 * @export
 */
export const ScoringApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ScoringApiFp(configuration)
    return {
        /**
         * 
         * @param {BrainV1GetScoredCompaniesPostRequest} [brainV1GetScoredCompaniesPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetScoredCompaniesPost(brainV1GetScoredCompaniesPostRequest?: BrainV1GetScoredCompaniesPostRequest, options?: any): AxiosPromise<Array<ScoredDealcloudCompany>> {
            return localVarFp.brainV1GetScoredCompaniesPost(brainV1GetScoredCompaniesPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ScoringApi - object-oriented interface
 * @export
 * @class ScoringApi
 * @extends {BaseAPI}
 */
export class ScoringApi extends BaseAPI {
    /**
     * 
     * @param {BrainV1GetScoredCompaniesPostRequest} [brainV1GetScoredCompaniesPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ScoringApi
     */
    public brainV1GetScoredCompaniesPost(brainV1GetScoredCompaniesPostRequest?: BrainV1GetScoredCompaniesPostRequest, options?: AxiosRequestConfig) {
        return ScoringApiFp(this.configuration).brainV1GetScoredCompaniesPost(brainV1GetScoredCompaniesPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * SourceCompaniesApi - axios parameter creator
 * @export
 */
export const SourceCompaniesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {SourceCompaniesInputs} sourceCompaniesInputs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1SourceCompaniesInitialInputsToInputsPost: async (sourceCompaniesInputs: SourceCompaniesInputs, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceCompaniesInputs' is not null or undefined
            assertParamExists('brainV1SourceCompaniesInitialInputsToInputsPost', 'sourceCompaniesInputs', sourceCompaniesInputs)
            const localVarPath = `/brain/v1/source_companies_initial_inputs_to_inputs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(sourceCompaniesInputs, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SourceCompaniesApi - functional programming interface
 * @export
 */
export const SourceCompaniesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SourceCompaniesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {SourceCompaniesInputs} sourceCompaniesInputs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1SourceCompaniesInitialInputsToInputsPost(sourceCompaniesInputs: SourceCompaniesInputs, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BrainV1SourceCompaniesInitialInputsToInputsPost200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1SourceCompaniesInitialInputsToInputsPost(sourceCompaniesInputs, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SourceCompaniesApi - factory interface
 * @export
 */
export const SourceCompaniesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SourceCompaniesApiFp(configuration)
    return {
        /**
         * 
         * @param {SourceCompaniesInputs} sourceCompaniesInputs 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1SourceCompaniesInitialInputsToInputsPost(sourceCompaniesInputs: SourceCompaniesInputs, options?: any): AxiosPromise<BrainV1SourceCompaniesInitialInputsToInputsPost200Response> {
            return localVarFp.brainV1SourceCompaniesInitialInputsToInputsPost(sourceCompaniesInputs, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SourceCompaniesApi - object-oriented interface
 * @export
 * @class SourceCompaniesApi
 * @extends {BaseAPI}
 */
export class SourceCompaniesApi extends BaseAPI {
    /**
     * 
     * @param {SourceCompaniesInputs} sourceCompaniesInputs 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SourceCompaniesApi
     */
    public brainV1SourceCompaniesInitialInputsToInputsPost(sourceCompaniesInputs: SourceCompaniesInputs, options?: AxiosRequestConfig) {
        return SourceCompaniesApiFp(this.configuration).brainV1SourceCompaniesInitialInputsToInputsPost(sourceCompaniesInputs, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * TaxonomyApi - axios parameter creator
 * @export
 */
export const TaxonomyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {BrainV1GetTaxonomyPostRequest} [brainV1GetTaxonomyPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetScoredTaxonomyPost: async (brainV1GetTaxonomyPostRequest?: BrainV1GetTaxonomyPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/get_scored_taxonomy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1GetTaxonomyPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BrainV1GetTaxonomyPostRequest} [brainV1GetTaxonomyPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetTaxonomyPost: async (brainV1GetTaxonomyPostRequest?: BrainV1GetTaxonomyPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/get_taxonomy`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1GetTaxonomyPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TaxonomyApi - functional programming interface
 * @export
 */
export const TaxonomyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TaxonomyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {BrainV1GetTaxonomyPostRequest} [brainV1GetTaxonomyPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1GetScoredTaxonomyPost(brainV1GetTaxonomyPostRequest?: BrainV1GetTaxonomyPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FrontendTaxonomy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1GetScoredTaxonomyPost(brainV1GetTaxonomyPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BrainV1GetTaxonomyPostRequest} [brainV1GetTaxonomyPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1GetTaxonomyPost(brainV1GetTaxonomyPostRequest?: BrainV1GetTaxonomyPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FrontendTaxonomy>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1GetTaxonomyPost(brainV1GetTaxonomyPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * TaxonomyApi - factory interface
 * @export
 */
export const TaxonomyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TaxonomyApiFp(configuration)
    return {
        /**
         * 
         * @param {BrainV1GetTaxonomyPostRequest} [brainV1GetTaxonomyPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetScoredTaxonomyPost(brainV1GetTaxonomyPostRequest?: BrainV1GetTaxonomyPostRequest, options?: any): AxiosPromise<FrontendTaxonomy> {
            return localVarFp.brainV1GetScoredTaxonomyPost(brainV1GetTaxonomyPostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BrainV1GetTaxonomyPostRequest} [brainV1GetTaxonomyPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetTaxonomyPost(brainV1GetTaxonomyPostRequest?: BrainV1GetTaxonomyPostRequest, options?: any): AxiosPromise<FrontendTaxonomy> {
            return localVarFp.brainV1GetTaxonomyPost(brainV1GetTaxonomyPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TaxonomyApi - object-oriented interface
 * @export
 * @class TaxonomyApi
 * @extends {BaseAPI}
 */
export class TaxonomyApi extends BaseAPI {
    /**
     * 
     * @param {BrainV1GetTaxonomyPostRequest} [brainV1GetTaxonomyPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxonomyApi
     */
    public brainV1GetScoredTaxonomyPost(brainV1GetTaxonomyPostRequest?: BrainV1GetTaxonomyPostRequest, options?: AxiosRequestConfig) {
        return TaxonomyApiFp(this.configuration).brainV1GetScoredTaxonomyPost(brainV1GetTaxonomyPostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BrainV1GetTaxonomyPostRequest} [brainV1GetTaxonomyPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TaxonomyApi
     */
    public brainV1GetTaxonomyPost(brainV1GetTaxonomyPostRequest?: BrainV1GetTaxonomyPostRequest, options?: AxiosRequestConfig) {
        return TaxonomyApiFp(this.configuration).brainV1GetTaxonomyPost(brainV1GetTaxonomyPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UpvotesApi - axios parameter creator
 * @export
 */
export const UpvotesApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetAllComingSoonUpvoteCountsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/get_all_coming_soon_upvote_counts`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetUserComingSoonUpvotesPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/get_user_coming_soon_upvotes`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BrainV1SetUserComingSoonUpvotePostRequest} brainV1SetUserComingSoonUpvotePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1SetUserComingSoonUpvotePost: async (brainV1SetUserComingSoonUpvotePostRequest: BrainV1SetUserComingSoonUpvotePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brainV1SetUserComingSoonUpvotePostRequest' is not null or undefined
            assertParamExists('brainV1SetUserComingSoonUpvotePost', 'brainV1SetUserComingSoonUpvotePostRequest', brainV1SetUserComingSoonUpvotePostRequest)
            const localVarPath = `/brain/v1/set_user_coming_soon_upvote`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1SetUserComingSoonUpvotePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UpvotesApi - functional programming interface
 * @export
 */
export const UpvotesApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UpvotesApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1GetAllComingSoonUpvoteCountsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: number; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1GetAllComingSoonUpvoteCountsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1GetUserComingSoonUpvotesPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: boolean; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1GetUserComingSoonUpvotesPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BrainV1SetUserComingSoonUpvotePostRequest} brainV1SetUserComingSoonUpvotePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1SetUserComingSoonUpvotePost(brainV1SetUserComingSoonUpvotePostRequest: BrainV1SetUserComingSoonUpvotePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1SetUserComingSoonUpvotePost(brainV1SetUserComingSoonUpvotePostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UpvotesApi - factory interface
 * @export
 */
export const UpvotesApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UpvotesApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetAllComingSoonUpvoteCountsPost(options?: any): AxiosPromise<{ [key: string]: number; }> {
            return localVarFp.brainV1GetAllComingSoonUpvoteCountsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetUserComingSoonUpvotesPost(options?: any): AxiosPromise<{ [key: string]: boolean; }> {
            return localVarFp.brainV1GetUserComingSoonUpvotesPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BrainV1SetUserComingSoonUpvotePostRequest} brainV1SetUserComingSoonUpvotePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1SetUserComingSoonUpvotePost(brainV1SetUserComingSoonUpvotePostRequest: BrainV1SetUserComingSoonUpvotePostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.brainV1SetUserComingSoonUpvotePost(brainV1SetUserComingSoonUpvotePostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UpvotesApi - object-oriented interface
 * @export
 * @class UpvotesApi
 * @extends {BaseAPI}
 */
export class UpvotesApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpvotesApi
     */
    public brainV1GetAllComingSoonUpvoteCountsPost(options?: AxiosRequestConfig) {
        return UpvotesApiFp(this.configuration).brainV1GetAllComingSoonUpvoteCountsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpvotesApi
     */
    public brainV1GetUserComingSoonUpvotesPost(options?: AxiosRequestConfig) {
        return UpvotesApiFp(this.configuration).brainV1GetUserComingSoonUpvotesPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BrainV1SetUserComingSoonUpvotePostRequest} brainV1SetUserComingSoonUpvotePostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UpvotesApi
     */
    public brainV1SetUserComingSoonUpvotePost(brainV1SetUserComingSoonUpvotePostRequest: BrainV1SetUserComingSoonUpvotePostRequest, options?: AxiosRequestConfig) {
        return UpvotesApiFp(this.configuration).brainV1SetUserComingSoonUpvotePost(brainV1SetUserComingSoonUpvotePostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UsageApi - axios parameter creator
 * @export
 */
export const UsageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1UserContactCreditsAvailablePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/user_contact_credits_available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1UserRunsAvailablePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/user_runs_available`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsageApi - functional programming interface
 * @export
 */
export const UsageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1UserContactCreditsAvailablePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1UserContactCreditsAvailablePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1UserRunsAvailablePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<number>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1UserRunsAvailablePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UsageApi - factory interface
 * @export
 */
export const UsageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsageApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1UserContactCreditsAvailablePost(options?: any): AxiosPromise<number> {
            return localVarFp.brainV1UserContactCreditsAvailablePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1UserRunsAvailablePost(options?: any): AxiosPromise<number> {
            return localVarFp.brainV1UserRunsAvailablePost(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsageApi - object-oriented interface
 * @export
 * @class UsageApi
 * @extends {BaseAPI}
 */
export class UsageApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsageApi
     */
    public brainV1UserContactCreditsAvailablePost(options?: AxiosRequestConfig) {
        return UsageApiFp(this.configuration).brainV1UserContactCreditsAvailablePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsageApi
     */
    public brainV1UserRunsAvailablePost(options?: AxiosRequestConfig) {
        return UsageApiFp(this.configuration).brainV1UserRunsAvailablePost(options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetLastViewedCompanyIdsPost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/get_last_viewed_company_ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetUserDefaultScopePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/get_user_default_scope`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BrainV1SetUserDefaultScopePostRequest} brainV1SetUserDefaultScopePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1SetUserDefaultScopePost: async (brainV1SetUserDefaultScopePostRequest: BrainV1SetUserDefaultScopePostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'brainV1SetUserDefaultScopePostRequest' is not null or undefined
            assertParamExists('brainV1SetUserDefaultScopePost', 'brainV1SetUserDefaultScopePostRequest', brainV1SetUserDefaultScopePostRequest)
            const localVarPath = `/brain/v1/set_user_default_scope`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1SetUserDefaultScopePostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {BrainV1UpdateLastViewedCompanyIdsPostRequest} [brainV1UpdateLastViewedCompanyIdsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1UpdateLastViewedCompanyIdsPost: async (brainV1UpdateLastViewedCompanyIdsPostRequest?: BrainV1UpdateLastViewedCompanyIdsPostRequest, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/brain/v1/update_last_viewed_company_ids`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(brainV1UpdateLastViewedCompanyIdsPostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1GetLastViewedCompanyIdsPost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<string>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1GetLastViewedCompanyIdsPost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1GetUserDefaultScopePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImportableSearchScope>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1GetUserDefaultScopePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BrainV1SetUserDefaultScopePostRequest} brainV1SetUserDefaultScopePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1SetUserDefaultScopePost(brainV1SetUserDefaultScopePostRequest: BrainV1SetUserDefaultScopePostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1SetUserDefaultScopePost(brainV1SetUserDefaultScopePostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {BrainV1UpdateLastViewedCompanyIdsPostRequest} [brainV1UpdateLastViewedCompanyIdsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async brainV1UpdateLastViewedCompanyIdsPost(brainV1UpdateLastViewedCompanyIdsPostRequest?: BrainV1UpdateLastViewedCompanyIdsPostRequest, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.brainV1UpdateLastViewedCompanyIdsPost(brainV1UpdateLastViewedCompanyIdsPostRequest, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetLastViewedCompanyIdsPost(options?: any): AxiosPromise<Array<string>> {
            return localVarFp.brainV1GetLastViewedCompanyIdsPost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1GetUserDefaultScopePost(options?: any): AxiosPromise<ImportableSearchScope> {
            return localVarFp.brainV1GetUserDefaultScopePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BrainV1SetUserDefaultScopePostRequest} brainV1SetUserDefaultScopePostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1SetUserDefaultScopePost(brainV1SetUserDefaultScopePostRequest: BrainV1SetUserDefaultScopePostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.brainV1SetUserDefaultScopePost(brainV1SetUserDefaultScopePostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {BrainV1UpdateLastViewedCompanyIdsPostRequest} [brainV1UpdateLastViewedCompanyIdsPostRequest] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        brainV1UpdateLastViewedCompanyIdsPost(brainV1UpdateLastViewedCompanyIdsPostRequest?: BrainV1UpdateLastViewedCompanyIdsPostRequest, options?: any): AxiosPromise<void> {
            return localVarFp.brainV1UpdateLastViewedCompanyIdsPost(brainV1UpdateLastViewedCompanyIdsPostRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public brainV1GetLastViewedCompanyIdsPost(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).brainV1GetLastViewedCompanyIdsPost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public brainV1GetUserDefaultScopePost(options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).brainV1GetUserDefaultScopePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BrainV1SetUserDefaultScopePostRequest} brainV1SetUserDefaultScopePostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public brainV1SetUserDefaultScopePost(brainV1SetUserDefaultScopePostRequest: BrainV1SetUserDefaultScopePostRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).brainV1SetUserDefaultScopePost(brainV1SetUserDefaultScopePostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {BrainV1UpdateLastViewedCompanyIdsPostRequest} [brainV1UpdateLastViewedCompanyIdsPostRequest] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public brainV1UpdateLastViewedCompanyIdsPost(brainV1UpdateLastViewedCompanyIdsPostRequest?: BrainV1UpdateLastViewedCompanyIdsPostRequest, options?: AxiosRequestConfig) {
        return UserApiFp(this.configuration).brainV1UpdateLastViewedCompanyIdsPost(brainV1UpdateLastViewedCompanyIdsPostRequest, options).then((request) => request(this.axios, this.basePath));
    }
}


