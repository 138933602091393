import { AspectRatio } from "@/components/ui/aspect-ratio";
import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Carousel, CarouselContent, CarouselItem, CarouselNext, CarouselPrevious } from "@/components/ui/carousel";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
  DialogHeader,
  DialogTitle,
  DialogDescription,
  DialogFooter,
  DialogClose
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { AutosizedTextarea } from "@/components/ui/textarea";
import { CompanyDomainAutocompleteForFindSimilarDemo } from "@/components/workflow-inputs/companyWebsiteAutocomplete";

import { isValidUrl } from "@/lib/utils";
import { Broadcast, Database, Target, Users } from "@phosphor-icons/react";
import * as Tabs from "@radix-ui/react-tabs";

import axios from "axios";
import posthog from "posthog-js";
import { useState, useRef, forwardRef } from "react";
import { Toaster, toast } from "sonner";

export const CompanyIntelligenceRequestButton = () => {
  const lambdaBaseUrl = "https://cc23vqfjhgkrpnk2fvx3pxxtkq0grlhf.lambda-url.us-east-1.on.aws/";
  const [email, setEmail] = useState("");
  const [note, setNote] = useState("");
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          variant="outline"
          className="w-30 bg-transparent"
          onClick={() => {
            // toast.info("Not available for public access yet");
            posthog.capture("user_clicked_on_company_intelligence_platform");
          }}>
          Book demo
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Enterprise Demo</DialogTitle>
          <DialogDescription>
            We'd love to show you Meticulate's enterprise offerings and work with you! We'll be in touch as soon as possible.
          </DialogDescription>
        </DialogHeader>
        <div className="grid gap-4 py-4">
          <div className="grid grid-cols-4 items-center gap-4">
            <label htmlFor="email" className="text-right">
              Email
            </label>
            <Input
              type="email"
              id="email"
              placeholder="youremail@gmail.com"
              className="col-span-3"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="grid grid-cols-4 items-center gap-4">
            <label htmlFor="note" className="text-right">
              Note
            </label>
            <AutosizedTextarea
              id="note"
              placeholder="Any thing you want us to know"
              className="col-span-3"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </div>
        </div>
        <DialogFooter>
          <DialogClose asChild>
            <Button
              type="submit"
              onClick={() => {
                posthog.capture("user_requested_access_to_company_intelligence_platform");
                axios.get(lambdaBaseUrl + "?email=" + encodeURIComponent(email) + "&note=" + encodeURIComponent(note));
                toast.info("Thanks for requesting access! We'll be in touch!");
                setEmail("");
                setNote("");
              }}>
              Submit
            </Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export const HowItWorksSection = () => {
  return (
    <div className="flex flex-col items-center justify-center w-full relative py-10">
      <div className="text-2xl text-black mb-10 font-mono uppercase px-4">How it works - The Meticulate engine</div>
      {/* <img src="/tmp_apollo_visual.png" className="w-full" /> */}
      <Carousel className="w-[75%] max-w-[1000px]">
        <CarouselContent>
          <CarouselItem className="basis-full items-center justify-center">
            <div className="flex md:flex-row flex-col items-center justify-center gap-10">
              <div className="flex flex-col md:w-[400px]">
                <div className="md:text-2xl text-xl text-black md:mb-10 mb-2 font-mono uppercase">1. Data gathering</div>
                <div className="text-base text-paragraph-text-color font-light">
                  Meticulate consumes an enormous amount of data per company, both unstructured and structured, from the public web, data
                  providers, and customer internal data.
                </div>
              </div>
              <div className="flex-grow">
                <img src="/data_ocean.png" className="object-contain rounded-lg" />
              </div>
            </div>
          </CarouselItem>
          {/* <CarouselItem className="basis-full">
            <div className="flex md:flex-row flex-col items-center justify-center gap-10">
              <div className="flex flex-col md:w-[400px]">
                <div className="md:text-2xl text-xl text-black md:mb-10 mb-2 font-mono uppercase">2. LLM pipelines</div>
                <div className="text-base text-paragraph-text-color font-light">
                  Our LLM & data pipelines then process the data streams, solving for credibility and comprehensiveness.
                </div>
              </div>
              <div className="flex-grow">
                <img src="/pipelines.png" className="object-contain rounded-lg" />
              </div>
            </div>
          </CarouselItem> */}
          <CarouselItem className="basis-full">
            <div className="flex md:flex-row flex-col items-center justify-center gap-10">
              <div className="flex flex-col md:w-[400px]">
                <div className="md:text-2xl text-xl text-black md:mb-10 mb-2 font-mono uppercase">2. Company Profiles</div>
                <div className="text-base text-paragraph-text-color font-light">
                  We generate the most comprehensive automated company profiles available anywhere, comparable to what an investment or
                  sales analyst would produce in a few hours.
                </div>
              </div>
              <div className="flex-grow">
                <img src="/company_profile.png" className="object-contain rounded-lg" />
              </div>
            </div>
          </CarouselItem>
          <CarouselItem className="basis-full">
            <div className="flex md:flex-row flex-col items-center justify-center gap-10">
              <div className="flex flex-col md:w-[400px]">
                <div className="md:text-2xl text-xl text-black md:mb-10 mb-2 font-mono uppercase">3. Powerful Search & Filter</div>
                <div className="text-base text-paragraph-text-color font-light">
                  Our LLM pipelines enable extremely powerful search and filter, allowing you to request company searches the same way you
                  would ask a human.
                </div>
              </div>
              <div className="flex-grow">
                <img src="/search_and_filter.png" className="object-contain rounded-lg" />
              </div>
            </div>
          </CarouselItem>
          <CarouselItem className="basis-full">
            <div className="flex md:flex-row flex-col items-center justify-center gap-10">
              <div className="flex flex-col w-[300px]">
                <div className="md:text-2xl text-xl text-black md:mb-10 mb-2 font-mono uppercase">4. Custom Research</div>
                <div className="text-base text-paragraph-text-color font-light">
                  Our pipelines can also be deployed towards your customized research & scoring needs.
                </div>
              </div>
              <div className="flex-grow">
                <img src="/custom_research.png" className="object-contain rounded-lg" />
              </div>
            </div>
          </CarouselItem>
        </CarouselContent>
        <CarouselPrevious />
        <CarouselNext />
      </Carousel>
    </div>
  );
};

export const ForGTMSection = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div
      ref={ref}
      className="flex flex-col items-center justify-center w-full border-y border-dashed bg-secondary-white border-light-border md:pt-10 md:pb-20 pt-4 relative p-2">
      <div className="text-2xl text-black mb-10 font-mono uppercase">For GTM Teams</div>
      <Tabs.Root
        defaultValue="leads"
        className="flex md:flex-row flex-col w-full justify-between md:items-start items-center gap-4 max-w-[1000px]">
        <Tabs.List className="flex flex-col md:w-1/2 w-full md:items-start items-center gap-2">
          <Tabs.Trigger
            value="leads"
            className="flex flex-row items-center justify-start w-full max-w-[340px] outline-colors-orange-200 data-[state=active]:outline data-[state=active]:bg-colors-orange-50 data-[state=active]:text-black text-gray-500 rounded-lg p-2 outline-1 hover:bg-colors-orange-50">
            <Target className="mr-4 flex-shrink-0 w-5 h-5" />
            <div className="flex flex-col">
              <span className="text-left">Build hyper targeted lead lists for any ICP</span>
            </div>
          </Tabs.Trigger>
          <Tabs.Trigger
            value="contacts"
            className="flex flex-row items-center justify-start w-full max-w-[340px] outline-colors-orange-200 data-[state=active]:outline data-[state=active]:bg-colors-orange-50 data-[state=active]:text-black text-gray-500 rounded-lg p-2 outline-1 hover:bg-colors-orange-50">
            <Users className="mr-4 flex-shrink-0 w-5 h-5" />
            <span className="text-left">Find the right contacts regardless of title</span>
          </Tabs.Trigger>
          <Tabs.Trigger
            value="crm"
            className="flex flex-row items-center justify-start w-full max-w-[340px] outline-colors-orange-200 data-[state=active]:outline data-[state=active]:bg-colors-orange-50 data-[state=active]:text-black text-gray-500 rounded-lg p-2 outline-1 hover:bg-colors-orange-50">
            <Database className="mr-4 flex-shrink-0 w-5 h-5" />
            <div className="flex flex-col">
              <Badge className="w-20 mb-1 text-colors-gray-modern-300" variant="outline">
                Enterprise
              </Badge>
              <span className="text-left">Continuously refresh your CRM for leads & track updates</span>
            </div>
          </Tabs.Trigger>
          <Tabs.Trigger
            value="signals"
            className="flex flex-row items-center justify-start w-full max-w-[340px] outline-colors-orange-200 data-[state=active]:outline data-[state=active]:bg-colors-orange-50 data-[state=active]:text-black text-gray-500 rounded-lg p-2 outline-1 hover:bg-colors-orange-50">
            <Broadcast className="mr-4 flex-shrink-0 w-5 h-5" />
            <div className="flex flex-col ">
              <Badge className="w-20 mb-1 text-colors-gray-modern-300" variant="outline">
                Enterprise
              </Badge>
              <span className="text-left">Define intelligent buying signals and custom-score leads</span>
            </div>
          </Tabs.Trigger>
        </Tabs.List>
        <div className="md:w-1/2 w-full">
          <AspectRatio ratio={3 / 2}>
            <Tabs.Content value="leads" className="absolute inset-0 w-full h-full">
              <img src="/lead_lists.png" className="w-full h-full object-fill rounded-xl" />
            </Tabs.Content>
            <Tabs.Content value="contacts" className="absolute inset-0">
              <img src="/contacts.png" className="w-full h-full object-fill rounded-xl" />
            </Tabs.Content>
            <Tabs.Content value="crm" className="absolute inset-0">
              <img src="/crm_enrich.png" className="w-full h-full object-fill rounded-xl" />
            </Tabs.Content>
            <Tabs.Content value="signals" className="absolute inset-0">
              <img src="/signals.png" className="w-full h-full object-fill rounded-xl" />
            </Tabs.Content>
          </AspectRatio>
        </div>
      </Tabs.Root>
    </div>
  );
});

export const ForInvestorsSection = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <div
      ref={ref}
      className="flex flex-col items-center justify-center w-full bg-white border-light-border md:pt-10 md:pb-20 pt-4 p-2 relative">
      <div className="text-2xl text-black mb-10 font-mono uppercase">For Investors</div>
      <Tabs.Root
        defaultValue="source"
        className="flex md:flex-row-reverse flex-col w-full justify-between md:items-start items-center gap-4 max-w-[1000px]">
        <Tabs.List className="flex flex-col md:w-1/2 w-full md:items-end items-center gap-2">
          <Tabs.Trigger
            value="source"
            className="flex flex-row items-center justify-start w-full max-w-[340px] outline-colors-blue-200 data-[state=active]:outline data-[state=active]:bg-colors-blue-50 data-[state=active]:text-black text-gray-500 rounded-lg p-2 outline-1 hover:bg-colors-blue-50">
            <Target className="mr-4 flex-shrink-0 w-5 h-5" />
            <span className="text-left">Source companies and discover hidden gems</span>
          </Tabs.Trigger>
          <Tabs.Trigger
            value="theses"
            className="flex flex-row items-center justify-start w-full max-w-[340px] outline-colors-blue-200 data-[state=active]:outline data-[state=active]:bg-colors-blue-50 data-[state=active]:text-black text-gray-500 rounded-lg p-2 outline-1 hover:bg-colors-blue-50">
            <Users className="mr-4 flex-shrink-0 w-5 h-5" />
            <span className="text-left">Run targeted searches to match any investment thesis</span>
          </Tabs.Trigger>
          <Tabs.Trigger
            value="score"
            className="flex flex-row items-center justify-start w-full max-w-[340px] outline-colors-blue-200 data-[state=active]:outline data-[state=active]:bg-colors-blue-50 data-[state=active]:text-black text-gray-500 rounded-lg p-2 outline-1 hover:bg-colors-blue-50">
            <Database className="mr-4 flex-shrink-0 w-5 h-5" />
            <div className="flex flex-col">
              <Badge className="w-20 mb-1 text-colors-gray-modern-300" variant="outline">
                Enterprise
              </Badge>
              <span className="text-left">Score & prioritize companies according to your firm's criteria</span>
            </div>
          </Tabs.Trigger>
          <Tabs.Trigger
            value="taxonomy"
            className="flex flex-row items-center justify-start w-full max-w-[340px] outline-colors-blue-200 data-[state=active]:outline data-[state=active]:bg-colors-blue-50 data-[state=active]:text-black text-gray-500 rounded-lg p-2 outline-1 hover:bg-colors-blue-50">
            <Broadcast className="mr-4 flex-shrink-0 w-5 h-5" />
            <span className="text-left">Map out markets and create taxonomies</span>
          </Tabs.Trigger>
        </Tabs.List>
        <div className="flex flex-col md:w-1/2 w-full">
          <AspectRatio ratio={3 / 2}>
            <Tabs.Content value="source" className="absolute inset-0">
              <img src="/source_companies.png" className="w-full h-full object-fill rounded-xl" />
            </Tabs.Content>
            <Tabs.Content value="theses" className="absolute inset-0">
              <img src="/investment_theses.png" className="w-full h-full object-fill rounded-xl" />
            </Tabs.Content>
            <Tabs.Content value="score" className="absolute inset-0">
              <img src="/rubric_scoring.png" className="w-full h-full object-fill rounded-xl" />
            </Tabs.Content>
            <Tabs.Content value="taxonomy" className="absolute inset-0">
              <img src="/taxonomy.png" className="w-full h-full object-fill rounded-xl" />
            </Tabs.Content>
          </AspectRatio>
        </div>
      </Tabs.Root>
    </div>
  );
});

export function LandingPage() {
  const [company, setCompany] = useState("");
  const gtmSectionRef = useRef<HTMLDivElement>(null);
  const investorsSectionRef = useRef<HTMLDivElement>(null);
  const scrollToGTMSection = () => {
    gtmSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const scrollToInvestorsSection = () => {
    investorsSectionRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <>
      <div className="flex flex-col dark relative font-outfit">
        <div className="h-15 bg-[rgba(255,255,255,0.56)] px-5 py-2 flex flex-row items-center backdrop-blur-md fixed z-50 w-full border-b border-light-border">
          <img src="/MeticulateIconSquare.svg" className="h-4" />
          <div className="text-xl ml-1 text-colors-brand-700 font-medium">Meticulate</div>
          <div className="hidden md:flex flex-row gap-8 ml-10">
            <div className="text-sm text-gray-500 cursor-pointer hover:text-gray-700" onClick={scrollToGTMSection}>
              For GTM teams
            </div>
            <div className="text-sm text-gray-500 cursor-pointer hover:text-gray-700" onClick={scrollToInvestorsSection}>
              For investors
            </div>
          </div>
          <div className="flex-grow"></div>
          <div className="flex flex-row items-center gap-2">
            <CompanyIntelligenceRequestButton />
            <Button
              className="w-30 h-10 bg-[#7F5EFF] hover:bg-[#8F5EFF] text-white shadow-[0_0_10px_rgba(255,255,255,0.5)] hover:shadow-[0_0_10px_rgba(255,255,255,0.6)]"
              onClick={() => {
                window.location.href = "https://app.meticulate.ai";
              }}>
              Login
            </Button>
          </div>
        </div>
        <div className=" w-full relative" style={{ background: "linear-gradient(#bcbcc4, #c9c9d0 18%, #fff 40%)" }}>
          <img src="/glow.webp" className="absolute top-0 left-0 w-full opacity-100 md:h-auto h-1/2" />
          <div className="md:pt-60 pt-40 p-4 flex flex-col w-full items-center md:justify-center relative z-10">
            <div className="md:text-5xl text-4xl text-black font-medium text-center max-w-[700px]">
              The Most Powerful Way to <div className="text-colors-brand-700">Find Companies</div>
            </div>
            <div className="md:text-xl text-lg flex mt-5 mb-10 font-light text-colors-gray-modern-700 text-center opacity-80 max-w-[500px]">
              Target any ICP you can imagine, find the right contacts, and define your own buying signals
            </div>
            <div className="flex flex-row gap-2">
              <Button
                className="w-30 h-10 bg-[#7F5EFF] hover:bg-[#8F5EFF] text-white mb-4 shadow-[0_0_10px_rgba(255,255,255,0.5)] hover:shadow-[0_0_10px_rgba(255,255,255,0.6)]"
                onClick={() => {
                  window.location.href = "https://app.meticulate.ai";
                }}>
                Try for free now
              </Button>
              <CompanyIntelligenceRequestButton />
            </div>
            <div className="flex flex-col flex-wrap items-center justify-center w-full gap-2">
              <div className="text-colors-gray-modern-200">or</div>
              <div className="flex flex-col items-center gap-2">
                <div className="text-colors-gray-modern-200">Try our similar companies search</div>
                {/* NOTE: this preserve-3d somehow makes the autocomplete width look correct */}
                <div
                  className="flex flex-row items-center gap-4 bg-white  rounded-lg p-2 mb-5 relative z-50"
                  style={{ transformStyle: "preserve-3d" }}>
                  <CompanyDomainAutocompleteForFindSimilarDemo
                    value={company}
                    placeholder="Company domain"
                    onChange={(e) => setCompany(e)}
                    onSubmit={({ value }) => {
                      setCompany(value);
                    }}
                  />
                  <Button
                    type="submit"
                    onClick={() => {
                      if (company && isValidUrl(company)) {
                        window.location.href = `/find-similar?company_domain=${encodeURIComponent(company)}`;
                      } else {
                        toast.error("Please enter a valid company domain");
                      }
                    }}
                    className="w-30 h-10 bg-[#7F5EFF] hover:bg-[#8F5EFF] text-white">
                    Search
                  </Button>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center mt-20 mb-20">
              <div className="text-xl flex mb-5 font-normal text-colors-gray-modern-200 text-center opacity-50 ">
                Our investors and customers
              </div>
              <div className="flex flex-row gap-x-10 gap-y-2 flex-wrap items-center content-center justify-center">
                <a href="https://www.ycombinator.com/launches/KUx-meticulate-effortless-business-research" target="_blank">
                  <img className="md:h-7 h-4 opacity-80" src="/yc_logo_expanded_orange.png" />
                </a>
                <img src="/leonis_capital.webp" className="md:h-10 h-6 rounded opacity-70 invert" />

                <img src="/link_logo.png" className="md:h-8 h-5 rounded opacity-70" />
                <img src="/Cervin_logo.svg" className="md:h-20 h-12 rounded opacity-70 p-1" />
                <img src="/infogrid_logo.png" className="md:h-8 h-5 rounded opacity-70" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <HowItWorksSection />
      <ForGTMSection ref={gtmSectionRef} />
      <ForInvestorsSection ref={investorsSectionRef} />

      <footer className="bg-secondary-white py-8 border-t border-dashed border-light-border">
        <div className="container mx-auto flex flex-col items-center">
          <img src="/MeticulateIconSquare.svg" alt="Meticulate Logo" className="h-8 mb-4" />
          {/* <div className="text-sm text-gray-500">© {new Date().getFullYear()} Meticulate. All rights reserved.</div> */}
        </div>
      </footer>

      <Toaster position="top-center" richColors />
    </>
  );
}
