import { createBrowserRouter, useRouteError, isRouteErrorResponse, json } from "react-router-dom";
import { SignIn, SignUp } from "@clerk/clerk-react";
import { FindSimilarPage } from "./FindSimilarPage";
import { LandingPage } from "./LandingPage";
import "@/App.css";
import { ApiAccessRequestPage } from "./ApiAccessRequest";

function ErrorBoundary() {
  const error = useRouteError();

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return <h1>404 - Not Found</h1>;
    }
  }

  return <h1>Something went wrong</h1>;
}

export const MeticulateRouter = createBrowserRouter([
  {
    path: "/find-similar",
    element: <FindSimilarPage />,
    loader: ({ request }) => {
      const url = new URL(request.url);
      const companyDomain = url.searchParams.get("company_domain");
      return { companyDomain };
    }
  },
  {
    path: "/",
    element: <LandingPage />
  },
  {
    path: "/api-access-request",
    element: <ApiAccessRequestPage />,
    loader: ({ request }) => {
      const url = new URL(request.url);
      const companyDomain = url.searchParams.get("company_domain");
      return { companyDomain };
    }
  },
  {
    path: "/workflow/:workflowId",
    loader: ({ params }) => {
      if (params.workflowId !== undefined) {
        window.location.href = `https://app.meticulate.ai/workflow/${params.workflowId}`;
      } else {
        console.error("No workflow ID provided");
      }
      return null;
    },
    element: null
  },
  {
    path: "/app",
    loader: ({}) => {
      window.location.href = `https://app.meticulate.ai`;
      return null;
    },
    element: null
  },
  {
    path: "/sign-up",
    element: (
      <div className="flex justify-center items-center m-auto p-[15%] bg-primary h-screen">
        <SignUp routing="path" path="/sign-up" signInUrl="/sign-in" redirectUrl="/app" />
      </div>
    )
  },
  {
    path: "/sign-in",
    element: (
      <div className="flex justify-center items-center m-auto p-[15%] bg-primary h-screen">
        <SignIn routing="path" path="/sign-in" signUpUrl="/sign-up" redirectUrl="/app" />
      </div>
    )
  },
  {
    path: "*", // Wildcard route for 404
    loader: () => {
      throw json({ message: "Not Found" }, { status: 404 });
    },
    element: <ErrorBoundary />,
    errorElement: <ErrorBoundary />
  }
]);
