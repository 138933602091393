/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-unused-vars */

import { ImportableSearchScope } from "@/services/autogen";
import { getUserDefaultScope } from "@/services/brain-api.service";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export type UserState = {
  defaultScope?: ImportableSearchScope;
  token?: string;
};

const initialState: UserState = {};

export const loadUserDefaultScope = createAsyncThunk("loadUserDefaultScope", async (thunkPayload: { token: string }, thunkAPI) => {
  const { token } = thunkPayload;
  getUserDefaultScope(token).then((response) => {
    if (response.status === 200) {
      const responseScope = response.data;
      thunkAPI.dispatch(userSlice.actions.setDefaultScope(responseScope));
    }
  });
});

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setDefaultScope: (state, action: PayloadAction<ImportableSearchScope>) => {
      state.defaultScope = action.payload;
    },
    setUserToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setDefaultScope, setUserToken } = userSlice.actions;

export default userSlice.reducer;
