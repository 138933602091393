import { Dialog } from "@/components/ui/dialog";
import { useState } from "react";
import { Toaster, toast } from "sonner";
import { Button } from "@/components/ui/button";
import posthog from "posthog-js";
import axios from "axios";
import { Input } from "@/components/ui/input";
import { AutosizedTextarea } from "@/components/ui/textarea";

export function ApiAccessRequestPage() {
  const lambdaBaseUrl = "https://cc23vqfjhgkrpnk2fvx3pxxtkq0grlhf.lambda-url.us-east-1.on.aws/";
  const [email, setEmail] = useState("");
  const [note, setNote] = useState("");

  return (
    <Dialog>
      <div
        className="flex flex-col dark relative min-h-screen"
        style={{ background: "radial-gradient(94% 58.3% at 50.3% 82.6%, #2a2541 0%, rgb(17, 16, 19) 100%)" }}>
        <div className="w-screen h-15 bg-[#111013] p-5 flex flex-row items-center">
          <img
            src="/MeticulateIconSquare.svg"
            className="h-10 cursor-pointer"
            onClick={() => (window.location.href = "https://meticulate.ai")}
          />
        </div>
        <div className="p-4 flex flex-col h-full w-screen items-center md:justify-center">
          {/* <div className="flex-grow"></div> */}
          <div className="md:text-4xl text-3xl flex text-[#7F5EFF] font-semibold text-center md:mt-0 mt-5">
            Meticulate - API access request
          </div>

          <div className="flex flex-col items-center gap-4 w-full my-10">
            <div className="grid grid-cols-4 items-center gap-4">
              <label htmlFor="email" className="text-right text-white">
                Email
              </label>
              <Input
                type="email"
                id="email"
                placeholder="youremail@gmail.com"
                className="col-span-3 bg-white w-[250px]"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="grid grid-cols-4 items-center gap-4">
              <label htmlFor="note" className="text-right text-white">
                Note
              </label>
              <AutosizedTextarea
                id="note"
                placeholder="Any thing you want us to know"
                className="col-span-3 bg-white w-[250px] h-[100px]"
                value={note}
                onChange={(e) => setNote(e.target.value)}
              />
            </div>
          </div>

          <Button
            type="submit"
            onClick={() => {
              posthog.capture("user_requested_access_to_company_intelligence_platform");
              axios.get(
                lambdaBaseUrl + "?email=" + encodeURIComponent(email) + "&note=" + encodeURIComponent(" API access request - " + note)
              );
              toast.info("Thanks for requesting access! We'll be in touch!");
              setEmail("");
              setNote("");
            }}>
            Request access
          </Button>
        </div>
      </div>
      <Toaster position="top-center" richColors />
    </Dialog>
  );
}
