import { Search, X } from "lucide-react";
import { ChangeEvent, useCallback, FormEvent, useEffect, useState } from "react";
import { getDomainFromUrl } from "../ui/avatar";
import { truncate } from "lodash";

type TQuery = {
  name: string;
  domain: string;
  icon: string;
};

export type TAutocomplete = {
  value: string;
  query?: TQuery;
  queries: TQuery[];
};

interface IAutocomplete {
  onChange: (value: string) => void;
  onSubmit: ({ value, queries, query }: TAutocomplete) => void;
  placeholder: string;
}

export const CompanyDomainAutocomplete = ({ onChange, onSubmit, placeholder }: IAutocomplete) => {
  const [value, setValue] = useState({ text: "", active: false });
  const [queries, setQueries] = useState<TQuery[]>([]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const text = value.text;
    onSubmit({ value: text, query: undefined, queries });
    setValue({ text, active: false });
    setQueries([]);
  };

  const handleClick = (query: TQuery) => {
    setValue({ text: query.domain, active: false });
    onSubmit({ value: query.domain, query, queries });
  };

  const reset = () => {
    setQueries([]);
    setValue({ text: "", active: false });
    onSubmit({ value: "", queries: [], query: undefined });
  };

  const getQueries = useCallback(async (searchValue: string) => {
    if (searchValue !== "") {
      try {
        const url = `https://api.brandfetch.io/v2/search/${searchValue}`;

        const res = await fetch(url);
        if (res.ok) {
          const data = await res.json();
          setQueries(data);
        }
      } catch (err) {
        console.log("Something went wrong, try again later.");
      }
      return;
    }

    setQueries([]);
  }, []);

  useEffect(() => {
    getQueries(value.text);
  }, [getQueries, value.text]);

  return (
    <div className="relative w-full">
      <div className="flex flex-row justify-between items-end text-colors-text-text-secondary-(700) mb-3">
        <div className="text-sm mr-3">Company website</div>
        <div className="text-xs text-right text-colors-text-text-quarterary-(500)">
          {"("}Logos provided by{" "}
          <a href="https://brandfetch.com/" rel="noreferrer" target="_blank">
            Brandfetch
          </a>
          {")"}
        </div>
      </div>

      <form onSubmit={handleSubmit} className="w-full relative">
        <label className="absolute left-2 top-3 text-colors-gray-modern-600">
          <Search size={16} />
        </label>

        <input
          className="border border-colors-border-border-primary bg-transparent rounded-lg pl-8 pr-8 py-2 focus-visible:outline-none text-colors-text-text-primary-(900) w-full"
          placeholder={placeholder}
          value={value.text}
          // TODO: handle pastes with https:// prefixes and suffixes by truncating
          onPaste={(e) => {
            e.preventDefault();
            const domain = getDomainFromUrl(e.clipboardData.getData("text"));
            setValue({ text: domain, active: true });
            onChange(domain);
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setValue({ text: e.target.value, active: true });
            onChange(e.target.value);
          }}
        />

        {value.text !== "" && (
          <label className="absolute right-1 top-2 cursor-pointer" onClick={() => reset()}>
            <X />
          </label>
        )}
      </form>

      {value.active && value.text !== "" && (
        <div className="fixed mt-2 left-0 right-0  bg-colors-brand-50 rounded-md grid z-50 p-5 border border-colors-border-border-secondary">
          {queries.length ? (
            <div className="flex flex-col gap-2 text-colors-text-text-secondary-(700)">
              {queries
                .filter((query) => query.domain.includes(value.text) && (query.domain.match(/\./g) || []).length < 2)
                .map((query, i) => (
                  <div
                    key={i}
                    className="flex gap-2 hover:bg-colors-brand-300 p-2 rounded-md cursor-pointer flex-row items-center w-full justify-between"
                    onClick={() => handleClick(query)}>
                    <div className="flex flex-row items-center gap-2">
                      <div className="h-5 w-5 relative overflow-hidden shadow-sm z-50">
                        <img src={query.icon} alt={query.name} className="w-full h-full top-0 left-0 right-0 bottom-0" />
                      </div>

                      <p>{truncate(query.name || query.domain, { length: 15 })}</p>
                    </div>

                    <p className="self-end place-self-end">{query.domain}</p>
                  </div>
                ))}
            </div>
          ) : (
            <div className="flex flex-col gap-2 text-center">
              <p className="bold">Couldn't auto-recognize this company... you can still try running the workflow</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export const CompanyDomainAutocompleteForFindSimilarDemo = ({
  onChange,
  onSubmit,
  placeholder,
  value
}: IAutocomplete & { value: string }) => {
  const [inputValue, setInputValue] = useState(value);
  const [active, setActive] = useState(false);
  const [queries, setQueries] = useState<TQuery[]>([]);

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleSubmit = (e: FormEvent) => {
    e.preventDefault();

    const text = inputValue;
    onSubmit({ value: text, query: undefined, queries });
    setActive(false);
    setQueries([]);
  };

  const handleClick = (query: TQuery) => {
    setInputValue(query.domain);
    setActive(false);
    onSubmit({ value: query.domain, query, queries });
  };

  const reset = () => {
    setQueries([]);
    setInputValue("");
    setActive(false);
    onSubmit({ value: "", queries: [], query: undefined });
  };

  const getQueries = useCallback(async (searchValue: string) => {
    if (searchValue !== "") {
      try {
        const url = `https://api.brandfetch.io/v2/search/${searchValue}`;

        const res = await fetch(url);
        if (res.ok) {
          const data = await res.json();
          setQueries(data);
        }
      } catch (err) {
        console.log("Something went wrong, try again later.");
      }
      return;
    }

    setQueries([]);
  }, []);

  useEffect(() => {
    getQueries(inputValue);
  }, [getQueries, inputValue]);

  return (
    <div className="relative w-full">
      <form onSubmit={handleSubmit} className="w-full relative">
        <label className="absolute left-2 top-3 text-colors-gray-modern-600">
          <Search size={16} />
        </label>

        <input
          className="border border-colors-border-border-primary bg-transparent rounded-lg pl-8 pr-8 py-2 focus-visible:outline-none text-colors-text-text-primary-(900) w-full"
          placeholder={placeholder}
          value={inputValue}
          // TODO: handle pastes with https:// prefixes and suffixes by truncating
          onPaste={(e) => {
            e.preventDefault();
            const domain = getDomainFromUrl(e.clipboardData.getData("text"));
            setInputValue(domain);
            setActive(true);
            onChange(domain);
          }}
          onChange={(e: ChangeEvent<HTMLInputElement>) => {
            setInputValue(e.target.value);
            setActive(true);
            onChange(e.target.value);
          }}
        />

        {inputValue !== "" && (
          <label className="absolute right-1 top-2 cursor-pointer" onClick={() => reset()}>
            <X />
          </label>
        )}
      </form>

      {active && inputValue !== "" && (
        <div className="fixed mt-2 left-0 right-0  bg-colors-brand-50 rounded-md grid z-[9999] px-2 py-3 border border-colors-border-border-secondary">
          {queries.length ? (
            <div className="flex flex-col gap-2 text-colors-text-text-secondary-(700) text-xs">
              {queries
                .filter((query) => query.domain.includes(inputValue) && (query.domain.match(/\./g) || []).length < 2)
                .map((query, i) => (
                  <div
                    key={i}
                    className="flex gap-2 hover:bg-colors-brand-300 p-2 rounded-md cursor-pointer flex-row items-center w-full justify-between"
                    onClick={() => handleClick(query)}>
                    <div className="flex flex-row items-center gap-2">
                      <div className="h-5 w-5 relative overflow-hidden shadow-sm z-50">
                        <img src={query.icon} alt={query.name} className="w-full h-full top-0 left-0 right-0 bottom-0" />
                      </div>

                      <p>{truncate(query.name || query.domain, { length: 15 })}</p>
                    </div>

                    <p className="self-end place-self-end">{query.domain}</p>
                  </div>
                ))}
            </div>
          ) : (
            <div className="flex flex-col gap-2 text-center">
              <p className="bold">Couldn't auto-recognize this company... you can still try running the workflow</p>
            </div>
          )}
        </div>
      )}
    </div>
  );
};
